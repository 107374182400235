import config from 'config.json';
import * as Data from './data';
import * as Http from './httpData';
import * as Core from 'helpers/core';
/*import * as Helpers from 'utils/helpers';
import * as Dt from 'utils/dt';*/


// DEMANDS >>>

const demandUrl = config.apiUrl + '/demand';

function mapToDemand(item) {
  return item;
}
function mapFromDemand(item) {
  return item;
}
function listDemand(data) {
  let mapped = [];
  /*data.forEach( function (item, index) {
    mapped.push( mapToInfoParam(item) );
  });*/
  for ( const [key, value] of Object.entries(data) ) {
    let item = mapToDemand(value);
    item.id = key;
    mapped.push( item );
  }
  return mapped;
}


export async function insertDemand(data, id, slug, params) {
  const response =
    ( Core.isset(id) && Core.isset(slug) )
      ? await Http.post( demandUrl + `/${id}/${slug}` + config.apiFormat + Data.params(params), {demand: mapFromDemand(data)} )
      : await Http.post( demandUrl + config.apiFormat + Data.params(params), {demand: mapFromDemand(data)} );
  response.data.demand = ( (response.data.demand === undefined) || (response.data.demand === null) )
    ? null
    : mapToDemand(response.data.demand);
  return response;
}
