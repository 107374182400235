import { useState } from 'react';
import { Link } from 'react-router-dom';

// Parts
import Loader from 'components/parts/loader';

// Forms
import FormLogin from 'components/forms/login';
import FormRegister from 'components/forms/register';

// Context
import {useAuth} from 'context/authContext.js';


const LoginPage = ({ props }) => {
  // Hooks
  const {auth} = useAuth();
  const [tab, setTab] = useState(0);

  // Handle

  const handleTableTab = (newTab) => {
    if ( tab === newTab ) {
      return;
    }
    setTab(newTab);
  }


  // RENDER >>>

  if (auth.user === undefined) {
    return <Loader type="page" text="Zpracovávám uživatele..." />;
    /*return (
      <div className="row-cols nobg">
        <main className="col-middle">
        </main>
      </div>
    );*/
  }


  if (auth.user === null) {
    return (
      <div className="row-cols nobg">
        <main className="col-middle">

          <h1>Přihlaste se</h1>
          <p>Poslední požadavek se nepodařilo splnit, je třeba se přihlásit.</p>

          <div className="table">
            <ul className="table-tabs">
              <li onClick={() => handleTableTab(0)} className={ (tab === 0) ? "active" : "" }>Přihlásit</li>
              <li onClick={() => handleTableTab(1)} className={ (tab === 1) ? "active" : "" }>Vytvořit účet</li>
            </ul>

            <hr />

            <h1>
              {/*
                (tab === 0) ? "Přihlaste se"
                : (tab === 1) ? "Registrace"
                : (tab === -1) ? "Obnova hesla"
                : ""
              */}
            </h1>

            {
              (tab === 0) ?
                <div className="tab-login">
                  <FormLogin />
                  <div>
                    <p>Zapomněli jste heslo? <Link onClick={() => handleTableTab(-1)}>Obnovit</Link></p>
                  </div>
                </div>
              :
              (tab === 1) ?
                <div className="tab-registration">
                  <FormRegister />
                </div>
              :
              (tab === -1) ?
                <></>
              : <></>
            }
          </div>

        </main>
      </div>
    );
  }


  return (
    <div className="row-cols nobg">
      <main className="col-middle">

        <h1>Vítejte</h1>

        <p>Jako přihlášený uživatel můžete využít tyto funkce:<br />
          <small>(pro navigaci můžete užít také hlavní menu)</small>
        </p>

        <div id="links">

          <div className="row">
            <div className="col-2">
              <Link to="garaz"><div className="icon is-car"></div></Link>
            </div>
            <div className="col-10">
              <h2><Link to="garaz">Moje garáž</Link></h2>
              <p>Nastavte Váš oblíbený automobil do hlavního filtru. Na webu se Vám tak budou zobrazovat a vyhledávat pouze produkty kompatibilní s Vašim automobilem.</p>
            </div>
          </div>

          <div className="row">
            <div className="col-2">
              <Link to="objednavky"><div className="icon is-receipt"></div></Link>
            </div>
            <div className="col-10">
              <h2><Link to="objednavky">Objednávky</Link></h2>
              <p>Podívejte se na historii Vašich objednávek nebo řešte případné reklamace.</p>
            </div>
          </div>

          <div className="row">
            <div className="col-2">
              <Link to="ucet"><div className="icon is-id-card-alt"></div></Link>
            </div>
            <div className="col-10">
              <h2><Link to="ucet">Můj účet</Link></h2>
              <p>Změňte Vaše registrované údaje, uložené adresy či heslo ke svému účtu.</p>
            </div>
          </div>

        </div>

      </main>
    </div>
  );

}

export default LoginPage;
