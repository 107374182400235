import React from 'react';


class FormNewsletter extends React.Component {
  state = {
  }


  componentDidMount() {
  }


  render () {

    return (
      <form onSubmit={(e) => (e.preventDefault()) }>
        <input type="email" placeholder="Váš e-mail" />
        <button>potvrdit</button>
      </form>
    );
  }
}

export default FormNewsletter;
