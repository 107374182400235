import {toInt} from 'helpers/core';


export class ExtendableError extends Error {
  constructor(message) {
    super();
    //this.stack = (new Error()).stack;
    this.name = this.constructor.name;
    this.message = message;
  }
}

export class HttpError extends ExtendableError {
  constructor(code, message) {
    super(message);
    this.code = code;
  }
}

export class HttpErrorWithResponse extends ExtendableError {
  constructor(code, message, response) {
    super(message);
    this.code = code;
    this.response = response;
  }
}
