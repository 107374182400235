import { useState, useEffect } from 'react';

// Parts
import Loader from 'components/parts/loader';

// Helpers
import {isInt,formatInt} from 'helpers/core';

// Context
import {useGlobal} from 'context/globalContext';
import {useProducts} from 'context/productsContext';
// Data
import * as DataProduct from 'data/productData';


const ProdTocart = ({ product, showButton, showRemove }) => {
  const {messagesAdd, setOverlay} = useGlobal();
  const {productsReplaceType, productSet} = useProducts();
  const [cartSync, setCartSync] = useState(false);


  let disabled = false;
  let max = 999999999;
  let classValue = "normal";
  let text = "do košíku";

  const order_together = product.order.together > 0 ? product.order.together : 1;


  // TODO: Not working now - After mount - set "to cart" amount from "in"
  /*useEffect( () => {
    if (product.cart.in > 0 ) {
      product.cart.to = product.cart.in;
    }
  }, [product.cart.in] );*/


  // POST

  const cartInsertOrUpdate = async (product) => {
    try {
      const updatedProduct = await DataProduct.insertOrUpdateToCart(product);
      return updatedProduct.product;
    } catch (ex) {
      return null;
    }
  }


  const changeInCart = async (e, count) => {
    e.preventDefault();

    // If set by "count" -> change in "product"
    let prevQuantity = product.cart.to;
    if (count !== undefined) {
      product.cart.to = count;
    }

    // Push to remote
    setCartSync(undefined);
    const newProduct = await cartInsertOrUpdate(product);
    if ( newProduct === null ) {
      if (count !== undefined) {
        product.cart.to = prevQuantity;
      }
      setCartSync(false);
      messagesAdd(1, "Nepodařilo se změnit zboží v košíku. Zřejmě chyba komunikace, prosím, kontaktujte nás.");
      return;
    }
    setCartSync(true);

    // Set new quantities
    if (product.cart.to == 0) {
      // If removed and quantity set to "0", change "to insert" to 1
      product.cart.to = order_together;
    }
    prevQuantity = product.cart.in;
    product.cart.in = newProduct.cart.in; // Set curently selected quantity to "in cart"

    /*if ( product.cart.in == product.cart.to ) {
      messagesAdd(3, "Vámi požadované množství již v košíku máte");
      return;
    }*/

    // Change product locally
    productSet(product);

    if ( product.cart.in == 0 ) {
      messagesAdd(0, "Zboží odebráno z košíku");
    } else if ( prevQuantity == 0 ) {
      messagesAdd(0, "Zboží přidáno do košíku");
    } else {
      messagesAdd(0, "Množství v košíku upraveno");
    }
  };


  const showDemand = () => {
    product.types = {...product.types, demand:null };
    productsReplaceType({ demand:[product] });
    setOverlay("demand");
  };


  const handleCartQuantityChange = ({currentTarget : input}) => {
    if ( ! isInt(input.value) ) {
      return;
    }

    const newValue = parseInt(input.value, 10);

    if ( (product.stock.stock > 0) && (newValue > product.stock.stock) ) {
      product.cart.to = (product.cart.in == 0) ? order_together : product.cart.in;
      productSet(product);
      messagesAdd(2, "Toto množství nemáme nyní skladem, množství nezměněno");
      return;
    }

    product.cart.to = parseInt(input.value, 10);  // Change value
    productSet(product);
  };

  const allow = (! isNaN(product.stock.stock)) && (product.stock.stock != 0) && (! isNaN(product.price.price)) && (product.price.price >= 0);

  if (allow) {
    if (product.stock.stock > 0) {
      max = product.stock.stock;
    }/* else {
      max = (-1 * product.stock.stock) + 1;
    }*/

    if ( product.cart.in > 0 ) {  // If have in cart

      // If value "to cart" == "in cart"
      if ( (product.cart.to == product.cart.in) && (cartSync !== undefined) ) {
        disabled = true;
        //classValue = "alt_ok alt_border_ok";
        classValue = "alt_ok alt_text_ok";
        text = (product.cart.in <= 9999 ? formatInt(product.cart.in) : formatInt(product.cart.in) + "+" ) + " v košíku";

      } else {
        classValue = "alt_ok";
        //text = (product.cart.in <= 9999 ? formatInt(product.cart.in) : formatInt(product.cart.in) + "+" ) + " v košíku";
        text = "uprav počet";

      }

    } else {                    // If don't have in cart
      if ( (product.cart.to < 1) ) {
        disabled = true;
        text = "zvolte počet";
      }
    }

    // For both (isn't in cart and is in cart)
    if ( (product.cart.to < 0) || (product.cart.to > max) ) {
      disabled = true;
      text = "zvolte počet";
    }

  } else {
    classValue = "alt";
    text = "poptat";

  }


  return(
    <div className="prod-tocart">
      {
        (showButton && allow)
        ?
          <>
            <button
              onClick={ (e) => changeInCart(e) }
              disabled={(cartSync === undefined) || disabled }
              className={`prod-cart ${classValue}`}
              >
              {
                (cartSync === undefined)
                ?
                  <Loader />
                :
                  text
              }
            </button>
            {
              ( showRemove && ( product.cart.in > 0 ) && (cartSync !== undefined) )
              ?
                <div onClick={ (e) => changeInCart(e, 0) } className="prod-remove">
                  <span className="btn-icon icon is-trash-alt"></span>
                </div>
              :
                null
            }
          </>
        :
          (showButton && ! allow)
          ?
            <button onClick={ (e) => showDemand(e) } className={`prod-cart ${classValue}`}>
              {text}
            </button>
          :
            null
      }

      <input type="number" min={ product.cart.in == 0 ? order_together : "0" } max={max} step={order_together} value={product.cart.to} onChange={ (e) => handleCartQuantityChange(e) } onBlur={ showButton ? null : (e) => changeInCart(e) } />
    </div>
  );

}


ProdTocart.defaultProps = {
  showButton: true,
  showRemove: false,
};


export default ProdTocart;
