import React from 'react';


const CarservicePage = ({ props }) => {

  return (
    <>

      <h1>Autoservis</h1>

      <p>Příznivé ceny na servis i přezouvání, neváhejte se objednat na čísle <b>605 46 83 96</b></p>
      <p>&nbsp;</p>
      <p>Otevírací doba: Po-Pá 7:30-12:00 13:00-15:00<br/>
      Kontakt - tel: 605 46 83 96<br/>
      Email: <a href="mailto:autoservis@prondo.cz">autoservis@prondo.cz</a></p>

    </>
  );

}

export default CarservicePage;
