export const responsive = {
    d: 1100     // desktop
    , t: 1010   // tablet
    , m: 640    // mobile
};

// https://www.w3schools.com/howto/howto_js_media_queries.asp

export function getResponsive(width, max) {
    if (max) {
        width -= 1;
    }
    return window.matchMedia( `(${max ? "max" : "min"}-width: ${width}px)` );
}

export function isResponsive(width, max) {
    return getResponsive(width, max).matches;
}

export function responsiveAddListener(fun, width, max) {
    let media = getResponsive(width, max);
    media.addEventListener(fun);
    return media;
}