// Config
import configFile from 'config';
import {isset} from 'helpers/core';


// URL

export function url_base() {
  return window.location.origin;
}


// PAGE

export function pageNew(levelToReplace, urn, path, name, key = null) {
  let out = {
    path : path,
    urn: urn,
    name: name,
    customLink: false,
    level: levelToReplace   // Where to replace (for new naviagtion/merge)
  };
  if (key !== null) {
    out.key = key;
  }
  return out;
}


export function pageMerge(oldNav, newNav, maxLength) {
  if (oldNav === undefined) {
    console.log( "UNDEFINED OLD NAV" );
    return newNav;
  }
  if (newNav === undefined) {
    console.log( "UNDEFINED NEW NAV" );
    return oldNav;
  }

  let out = [];

  // Insert OLD navigation into OUT
  for (let idx = 0; idx < oldNav.length && (maxLength === undefined || idx < maxLength); idx++) {
    out.push(oldNav[idx]);
  }

  // Append from NEW navigation or replace OLD with NEW
  for (let idx = 0; idx < newNav.length; idx++) {
    const nav = newNav[idx];

    if ( (! ("level" in nav)) || (nav.level === null) || (nav.level >= oldNav.length) ) {
      // Append, if level in "new"
      if ( maxLength === undefined || out.length < maxLength ) {
        out.push(nav);
      }
    } else {
      // Replace in old
      if ( maxLength === undefined || nav.level < maxLength ) {
        out[nav.level] = nav;
      }
    }
  }

  return out;
}


export function pageCurrent() {
  const pages = configFile.config.pages;  // All pages

  // Current page
  let currentPage = window.location.pathname;
  if ( currentPage.length === 0 ) {
    const out = pages['home'];
    out.key = 'home';
    return out;
  }
  currentPage = currentPage.split(/[?#]/)[0];

  // Find exact
  for (let key in pages) {
    if ( pages[key].urn.indexOf( currentPage ) != -1 ) {
      const out = pages[key];
      out.key = key;
      return out;
    }
  }

  // Find by 1st "/.../"
  currentPage = currentPage.split('/');
  // Iterate all pages
  for (let key in pages) {
    // Iterate [arrays]
    for (let urn of pages[key].urn) {
      const current = urn.split('/');
      if ( current.length > 1 && current[1] === currentPage[1] ) {
        const out = pages[key];
        out.key = key;
        return out;
      }
    }
  }

  /*const out = pages['home'];
  out.key = 'home';
  return out;*/
  return null;
};


export function buildNavigation(currentPage, exceptions = false) {
  const pages = configFile.config.pages;

  if (currentPage === undefined) {
    currentPage = pageCurrent();
  }

  // If current page "null" -> nothing in navigation
  if (currentPage === null) {
    return [];
  }

  // Check, if current page exist in pages
  if (! currentPage.key in pages) {
    console.log("CURRENT PAGE NOT EXISTS! " + currentPage.key); // TODO: Log
    return [];
  }


  // EXCEPTIONS FOR DIFFERENT PAGES >>>
  if (exceptions) {
    switch (currentPage.key) {
      case "products":
        // TODO!
        return undefined;
      case "product":
        // TODO!
        break;
      default:
        break;
    }
  }
  // <<< EXCEPTIONS FOR DIFFERENT PAGES


  let out = [];
  // Iterate whole path of current page and add it to "out"
  for (let path of currentPage.path) {
    if (! path in pages) {
      console.log("PAGE NOT EXISTS! " + path); // TODO: Log
      continue;
    }

    const page = pages[path];
    page.key = path;

    // EXCEPTIONS FOR DIFFERENT PAGES >>>
    /*if (page.customLink) {
      switch (path) {
        case "products":
          // TODO!
          break;
        case "product":
          // TODO!
          break;
        default:
          break;
      }
    }*/
    // <<< EXCEPTIONS FOR DIFFERENT PAGES

    out.push( page );
  }
  // Append current page to the end
  out.push( currentPage );

  return out;
}


// PARAMS

// TODO: does not handle multi-valued keys, make: https://stackoverflow.com/a/21210643 .. https://stackoverflow.com/questions/5448545/how-to-retrieve-get-parameters-from-javascript
export function paramGet(paramName) {
  var items = window.location.search.substr(1).split("&");

  for (var idx = 0; idx < items.length; idx++) {
    var idxFirst = items[idx].indexOf('=');
    var key;
    var value = undefined;

    // Divide into "key"+"value"
    if (idxFirst === -1) {
      // If there isn't any "=", consider as key only (isn't any value), return empty string
      key = items[idx];
      value = null;
    } else {
      key = items[idx].substring(0, idxFirst);
    }

    if ( key === paramName ) {
      if (value === undefined) {
        value = items[idx].substring(idxFirst + 1);
      }
      return decodeURIComponent(value);
    }
  }

  return undefined;
}



// GET

export function fromGet( props ) {
  if (props === undefined) {
    return null;
  }

  const query = (props.match === undefined || props.match.params === undefined) ? undefined : props.match.params;
  const params = (props.location === undefined || props.location.search === undefined) ? undefined : parseUrlParams(props.location.search);

  return {
    id: (query === undefined || query.id === undefined) ? null : query.id,
    slug: (query === undefined || query.slug === undefined) ? null : query.slug,
    typ: (params === undefined || params.typ === undefined) ? null : params.typ,
    query: (query === undefined) ? null : query,
    params: (params === undefined) ? null : params,
  }
}

function parseUrlParams( url ) {
  let parsed = {};
  if (url != '') {
    url = url.substring(url.indexOf('?')+1);
    let p1 = url.split('&');
    p1.map(function(value) {
      let params = value.split('=');
      parsed[ decodeURI(params[0]) ] = decodeURI(params[1]);
    });
  }
  return parsed;
}

export function toGet(params) {
  let out = "";

  if (params) {
    for (let key in params) {
      if ( ! isset(key) ) { // Key not set
        continue;
      }
      if ( ! isset(params[key]) ) { // Value not set
        continue;
      }
      out += (out.length === 0) ? "?" : "&";  // Is first or not
      out += encodeURI(key) + "=" + encodeURI(params[key]);
    }
  }

  return out;
}


// TODO -> not used in "toGet()"
function paramToGetArray(k, vArray) {
  /*let out = "";
  statesId.forEach( (value, idx) => {
    if ( out !== "" ) {
      out += "&";
    }
    out += `state[${idx}]=` + value;
  });*/
  let out = {};
  vArray.forEach( (value, idx) => {
    out[`${k}[${idx}]`] = value;
  });
  return out;
}


export function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "čďěňřšťůýžãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
  var to   = "cdenrstuyzaaaaaeeeeeiiiiooooouuuunc------";
  for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

  return str;
}


export function productTypeToEn(type = "category") {
  switch (type) {
    case undefined:
    case null:
    case "category":
    case "kategorie":
      return "category";
    case "universal":
    case "univerzalni":
      return "universal";
    case "search":
    case "hledat":
      return "search";
    case "interchangeable":
    case "zamenitelne":
      return "interchangeable";
    case "favorite":
    case "oblibene":
      return "favorite";
    case "sale":
    case "vyprodej":
      return "sale";
    default:
      return type;
  }
}
export function productTypeToCz(type = "kategorie") {
  switch (type) {
    case undefined:
    case null:
    case "category":
    case "kategorie":
      return "kategorie";
    case "universal":
    case "univerzalni":
      return "univerzalni";
    case "search":
    case "hledat":
      return "hledat";
    case "interchangeable":
    case "zamenitelne":
      return "zamenitelne";
    case "favorite":
    case "oblibene":
      return "oblibene";
    case "sale":
    case "vyprodej":
      return "vyprodej";
    default:
      return type;
  }
}


export function categoryLink(id, name, type) {
  switch (type) {
    case "universal":
    case "univerzalni":
      return "/produkty/" + id + "/" + slugify(name) + "?typ=" + productTypeToCz(type);
    case "category":
    case "kategorie":
    default:
      return "/produkty/" + id + "/" + slugify(name);
  }
}

export function productLinkSlugify(id, name) {
  return "/produkt/" + id + "/" + slugify(name);
}
