import React from 'react';

// Parts
import Product from 'components/parts/product';
import ProdBanner from 'components/parts/product/prodbanner';
import Loader from 'components/parts/loader';


const Products = ({ products, banner_title, banner_link, banner_icon }) => {

  if ( (products) && (products.length === 0) ) {
    return null;
  }

  return (
    <section className={ banner_title ? "productline" : "productlist" }>
      {
        (products === undefined || products === null) // TODO: Use undefined only! Null = no product
        ?
          <Loader />
        :
          <>
            {
              banner_title &&
                <ProdBanner title={banner_title} link={banner_link} icon={banner_icon} />
            }

            {
              products.map( (item, idx) =>
                <Product key={idx} product={ item } />
              )
            }

            {
              // Width from CSS!!!
              [...Array( Math.floor(1100 / (250+4)) ).keys()].map( idx =>
                <div key={idx * -1} className="dummy"></div>
              )
            }
          </>
      }
    </section>
  );

}

export default Products;
