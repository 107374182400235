
export function isset(element) {
  if ( /*(! element) ||*/ (typeof element === 'undefined') || element === null) {
    return false;
  } else {
    return true;
  }
}

export function empty(element) {
  if ( (! isset(element)) || element === '' || (isArray(element) && element.length === 0) || (isObject(element) && Object.keys(element).length === 0) ) {
    return true;
  } else {
    return false;
  }
}

export function echo(text) {
  if (isset(text)) {
    return text;
  } else {
    return "";
  }
}

export function isObject(variable) {
  return ( typeof variable === 'object' && !Array.isArray(variable) && variable !== null );
}

export function isArray(variable) {
  if (typeof Array.isArray === 'undefined') {
    Array.isArray = function(obj) {
      return Object.prototype.toString.call(obj) === '[object Array]';
    }
  };
  return Array.isArray(variable)
}

export function isBoolean(variable) {
  return (typeof variable == "boolean");
}

export function isString(variable) {
  return (typeof variable === 'string' || variable instanceof String);
}

export function isNumber(text) {  // TODO: detect wholeNumber+decimalNumber
  if ( empty(text) || isBoolean(text) ) {
    return false;
  }
  return ! isNaN(text);
}

export function isInt(text) { // TODO: remake to detect "ifIsWholeNumber" instead "isInt"
  //return (text === parseInt(text, 10));
  return (text == parseInt(text, 10));
}

export function toInt(text) {
  const out = parseInt(text, 10);
  //return isNaN(out) ? text : out;
  return isNaN(out) ? null : out;
}

export function formatNumber(price, precision) {
  return price.toFixed(precision).replace('.', ',').replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ");
}

export function formatInt(price) {
  if (! isset(price)) {
    return undefined;
  }
  return price.toString().replace('.', ',').replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ");
}

export function isDate(dateObj) {
  return dateObj instanceof Date && !isNaN(dateObj);
}



export function brToLine(text) {
  if (! isset(text)) {
    return undefined;
  }
  return text.replace(/(<br>|<\/br>|<br\/>|<br \/>)/mgi, "\n");
}



export function textCutRight(text, maxLength) {
  /*if (text === undefined) {
    return undefined;
  }
  if (text === null) {
    return null;
  }*/
  if (! isset(text)) {
    return null;
  }
  return text.substring(0, maxLength);
}
