import React from 'react';

// Forms
import {setState, Radio} from 'components/form';

// Functions
import * as money from 'helpers/money';

// Context
import {useOrder} from 'context/orderContext.js';


const PartialOrderShipping = ({ all, name, idKey, valueKey, priceKey, taxKey, data, setData, doClearPayment }) => {
  // Hooks
  const {order, sumSet} = useOrder();


  const handlePriceChange = (e, item) => {
    sumSet({ shippingTax: money.priceToTax(item[priceKey],item[taxKey]), shippingNoTax: item[priceKey] });

    /* TODO:
    if ( item.type === 1 ) {

    }
    console.log( all );*/
    doClearPayment();

    setState(e,data,setData);
  }

  // RENDER >>>


  if (! data) {
    return (
      <div className={`form-partial form-partial-order-select form-partial-order-select-${ name }`}>
      </div>
    );
  }


  return (
    <div className={`form-partial form-partial-order-select form-partial-order-select-${ name }`}>

      {
        all.map( item =>
          <div key={item[idKey]}>
            <Radio checked={item[idKey] == data[name]} name={name} labelText={item[valueKey]} value={item[idKey]} onChange={ (e) => handlePriceChange(e,item) } />
            <div className="price">
              <span>{/*<small>{ money.formatCzk(item[priceKey]) }&nbsp;Kč bez DPH</small> */}{ money.formatCzk( money.priceToTax(item[priceKey],item[taxKey]) ) }&nbsp;Kč <small>s DPH</small></span>
            </div>
          </div>
        )
      }

    </div>
  );
}

export default PartialOrderShipping;
