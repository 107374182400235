import React from 'react';
import { Link } from 'react-router-dom';

// Helpers
import { isNumber, formatNumber } from 'helpers/core';

// Config
import configFile from 'config';


const ProdFlags = ({ flags, discount, onClick, vertical }) => {

  const icon = {
    discount: null,
    favorite: 'ir-thumbs-up',
//    recommended: 'ir-check-circle',
    recommended: 'is-check',
  }

  const useDiscount = isNumber(discount) && ((-1*discount) >= configFile.config.product.minDiscount);


  if ( ! flags && (! useDiscount) ) {
    return null;
  }

  let valid = 0;
  for ( let [key, flag] of Object.entries(flags) ) {
    if ( (flag === true) /*|| (isInt(flag))*/ ) {
      valid += 1;
    }
  }

  if ( valid === 0 && (! useDiscount) ) {
    return null;
  }


  return (
    <div onClick={onClick} className={ ((vertical === true) ? "vertical " : "") + "prod-flags" }>
      {
        Object.entries(flags).map( ([key, item], index) =>
          (item == true) &&
            <div key={index} className={`flag-${key}`}><span className={`icon ${ icon[key] }`}></span></div>
        )
      }
      {
        useDiscount &&
          <div className="flag-discount">{ Math.round(discount) }%</div>
      }
    </div>
  );

}

export default ProdFlags;


ProdFlags.defaultProps = {
  flags: [],
  discount: null,
}
