import React from 'react';


export default class Page extends React.Component {


  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  }

}
