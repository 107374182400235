// Config
import configFile from 'config';


export function changeTitle(text) {
  if (text === undefined) {
    document.title = configFile.config.page.title;
  } else {
    document.title = text;
  }
}
