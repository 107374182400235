import { useState, useEffect } from 'react';

// Forms
import {setState, Check, Textarea, Input} from 'components/form';

// Parts
import Loader from 'components/parts/loader';
import MessagesBox from 'components/parts/messagesbox';
import ProductRow from 'components/parts/productrow';

// Helpers
import { isset } from 'helpers/core';
import * as money from 'helpers/money';
import {strYears,strVehicle} from 'helpers/vehicles';

// Context
import {useGlobal} from 'context/globalContext';
import {useProducts} from 'context/productsContext';
// Data
import * as AccountData from 'data/accountData';

// Config
import configFile from 'config';


const initComplaint = {
  note: ""
};


const OrdersPage = (props) => {
  // Hooks
  const {messagesAdd} = useGlobal();
  const [orders, setOrders] = useState(undefined);
  const [products, setProducts] = useState(undefined);
  const [complaint, setComplaint] = useState({...initComplaint});
  const [complaintStatus, setComplaintStatus] = useState([]);
  const [showDetail, setShowDetail] = useState(undefined);
  const [showComplaint, setShowComplaint] = useState(undefined);
  const [sync, setSync] = useState(false);


  useEffect( () => {
    const fetchData = async () => {
      const answer = await ordersLoad();
      if (answer === null) {
        setOrders(null);
        return null;
      }
      setOrders( processOrdersAnswer(answer.status) ? answer.data.orders : null );
    };
    fetchData();
  }, [] );


  // GET
  const ordersLoad = async () => {
    setOrders(undefined);
    try {
      const answer = await AccountData.ordersGet();
      return answer;
    } catch(e) {
      setOrders([]);
      return null;
    }
  };

  // Products load
  const productsLoad = async (orderId) => {
    setProducts(undefined);
    try {
      const answer = await AccountData.order(orderId);
      return answer.data.products;
    } catch(e) {
      console.log(e);
      return null;
    }
  };


  const processOrdersAnswer = (status) => {
    if (status === null) {
      messagesAdd(1, "Nepodařilo se spojit se serverem. Zkuste to později znovu nebo nás kontaktujte.");
      return false;
    }

    switch (status) {
      case 0:
        break;
      case 5100:
        messagesAdd(3, "Musíte se nejdříve přihlásit.");
        return false;
      case null:
        messagesAdd(1, "Systémová chyba: Nepřišla platná odpověď od serveru.");
        return false;
      default:
        messagesAdd(1, "Systémová chyba " + status + ", prosím kontaktujte nás.");
        return false;
    }

    return true;
  }


  const handleShowDetails = async (orderId) => {
    setShowComplaint(null);
    const show = (showDetail == orderId ? null : orderId);
    setShowDetail(show);
    if (show === null) {
      setProducts(null);
    } else {
      setProducts( await productsLoad(orderId) );
    }
  }


  const mapComplaintProducts = (tmpProducts) => {
    if (tmpProducts === undefined || tmpProducts === null) {
      return null;
    }
    const out = [...tmpProducts];
    out.forEach( function (item, index) {
      item.complaint = {
        quantity: null,
        comment: ""
      }
    });
    return out;
  }


  // TODO: REMOVE LATER >>>
  function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
  }
  // <<<

  const handleComplaint = async () => {
    if ( !isset(products) ) {
      setComplaintStatus([ {type:1, text: "Produkty nebyly načteny, zkuste to znova, prosím."} ]);
      return false;
    }

    let isAnyProductSelected = false;
    products.forEach( function (item, index) {
      if (item.complaint.quantity > 0) {
        isAnyProductSelected = true;
        return;
      }
    });
    if ( ! isAnyProductSelected ) {
      setComplaintStatus([ {type:2, text: "Nemáte vybrán žádný produkt k reklamaci."} ]);
      return false;
    }

    setComplaintStatus(undefined);

    await timeout(1000);  // TODO: REMOVE LATER <<<
    setComplaintStatus([ {type:0, text: "Žádost o reklamaci byla odeslána, brzy Vás budeme kontaktovat."} ]);
    return true;
  }


  const handleSelectComplaintProduct = (productIdx) => {
    if ( !isset(products) || !isset(products[productIdx]) || !isset(products[productIdx].complaint) ) {
      return;
    }
    const tmpProducts = [...products];
    products[productIdx].complaint.quantity = (products[productIdx].complaint.quantity === null) ? products[productIdx].cart.in : null;
    setProducts( tmpProducts );
  }

  const handleChangeComplaintQuantity = ({currentTarget : input}, productIdx) => {
    if ( !isset(products) || !isset(products[productIdx]) || !isset(products[productIdx].complaint) ) {
      return;
    }
    const tmpProducts = [...products];
    products[productIdx].complaint.quantity = input.value;
    setProducts( tmpProducts );
  }

  const handleChangeComplaintComment = ({currentTarget : input}, productIdx) => {
    if ( !isset(products) || !isset(products[productIdx]) || !isset(products[productIdx].complaint) ) {
      return;
    }
    const tmpProducts = [...products];
    products[productIdx].complaint.comment = input.value;
    setProducts( tmpProducts );
  }


  const handleShowComplaint = async (orderId) => {
    setShowDetail(null);
    const show = (showComplaint == orderId ? null : orderId);
    setShowComplaint(show);
    if (show === null) {
      setProducts(null);
    } else {
      setComplaint({...initComplaint});
      setComplaintStatus([]);
      const tmpProducts = await productsLoad(orderId);
      setProducts( mapComplaintProducts(tmpProducts) );
    }
  }


  // RENDER >>>


  if ( orders === undefined ) {
    return (
      <Loader type="page" text="Načítám Vaše objednávky ..." />
    )
  }


  return (
    <section id="orderspage" className="row-cols">
      <aside className="col-left">
      </aside>
      <main className="col-middle">

        <h1>Historie objednávek</h1>
        <div className="table">
          {
            ( ! isset(orders) || (orders.length === 0) )
            ?
              <div className="txt-banner">
                <p>U Vašeho účtu zatím neevidujeme žádnou objednávku.</p>
              </div>
            :
              <div className="table-data">
                {
                  orders.map( (item,idx) =>
                    <div>

                      <div className="row">
                        <div className="col-4">
                          <small>Číslo objednávky</small>: { item.number }<br/>
                        </div>
                        <div className="col-4">
                          <strong><small>Cena celkem</small>: { money.formatCzk(item.price) }</strong>  Kč
                        </div>
                        <div className="col-2">
                          <button onClick={ (e) => handleShowDetails(item.id) }>{ item.id == showDetail ? "Skrýt" : "Podrobnosti" }</button>
                        </div>
                        <div className="col-2">
                          <button onClick={ (e) => handleShowComplaint(item.id) } className="alt">{ item.id == showComplaint ? "Skrýt" : "Reklamace" }</button>
                        </div>
                      </div>


                      <div className={`order-detail${item.id == showDetail ? "" : " hide"}`}>
                        <hr/>

                        <div className="row">
                          <div className="col-4">
                            <small>Fakturační adresa:</small><br/>
                            { item.address_invoicing.firstname } { item.address_invoicing.lastname }<br/>
                            { item.address_invoicing.street }<br/>
                            { item.address_invoicing.city } { item.address_invoicing.zip }<br/>
                            { item.address_invoicing.country }
                          </div>
                          <div className="col-4">
                            <small>Doručovací adresa:</small><br/>
                            {
                              (item.use.address_delivery == 1)
                              ?
                                <>
                                  { item.address_delivery.firstname } { item.address_delivery.lastname }<br/>
                                  { item.address_delivery.street }<br/>
                                  { item.address_delivery.city } { item.address_delivery.zip }<br/>
                                  { item.address_delivery.country }
                                </>
                              :
                                <>Stejná jako fakturační.</>
                            }
                          </div>
                          <div>
                            <small>Firma:</small><br/>
                            {
                              (item.use.address_delivery == 1)
                              ?
                                <>
                                  { item.company.name }<br/>
                                  { item.company.bin }<br/>
                                  { item.company.tin }
                                </>
                              :
                                <>Objednávka není fakturována na firmu.</>
                            }
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <small>E-mail</small>: { item.contact.email }<br/>
                            <small>Telefon</small>: { item.contact.phone }
                          </div>
                        </div>

                        <hr/>

                        <div className="row">
                          <div className="col-12">
                            <small>Přeprava</small>: { item.transport }<br/>
                            <small>Platba</small>: { item.payment }
                          </div>
                        </div>

                        <hr/>

                        <div className="row">
                          {
                            (products === undefined)
                            ?
                              <div className="col-12 h-center">
                                <Loader text="Načítám produkty ..." />
                              </div>
                            : (products === null)
                              ?
                                <div className="col-12 h-center">
                                  Nepodařilo se načíst produkty. Zkuste to později nebo nás kontaktujte.
                                </div>
                              : (products.length === 0)
                                ?
                                  <div className="col-12 h-center">
                                    Tato objednávka neobsahuje žádný produkt.
                                  </div>
                                :
                                  <div className="col-12">
                                    {
                                      products.map( (item,idx) =>
                                        <ProductRow key={idx} product={item} showFlags={false} showCart={null} showStock={false} showRemove={false} />
                                      )
                                    }
                                  </div>
                          }
                        </div>

                      </div>


                      <div className={`order-complaint${item.id == showComplaint ? "" : " hide"}`}>
                        <hr/>

                        {
                          (products === undefined)
                          ?
                            <div className="col-12 h-center">
                              <Loader text="Načítám produkty ..." />
                            </div>
                          : (products === null)
                            ?
                              <div className="col-12 h-center">
                                Nepodařilo se načíst produkty. Zkuste to později nebo nás kontaktujte.
                              </div>
                            : (products.length === 0)
                              ?
                                <div className="col-12 h-center">
                                  Tato objednávka neobsahuje žádný produkt.
                                </div>
                              :
                                products.map( (item,idx) =>
                                  <>
                                    <div className="row">
                                      <div className="col-6">
                                        <ProductRow
                                            key={idx}
                                            product={item}
                                            largestCol={8}
                                            showFlags={false}
                                            showCart={false}
                                            showStock={false}
                                            showPrice={false}
                                            showRemove={false}
                                          />
                                      </div>
                                      <div className="col-2">
                                        <Check labelText="Vybrat produkt" value={(item.complaint !== undefined && item.complaint.quantity !== null) ? 1 : null} onChange={(e) => handleSelectComplaintProduct(idx)} />
                                        {
                                          (item.complaint !== undefined && item.complaint.quantity > 0) &&
                                            <div>
                                              <small>Počet k reklamaci</small>:
                                              <Input type="number" name={`quantity-${idx}`} value={item.complaint.quantity} min="1" max={item.cart.in} step="1" onChange={(e) => handleChangeComplaintQuantity(e,idx)} />
                                            </div>
                                        }
                                      </div>
                                      <div className="col-4">
                                        {
                                          (item.complaint !== undefined && item.complaint.quantity > 0) &&
                                            <div>
                                              <small>Popište problém s tímto produktem</small>:
                                              <Textarea rows={2} name={`comment-${idx}`} value={item.complaint.comment} onChange={(e) => handleChangeComplaintComment(e,idx)} />
                                            </div>
                                        }
                                      </div>
                                    </div>

                                    <hr/>
                                  </>
                                )
                        }

                        <div>
                          <small>Poznámky k reklamaci (nepovinné)</small>:
                          <Textarea rows={4} name="note" value={complaint.note} onChange={(e) => setState(e,complaint,setComplaint)} />
                          <div className="h-center">
                            <MessagesBox messages={complaintStatus} />
                            <button onClick={handleComplaint}>
                              {
                                (complaintStatus === undefined)
                                ?
                                  <Loader />
                                :
                                  "Vyžádat reklamaci"
                              }
                            </button>
                          </div>
                        </div>

                      </div>

                    </div>
                  )
                }
              </div>
          }
        </div>

      </main>
      <aside className="col-right">
      </aside>
    </section>
  );

}

export default OrdersPage;
