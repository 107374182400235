import { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Forms
import {setState, Check, Textarea, Input} from 'components/form';

// Parts
import MessagesBox from 'components/parts/messagesbox';
import Loader from 'components/parts/loader';

// Context
import {useGlobal} from 'context/globalContext.js';
import {useAuth} from 'context/authContext.js';
import {useProducts} from 'context/productsContext';

// Data
import * as UserData from 'data/userData';

// Config
import configFile from 'config';


const initData = {
  email: "",
  status: null,
}


const FormPasswordReset = ({ product }) => {
  // Hooks
  const history = useHistory();
  const {messagesAdd, messagesSet, setOverlay} = useGlobal();
  const [data, setData] = useState(initData);
  const [resetMessages, setResetMessages] = useState([]);


  const requestToken = async (e) => {
    e.preventDefault();
    setResetMessages(undefined);

    const out = { ...data };
    const input = await resetPost(out.email);

    if (input === null || input.status === undefined || input.email === null) {
      //setResetMessages([]);
      setResetMessages([ {type:1, text: "Nepodařilo se spojit se serverem. Zkuste to později znovu nebo nás kontaktujte."} ]);
      return;
    }
    // Some errors -> don't submited
    switch (input.status) {
      case undefined:
      case null:
      case 0:
        setResetMessages([ {type:0, text: "Na tento e-mail jsme Vám zaslali instrukce k obnově hesla."} ]);
        break;
      case -1:
        setResetMessages([ {type:3, text: "Hodnota \"Váš e-mail\" musí být platná e-mailová adresa."} ]);
        return;
      case -2:
        setResetMessages([ {type:3, text: "Pro tento e-mail u nás neevidujeme žádný účet."} ]);
        return;
      case 6403:
        setResetMessages([ {type:3, text: "Vyplňte, prosím, všechny potřebné informace."} ]);
        return;
      case 6402:
        setResetMessages([ {type:1, text: "Požadavek neobsahuje všechny informace, prosím kontaktujte nás."} ]);
        return;
      default:
        setResetMessages([ {type:1, text: "Chyba aplikace, prosím kontaktujte nás."} ]);
        return;
    }

    processTokenAnswer(input.status, input.email);

    //window.scrollTo(0, 0);  // Scroll to top
  }


  const processTokenAnswer = (resetStatus, email) => {
    const result = { ...data };
    result.status = resetStatus;
    setData(result);
  }


  // POST

  const resetPost = async (email) => {
    try {
      return await UserData.passwordToken(email);
    } catch (ex) {
      return null;
    }
  }


  // RENDER >>>


  return (
    <div className="form-login">

      <MessagesBox messages={resetMessages} />

      <form onSubmit={ (e) => (e.preventDefault()) } className="form-1">

        <div className="form-row">
          <div className="form-col">
            <Input labelText="Zadejte Váš e-mail" name="email" value={data["email"]} onChange={(e) => setState(e,data,setData)} />
          </div>
          <div className="form-col">
            <button onClick={requestToken} disabled={(resetMessages === undefined)}>
              {
                (resetMessages === undefined)
                ?
                  <Loader />
                :
                  "Obnovit heslo"
              }
            </button>
          </div>
        </div>

      </form>

    </div>
  );
}

export default FormPasswordReset;
