
export const order = {
  shipping: null,
  payment: null,
  completeonly: 1,
  notes: "",
  terms: null,
}


export async function initOrder() {
  return {...order};
}
