import React from 'react';


const CarrierRentPage = ({ props }) => {

  return (
    <>
      <h1>Půjčovna nosičů</h1>

      <p>
        Pokud potřebujete autobox nebo nosič kol jen párkrát do roka, máme pro vás ideální řešení - příslušné komponenty si <strong>VYPŮJČTE U NÁS</strong>. Nemusíte se o nic starat, poradíme vám s výběrem i montáží a to vše za optimální cenu.
      </p>
      <p>
        Nabízíme k zapůjčení střešní boxy a nosiče kol na střechu i na tažné zařízení od firmy Hakr.
      </p>

      <h2>Podmínky a ceny pronájmu</h2>
      <ul className="list">
        <li>cena pronájmu je splatná po vrácení nosiče / boxu (kartou nebo odečtem ze zálohy)</li>
        <li>vratná kauce (záloha) dle ceníku je splatná v hotovosti v den zapůjčení</li>
        <li>uvedené ceny jsou včetně DPH, minimální půjčovné 200 Kč</li>
        <li><strong>první den půjčení neúčtujeme</strong>, započítává se každý následující započatý den</li>
        <li>mytí nosičů neúčtujeme!</li>
        <li><span>Rezervace na tel.: 326 728 002, osobně nebo e-mailem </span><a href="mailto:pujcovna@prondo.cz">pujcovna@prondo.cz</a></li>
        <li>Potřebujete-li pomoc s montáží, je nutné si předem domluvit termín (k dispozici většinou mezi 9-15h)</li>
      </ul>


      <h2>Střešní boxy</h2>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Rozměry</th>
            <th>Objem (l)
              <br/>páry lyží
            </th>
            <th>Cena / den
              <br/>(1-9 dní)
            </th>
            <th>Cena / den
              <br/>(10 a více dní)</th>
            <th>Kauce</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td><strong>HAKR Magic line 400</strong>
              <br/><a href="https://www.hakrbrno.cz/autobox-hakr-magic-line-400-sedy-leskly" target="_blank">více info</a>
            </td>
            <td>199x74x42</td>
            <td>400 / 6</td>
            <td>120 Kč</td>
            <td>110 Kč</td>
            <td>3.000&nbsp;Kč</td>
          </tr>
        </tbody>
      </table>


      <h2>Střešní nosiče</h2>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Rozměry</th>
            <th>Objem (l)
              <br/>páry lyží
            </th>
            <th>Cena / den
              <br/>(1-9 dní)
            </th>
            <th>Cena / den
              <br/>(10 a více dní)</th>
            <th>Kauce</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td><strong>Nosič HAKR KIT systém</strong></td>
            <td>dle modelu</td>
            <td>- - -</td>
            <td>90 Kč</td>
            <td>80 Kč</td>
            <td>1.000 Kč</td>
          </tr>

          <tr>
            <td><strong>Nosič na hagusy univerzální</strong></td>
            <td>až 127 cm</td>
            <td>- - -</td>
            <td>80 Kč</td>
            <td>70 Kč</td>
            <td>1.000 Kč</td>
          </tr>
        </tbody>
      </table>


      <h2>Nosiče kol</h2>
      <table>
        <thead>
          <tr>
      			<th></th>
      			<th>Typ</th>
      			<th>Počet kol</th>
      			<th>Cena / den
              <br/>(1-9 dní)</th>
      			<th>Cena / den
              <br/>(10 a více dní)</th>
      			<th>Kauce</th>
      		</tr>
        </thead>

        <tbody>
          <tr>
            <td><strong>HAKR Speed ALU</strong>
              <br/><a href="https://www.hakrbrno.cz/hakr-speed-alu" target="_blank">více info</a></td>
            <td>na střechu</td>
            <td>1</td>
            <td>60 Kč</td>
            <td>50 Kč</td>
            <td>500 Kč</td>
          </tr>

          <tr>
            <td><strong>HAKR Trip Middle 3</strong>
              <br/><a href="https://www.hakrbrno.cz/hakr-trip-pro-3-kola-middle" target="_blank">více info</a></td>
            <td>tažné, 13pin</td>
            <td>3</td>
            <td>100 Kč</td>
            <td>90 Kč</td>
            <td>4.000 Kč</td>
          </tr>

          <tr>
            <td><strong>HAKR Trip adaptér na 4.kolo</strong>
              <br/><a href="https://www.hakrbrno.cz/adapter-pro-nosic-trip-middle-k-rozsireni-nosice-kol" target="_blank">více info</a></td>
            <td>tažné</td>
            <td>1</td>
            <td>20 Kč</td>
            <td>20 Kč</td>
            <td>500 Kč</td>
          </tr>
        </tbody>
      </table>


      <h2>Nosiče lyží</h2>
      <table>
        <thead>
          <tr>
            <th></th>
            <th colSpan="2">Lyže / Běžky / Snowbord</th>
            <th>Cena / den
              <br/>(1-9 dní)</th>
            <th>Cena / den
              <br/>(10 a více dní)</th>
            <th>Kauce</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td><strong>HAKR 3</strong></td>
            <td colSpan="2">3 / 3 / 0</td>
            <td>30 Kč</td>
            <td>20 Kč</td>
            <td>500 Kč</td>
          </tr>
        </tbody>
      </table>


      <h2>Dětský vozík za kolo</h2>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Cena za den</th>
            <th>Cena za víkend</th>
            <th>Cena za týden</th>
            <th>Kauce</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Vozík za kolo</td>
            <td>300 Kč</td>
            <td>500 Kč</td>
            <td>900 Kč</td>
            <td>4 000 Kč</td>
          </tr>
        </tbody>
      </table>
    </>
  );

}

export default CarrierRentPage;
