// Context
import {useGlobal} from 'context/globalContext';


const MessagesOverlay = () => {
  const {global, messagesClear} = useGlobal();

  const close = () => {
    messagesClear();
  }

  return(
    <div id="messages" className={ (global.messages.length === 0) ? "" : "show" }>
      <div className="close"><span onClick={close} className="btn-icon icon is-times"></span></div>
      {
        global.messages.map( (item, idx) =>
            <p key={idx} className={`message ${ (item.type==0)?" alt_ok" : (item.type==1||item.type==2)?" alt_err" : (item.type==3)?" alt_warn" : "" }`} >{item.text}</p>
          )
      }
    </div>
  );

}

export default MessagesOverlay;
