import React from 'react';

import Code404Page from './status/404Page';


const StatusPage = ({ page, props }) => {

  const getPage = () => {
    switch (page) {
      case '404':
      default:
        return <Code404Page />;
    }
  }

  return(
    <section id="simplepage" className="row-cols">
      <aside className="col-left">
      </aside>
      <main className="col-middle">
        {
           getPage()
        }
      </main>
      <aside className="col-right">
      </aside>
    </section>
  );

}

export default StatusPage;
