import React from 'react';
import { useHistory, Link } from 'react-router-dom';

// Parts
import ProdFlags from 'components/parts/product/prodflags';
import ProdPrice from 'components/parts/product/prodprice';
import ProdTocart from 'components/parts/product/prodtocart';
import ProdStock from 'components/parts/product/prodstock';
import A from 'components/a';

// Helpers
import { isset, empty } from 'helpers/core';
import { countDiscount } from 'helpers/money';
import * as Url from 'helpers/url';

// Context
import {useProducts} from 'context/productsContext';

// Images
import img_empty from 'assets/images/noimg.png';


const Product = ({ product, showRemove }) => {
  const {products, infoSet, infoClear} = useProducts();
  const history = useHistory();


  const handleGoToProduct = () => {
    history.push( Url.productLinkSlugify(product.id, product.text.title) );
  }

  const handleHideInfo = () => {
    infoClear();
  }

  const handleShowInfo = (e, info) => {
    //infoSet(info, e.clientX, e.clientY);
    infoSet(info, e.pageX, e.pageY);
  }


  return (
    <article className={`product${ (product.stock.stock > 0) ? "" : " zerostock" }`}>

      <div className="prod-title">
        {/*<A
          to={ Url.productLinkSlugify(product.id, product.text.title) }
          classRoot={"inverted"}
          text={ <><span><b>{product.code.code}</b>{ (empty(product.text.manufacturer)) ? "" : " / "+product.text.manufacturer }</span>{product.text.title}</> }
        />*/}
        <Link to={ Url.productLinkSlugify(product.id, product.text.title) } className="inverted prod-title">{ <><span><b>{product.code.code}</b>{ (empty(product.text.manufacturer)) ? "" : " / "+product.text.manufacturer }</span>{product.text.title}</> }</Link>
      </div>

      <ProdPrice priceTax={product.price.price} priceOldTax={product.price.recommended} priceRefundableTax={product.price.fee.refundable} tax={product.price.tax} />

      <ProdTocart product={product} showRemove={showRemove} />

      <ProdStock product={product} quantity={product.stock.stock} local={product.stock.local} delivery={product.stock.delivery} />

      <ProdFlags onClick={ () => handleGoToProduct() } flags={product.flag} discount={countDiscount(product.price.recommended, product.price.price)} vertical={ ! empty(product.image.thumbnail) } />

      { // Image
        (! empty(product.image.thumbnail)) &&
          <Link to={ Url.productLinkSlugify(product.id, product.text.title) } className="prod-img">
            <img src={ product.image.thumbnail ? product.image.thumbnail : img_empty } alt={"Foto produktu "/* + product.text.title*/} className={product.image.thumbnail ? "img-fit" : "img-empty"} />
          </Link>
      }

      { // Description
        (! empty(product.text.description)) &&
          <div className="prod-description">
            <div>
              {
                product.text.description.split('\n').map((item, key) => {
                  return <span key={key}>{item}<br/></span>
                })
              }
            </div>
            {/*
              (product.text.description.length > 80) &&
                <div className="prod-description-showmore">v</div>
            */}
          </div>
      }

      <div className="prod-info">
        <div className="prod-codeour">{product.code.our}</div>
        <div><span onMouseEnter={ (e) => handleShowInfo(e, product.id) } onMouseOut={handleHideInfo} className="icon is-info"></span></div>
      </div>
    </article>
  );

}


Product.defaultProps = {
  showRemove: true,
};

export default Product;
