
export const address = {
  firstname: "",
  lastname: "",
  street: "",
  city: "",
  zip: "",
  country: "CZ",
  company: "",
  bin: "",
  tin: "",
  email: "",
  phone: "",
}


export async function initAddress() {
  return {...address};
}
