import { useState, useEffect } from 'react';

// Parts
import Loader from 'components/parts/loader';

// Context
import {useProducts} from 'context/productsContext';

// Data
import * as DataProduct from 'data/productData';


const InfoOverlay = () => {
  const PRODUCT_WIDTH = 260;
  const MODAL_WIDTH = 400;
  const POSITION_OFFSET = 10;

  const {products, setProduct} = useProducts();
  const [params, setParams] = useState(null);

  const show = (! (products.infoIndex.productIndex === undefined || products.infoIndex.productIndex === null));


  // GET

  const loadInfo = async (productId) => {
    const tmp_prods = products.all.filter( item => (item.id == productId) );
    if (tmp_prods.length === 0) {
      return;
    }
    const product = tmp_prods[0];

    try {
      let infoData = await DataProduct.getInfo( product.id, product.slug, "params" );
      if (show) {
        setParams(infoData.params);
      }
    } catch(e) {
    }
  };


  useEffect( async () => {
    if (! show) {
      setParams(null);
      return;
    }

    const fetchData = async () => {
      await loadInfo(products.infoIndex.productIndex);
    };
    fetchData();
  }, [products.infoIndex] );


  return(
    <div id="modal" className={`table ${ show ? "show" : "" }`}
      style={{
//        top: (products.infoIndex.mouseY < (window.innerHeight / 2)) ? :,
        top: products.infoIndex.mouseY + POSITION_OFFSET,
        left: (products.infoIndex.mouseX < (window.innerWidth / 2)) ? (products.infoIndex.mouseX /*- PRODUCT_WIDTH*/ + POSITION_OFFSET) : (products.infoIndex.mouseX - MODAL_WIDTH - POSITION_OFFSET )
      }}
    >
      {
        (params === null)
        ?
          <Loader type="block" text="Načítám informace ..." />
        :
          <div className="table-all tab-parameters">
            {
              (params.length === 0)
              ?
                <div className="table-header row empty">
                  <div className="col-12">žádný záznam</div>
                </div>
              :
                <>
                  <div className="table-header row">
                    <div className="col-5">název parametru</div><div className="col-7">hodnota</div>
                  </div>
                  <div className="table-data">
                    { params.map( (item, idx) =>
                      <div key={idx} className="row">
                        {/* <div className="col-5">{item.name}{ (item.unit) ? <span> [{item.unit}]</span> : "" }</div><div className="col-7">{item.value}</div> */}
                        <div className="col-5">{item.name}</div><div className="col-7">{item.value}</div>
                      </div>
                    )}
                  </div>
                </>
            }
          </div>
      }

      {/*
        global.messages.map( (item, idx) =>
            <p key={idx} className={`message ${ (item.type==0)?" alt_ok" : (item.type==1||item.type==2)?" alt_err" : (item.type==3)?" alt_warn" : "" }`} >{item.text}</p>
          )
      */}
    </div>
  );

}

export default InfoOverlay;


InfoOverlay.defaultProps = {
  mousePosition: null,
}
