import React, { useContext, useEffect } from 'react';
import { useHistory, NavLink, Link } from 'react-router-dom';

// Parts
import Loader from 'components/parts/loader';

// Context
import {useGlobal} from 'context/globalContext.js';
import {useAuth} from 'context/authContext.js';

// Data
import * as UserData from 'data/userData';


const ControlUser = () => {
  // Hooks
  const history = useHistory();
  const {global, setOverlay, messagesSet} = useGlobal();
  const {auth, userSet, adminSet} = useAuth();


  // After mount
  useEffect( async () => {
    if (auth.user === undefined) {
      const info = await userLoad();
      userSet( (! info || ! info.user) ? null : info.user );
      adminSet( (! info || ! info.admin) ? null : info.admin ); // If has also "admin" info -> Set admin
    }
  }, []);


  // GET

  const userLoad = async () => {
    try {
      const data = await UserData.loginGet();
      return data;
    } catch(e) {
      return null;
    }
  }

  // DELETE

  const userLogout = async () => {
    try {
      return await UserData.loginDel();
    } catch(e) {
      return null;
    }
  }


  // Handle

  const handleShowLoginOverlay = e => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setOverlay("login");
  };


  const handleLogout = async () => {
    const prevUser = auth.user;
    userSet(undefined);
    const logout = await userLogout();
    if (logout == 0 || logout == -1) {
      messagesSet([{type:0, text:"Odhlášení proběhlo v pořádku."}]);
      userSet(null);
      history.push("/");
    } else {
      messagesSet([{type:1, text:"Nepodařilo se odhlásit uživatele."}]);
      userSet(prevUser);
    }

  }


  // RENDER >>>


  if (auth.user === undefined) {
    return <Loader />;
  }


  return (
    <ul className="menu-with-children">
      <li onClick={ () => (auth.user === null) ? handleShowLoginOverlay() : history.push("/login") } className={`control control-user pointer${ auth.user === null ? "" : " small" }`}>
        <div className={`icon${ auth.user === null ? " is-user" : " ir-user" }`}></div>
        {
          (auth.user !== null) &&
            <div>{auth.user.firstname} {auth.user.lastname}</div>
        }
      </li>
      {
        (auth.user !== null) &&
          <ul className="menu-child">
            <li>
              <NavLink to="/garaz">Moje garáž</NavLink>
            </li>
            <li>
              <NavLink to="/objednavky">Objednávky</NavLink>
            </li>
            <li>
              <NavLink to="/ucet">Můj účet</NavLink>
            </li>
            <li>
              <div onClick={handleLogout} className="pointer">Odhlásit</div>
            </li>
          </ul>
      }
    </ul>
  );
}

export default ControlUser;
