// Functions
import {isset} from 'helpers/core';


export function formatCzk(price) {
  if (! isset(price)) {
    return undefined;
  }
  return price.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ");
}


// From "TAX" to "NON-Tax"
export function priceFromTax(price, tax = 21) {
  if (! isset(price)) {
    return undefined;
  }
  //return parseFloat( ( price * (1 - (tax/100)) ).toFixed(2) );  // NOT WORKING!
  return parseFloat( ( price / (1 + (tax/100)) ).toFixed(2) );
}

// From "NON-Tax" to "TAX"
export function priceToTax(price, tax = 21) {
  if (! isset(price)) {
    return undefined;
  }
  return parseFloat( ( price * (1 + (tax/100)) ).toFixed(2) );
}


export function countDiscount(oldPrice, newPrice) {
  if ( (! isset(oldPrice)) || (oldPrice <= 0.0) || (! isset(newPrice)) || (newPrice <= 0.0) ) {
    return null;
  }
  return ( (oldPrice - newPrice) / oldPrice ) * -100;
}
