import { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Forms
import {setState, Check} from 'components/form';
import PartialAddressInvoice from 'components/forms/_partials/address-invoice';
import PartialAddressDelivery from 'components/forms/_partials/address-delivery';
import PartialOrderShipping from 'components/forms/_partials/order-shipping';
import PartialOrderPayment from 'components/forms/_partials/order-payment';
import PartialOrderOthers from 'components/forms/_partials/order-others';

// Parts
import MessagesBox from 'components/parts/messagesbox';
import ProductRow from 'components/parts/productrow';
import Loader from 'components/parts/loader';

// Functions
import { isset, isArray } from 'helpers/core';
import * as money from 'helpers/money';

// Context
import {useOrder} from 'context/orderContext';
import {useProducts} from 'context/productsContext';
// Data
import * as CartData from 'data/cartData';


const FormOrder = ({ products, shipping, payments,
    dataUse, setDataUse,
    dataAddressInvoicing, setDataAddressInvoicing,
    dataAddressShipping, setDataAddressShipping,
    dataOrder, setDataOrder
  }) => {

  // Hooks
  const history = useHistory();
  const {order, paymentSet, orderedCopy} = useOrder();
  const {productsClear} = useProducts();

  const [statusOrder, setStatusOrder] = useState([]);


  const placeOrder = async (e) => {
    e.preventDefault();
    const order = await orderInsert( {
        use : dataUse,
        address_invoicing : dataAddressInvoicing,
        address_delivery : dataAddressShipping,
        order : dataOrder
      } );
  }


  // POST

  const orderInsert = async (orderData) => {
    setStatusOrder(undefined);
    try {
      const order = await CartData.insertOrder(orderData);
      processOrderAnswer(order);
      return order.data.order;
    } catch (ex) {
      console.log(ex);
      processOrderAnswer(null);
      return null;
    }
  }


  const processOrderAnswer = (ordered) => {
    // If ordered is undefined -> remove all messages
    if (ordered === undefined) {
      setStatusOrder([]);
      return;
    }

    window.scrollTo(0, 0);  // Scroll to top

    // If connection error
    if (ordered === null) {
      setStatusOrder([ {type:2, text: "Nepodařilo se odeslat objednávku na server. Zkuste to znovu nebo nás kontaktujte."} ]);
      return;
    }

    // Some errors -> don't submited
    if (ordered.status != 0) {
      setStatusOrder(ordered.messages);
      return;
    }

    // ORDER CREATED >>>
    orderedCopy(products);
    productsClear();  // TODO: Clear only "cart" products?
    setStatusOrder([ {type:0, text: "Děkujeme za Vaši objednávku, o průběhu Vás budeme informovat."} ]);

    const payment = {
      type: (ordered.data.payment.type) ? ordered.data.payment.type : null
    };
    let link = null;
    switch (payment.type) {
      case 1: // Use bank account
        link = "/objednano?platba=b";
        payment["bank_account"] = ordered.data.payment.bank_account;
        payment["bank_vs"] = ordered.data.payment.bank_vs;
        payment["pay"] = ordered.data.order.total_vat;
        break;
      case 2: // Card online
        link = "/objednano?platba=" + ordered.id;
        break;
      case 3: // Use ondelivery
        link = "/objednano?platba=c";
        break;
      case 4: // Use cash/card (on branch office)
        link = "/objednano?platba=a";
        break;
      default:  // Unknown
        link = "/objednano?platba=0";
        break;
    }
    
    paymentSet(payment);

    history.push(link);
  }


  const handleClearPayment = () => {
    console.log("TODO: clear");
  }


  // RENDER >>>


  if (! products) {
    return null;
  }


  // TODO: Move status box to separated component


  let selectedShipping = undefined;

  if ( isArray(shipping) && isset(dataOrder.shipping) ) {
    selectedShipping = shipping.filter( item => item.id == dataOrder.shipping );
    if (selectedShipping.length !== 0) {
      selectedShipping = selectedShipping[0];
    }
  }


  return (
    <form>
      <MessagesBox messages={statusOrder} />

      <h2>Produkty</h2>
      <div id="products">
        {
          products.map( (item,idx) =>
            <ProductRow key={idx} product={item} showInfo={true} showFlags={true} />
          )
        }
      </div>

      <hr />

      <div>
        <div id="price-sum" className="col-11">
          <small>CELKEM &nbsp; <span className="large">{ money.formatCzk(order.sum.productsTax + order.sum.feesTotalTax) }</span> Kč s DPH<br />
          { money.formatCzk(order.sum.productsNoTax + order.sum.feesTotalNoTax) } Kč bez DPH</small>
        </div>
        <div className="col-1">
        </div>
      </div>

      <h2>Fakturační adresa</h2>
      {
        (dataAddressInvoicing === undefined)
        ?
          <Loader />
        :
          <PartialAddressInvoice data={dataAddressInvoicing} setData={setDataAddressInvoicing} dataUse={dataUse} setDataUse={setDataUse} />
      }

      <h2>Doručovací adresa</h2>
      {
        (dataAddressShipping === undefined)
        ?
          <Loader />
        :
          <PartialAddressDelivery data={dataAddressShipping} setData={setDataAddressShipping} dataUse={dataUse} setDataUse={setDataUse} />
      }

      <div id="order-select" className="form-row form-2">
        <div>
          <h2>Doprava</h2>
          {
            (shipping === undefined)
            ?
              <Loader />
            :
              <PartialOrderShipping
                name="shipping"
                all={shipping}
                idKey="id"
                valueKey="name"
                priceKey="price"
                taxKey="tax"
                data={dataOrder}
                setData={setDataOrder}
                doClearPayment={handleClearPayment}
              />
          }
        </div>
        <div>
          <h2>Platba</h2>
          {
            (payments === undefined)
            ?
              <Loader />
            :
              <PartialOrderPayment
                name="payment"
                all={payments}
                idKey="id"
                valueKey="name"
                priceKey="price"
                taxKey="tax"
                data={dataOrder}
                setData={setDataOrder}
                selectedShipping={selectedShipping}
                typeKey="type"
                shortcutKey="value"
              />
          }
        </div>
      </div>

      <h2>Podrobnosti</h2>
      <PartialOrderOthers
        completeName="completeonly"
        notesName="notes"
        data={dataOrder}
        setData={setDataOrder}
      />

      <h2>Objednávka</h2>
      <div className="form-row">
        <div id="price-total">
          CENA CELKEM <span>{ money.formatCzk( Math.round(order.sum.totalTax) ) }</span> Kč s DPH<br />
          <small><span>{ money.formatCzk( Math.round(order.sum.totalNoTax) ) }</span> Kč bez DPH</small>
        </div>
        <div className="form-col">
          <Check name="terms" labelText="Souhlasím s obchodními podmínkami a se zpracováním osobních údajů" value={dataOrder["terms"]} onChange={(e) => setState(e,dataOrder,setDataOrder,1)} />
          { /*<label for="terms" className="wrap">Souhlasím s obchodními podmínkami a s ochranou osobních údajů</label>*/ }
          <button onClick={placeOrder} disabled={(statusOrder === undefined)} >{ (statusOrder === undefined) ? <Loader /> : "Objednat a zavázat se k platbě" }</button>
        </div>
      </div>

    </form>
  );

}

export default FormOrder;
