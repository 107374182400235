import React from 'react';
//import { Link } from 'react-router-dom';

// Context
import {useGlobal} from 'context/globalContext';
import {useProducts} from 'context/productsContext';


const ProdStock = ({ quantity, local, delivery, product }) => {
  const {setOverlay} = useGlobal();
  const {productsReplaceType, productSet} = useProducts();


  const showDemand = () => {
    product.types = {...product.types, demand:null };
    productsReplaceType({ demand:[product] });
    setOverlay("demand");
  };


  let textQuantity = "kus";
  if (quantity == 1 || quantity == -1) {
    // Nothing
  } else if (2 <= quantity && quantity <= 4 || -4 <= quantity && quantity <= -2) {
    textQuantity += "y";
  } else {
    textQuantity += "ů";
  }


  if (quantity > 0) {
    return(
      <div className="prod-stock">
        {
          (local)
          ?
            <div className="stock-on stock-local"><b>skladem</b> <span>{quantity} {textQuantity}</span></div>
          :
            <div className="stock-on"><b>do druhého dne</b> <span>{quantity} {textQuantity}</span></div>
        }
        {
          (delivery !== null) &&
            <div><span>doručíme do {delivery.getDate()}. {delivery.getMonth()+1}. {delivery.getFullYear()}</span></div>
        }
      </div>
    );
  } else if (quantity == 0) {
    return(
      <div className="prod-stock">
        <div><span>není skladem</span></div>
      </div>
    );
  } else if (quantity == -1) {
    return(
      <div className="prod-stock">
        <div className="stock-ask"><span>dostupnost {
          (product === undefined)
          ?
            <>na dotaz</>
          :
            <b><a onClick={ (e) => showDemand(e) }>na dotaz</a></b>
        }</span></div>
        <div><span>termín potvrdíme</span></div>
      </div>
    );
  } else {
    return(
      <div className="prod-stock">
        {
          (local)
          ?
            <div className="stock-on stock-local"><b>skladem</b> <span>více než {-1*quantity} {textQuantity}</span></div>
          :
            <div className="stock-on"><b>do druhého dne</b> <span>více než {-1*quantity} {textQuantity}</span></div>
        }
        {
          (delivery !== null) &&
            <div><span>doručíme do {delivery.getDate()}. {delivery.getMonth()+1}. {delivery.getFullYear()}</span></div>
        }
      </div>
    );
  }

}

export default ProdStock;
