import React from 'react';


const RetailPage = ({ props }) => {

  return (
    <>

      <h1>Maloobchod</h1>
      
      <h2>Maloobchodní/velkoobchodní prodejna - pobočka Kosmonosy</h2>

      <p>Nabízíme náhradní díly na vozy Škoda, tisíce položek skladem. Pomůžeme s vyhledáním a identifikací dílu.</p>
      <p>Můžete si u nás i objednat náhradní díly na zahraniční vozy všech značek.</p>
      <p>Zde Vám můžeme nabídnout zboží nejen jako pro koncového zákazníka, ale také za velkoobchodní ceny, při registraci a nákupu na IČ. (Slevy od obratu 10.000 Kč bez DPH/rok)</p>

    </>
  );

}

export default RetailPage;
