import React from 'react';

// Forms
import {setState, Input, Check, Select, Label, optionsCountries} from 'components/form';


const PartialAddressInvoice = ({ data, setData, dataUse, setDataUse }) => {

  // RENDER >>>

  return (
    <div className="form-partial form-partial-address-invoice form-row form-3">

      <div className="form-col">
        { (data["firstname"] !== undefined) &&
            <Input labelText="Jméno" name="firstname" value={data["firstname"]} onChange={(e) => setState(e,data,setData)} />
        }
        { (data["lastname"] !== undefined) &&
            <Input labelText="Příjmení" name="lastname" value={data["lastname"]} onChange={(e) => setState(e,data,setData)} />
        }
        { (data["email"] !== undefined) &&
            <Input labelText="E-mail" name="email" value={data["email"]} onChange={(e) => setState(e,data,setData)} />
        }
        { (data["phone"] !== undefined) &&
            <Input labelText="Telefon" name="phone" value={data["phone"]} onChange={(e) => setState(e,data,setData)} />
        }
      </div>

      <div className="form-col">
        { (data["street"] !== undefined) &&
            <Input labelText="Ulice, číslo" name="street" value={data["street"]} onChange={(e) => setState(e,data,setData)} />
        }
        { (data["city"] !== undefined) &&
            <Input labelText="Město" name="city" value={data["city"]} onChange={(e) => setState(e,data,setData)} />
        }
        { (data["zip"] !== undefined) &&
            <Input labelText="PSČ" name="zip" value={data["zip"]} onChange={(e) => setState(e,data,setData)} />
        }
        { (data["country"] !== undefined) &&
            <Select labelText="Stát" options={optionsCountries} name="country" value={data["country"]} onChange={(e) => setState(e,data,setData)} empty={false} />
        }
      </div>

      <div className="form-col">
        <Check labelText="Nakupuji na firmu" name="company" value={dataUse["company"]} onChange={(e) => setState(e,dataUse,setDataUse,1)} />
        { (dataUse["company"]) &&
            <>
              { (data["company"] !== undefined) &&
                <Input labelText="Název" name="company" value={data["company"]} onChange={(e) => setState(e,data,setData)} />
              }
              { (data["bin"] !== undefined) &&
                <Input labelText="IČO" name="bin" value={data["bin"]} onChange={(e) => setState(e,data,setData)} />
              }
              { (data["tin"] !== undefined) &&
                <Input labelText="DIČ" name="tin" value={data["tin"]} onChange={(e) => setState(e,data,setData)} />
              }
            </>
        }
      </div>

    </div>
  );
}

export default PartialAddressInvoice;
