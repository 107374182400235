import { useRef } from 'react';

// Forms
import FormDemand from 'components/forms/demand';

// Context
import {useGlobal} from 'context/globalContext.js';
import {useProducts} from 'context/productsContext';


const DemandOverlay = () => {
  // Hooks
  const {global, setOverlay} = useGlobal();
  const {all : products} = useProducts().products;
  const setCallbackRef = useRef();
  const clearCallbackRef = useRef();


  // Handle

  const handleCloseOverlay = () => {
    setOverlay(null);
  }


  // RENDER >>>


  if (global.overlay !== "demand")
  {
    return null;
  }

  let product = products.filter( item => "demand" in item.types );
  if (product && product.length > 0) {
    product = product[0];
  } else {
    product = null;
  }

  return (
    <section id="overlay-demand" className="popup overlay">

      <div className="close">
        <span onClick={handleCloseOverlay} className="btn-icon icon is-times"></span>
      </div>

      <div>
        <h1>Poptávka produktu</h1>
        {
          product &&
            <p>{`${product.code.code}${(product.text.manufacturer) ? " / "+product.text.manufacturer : ""}: ${product.text.title}`}</p>
        }
        <FormDemand product={product} />
      </div>

    </section>
  );

};

export default DemandOverlay;
