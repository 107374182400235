import React from 'react';


const GoodsPage = ({ props }) => {

  return (
    <>

      <h1>Sortiment</h1>

      <p>Nabízíme široký sortiment náhradních dílů především na osobní automobily všech značek. Náš velkoobchodní sklad je specializován na autodíly Škoda, zde seženete běžné díly pro servis jakou jsou součásti brzd, motorové díly, rozvody, ventily, spojky, díly podvozků, části karoserie, běžné příslušenství, atd.</p>
      <p>Jsme však schopni dodat i ne tak běžné díly, které Vám pomůžeme identifikovat a poté můžeme dodat. To
          vše za
          velmi konkurenceschopné ceny.
      </p>
      <p> Dodáváme jak kvalitní značkové díly tak i levnější alternativy.
      </p>
      <p> Zajistíme autodíly také na zahraniční vozidla za skvělé ceny a v některých případech i na nákladní
          automobily.
      </p>

      {/* <img src="http://www.prondo.cz/uploads/modules/photogalery/280_image.jpg" alt="TWR" width="5em">
      <img src="http://www.prondo.cz/uploads/modules/photogalery/281_image.jpg" alt="TYLL" width="5em">
      <img src="http://www.prondo.cz/uploads/modules/photogalery/281_image.jpg" alt="LUK" width="5em"> */}

    </>
  );

}

export default GoodsPage;
