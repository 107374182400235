import { useRef, useState } from 'react';

// Parts
import VehicleSelector from 'components/parts/vehicleselector';
import Loader from 'components/parts/loader';

// Helpers
import {changeTitle} from 'helpers/page';
import {strYears,strVehicle,strEngine} from 'helpers/vehicles';

// Context
import {useGlobal} from 'context/globalContext.js';
import {useAuth} from 'context/authContext.js';
import {useProducts} from 'context/productsContext';
// Data
import * as AccountData from 'data/accountData';


const VehicleOverlay = () => {
  // Hooks
  const {global, messagesAdd, setOverlay} = useGlobal();
  const {auth, userSet} = useAuth();
  const {products} = useProducts();
  const {vehicle} = useProducts().products;
  const [syncGarage, setSyncGarage] = useState(false);

  const setCallbackRef = useRef();
  const clearCallbackRef = useRef();


  // Handle

  const handleCloseOverlay = () => {
    setOverlay(null);
  }


  const handleVehicleToGarage = async e => {
    e.preventDefault();
    if (! auth.user) {
      messagesAdd(3, "Musíte se nejdříve přihlásit, pak můžete přidat vozidlo do garáže.");
      return;
    }
    if (! vehicle || ! vehicle.modification_key) {
      messagesAdd(3, "Nejdříve vyberte automobil.");
      return;
    }

    setSyncGarage(undefined);

    const status = await garageInsert(vehicle.modification_key);

    switch (status) {
      case 0:
        messagesAdd(0, "Automobil byl přidán do Vaší garáže a nastaven jako výchozí.");
        setSyncGarage(true);
        return;
      case -1:
        messagesAdd(3, "Automobil se již nachází ve Vaší garáži.");
        setSyncGarage(true);
        return;
      case 5100:
        messagesAdd(2, "Musíte se nejdříve přihlásit, pak můžete přidat vozidlo do garáže.");
        break;
      case 6402:
        messagesAdd(1, "Systémová chyba: není vybráno vozidlo.");
        break;
      default:
        messagesAdd(1, "Systémová chyba " + status + ", prosím kontaktujte nás.");
        break;
    }

    setSyncGarage(false);
  }

  const handleClearVehicle = e => {
    e.preventDefault();

    changeTitle();

    //console.log( callbackRef );
    //callbackRef.current = handleClearVehicle;
    clearCallbackRef.current();
    //handleCloseOverlay();
  }

  const handleSetVehicle = e => {
    e.preventDefault();

    changeTitle("PRONDO - "+ vehicle.manufacturer_text +" "+ vehicle.model_text +" "+ vehicle.modification_kw +"kW "+ vehicle.modification_engine);

    //console.log( callbackRef );
    //callbackRef.current = e;
    setCallbackRef.current();
    handleCloseOverlay();
  }

  // POST

  const garageInsert = async (vehicle) => {
    try {
      const data = await AccountData.garageInsert(vehicle);
      return data;
    } catch (ex) {
      return null;
    }
  }


  // RENDER >>>


  if (global.overlay !== "vehicle")
  {
    return null;
  }

  const model_years = strYears(products.vehicle.model_year_from, products.vehicle.model_year_to);
  const modification_vehicle = strVehicle(products.vehicle.modification_kw, products.vehicle.modification_hp, products.vehicle.modification_ccm);
  const modification_engine = strEngine(products.vehicle.modification_engine);


  return (
    <section id="overlay-vehicle" className="dialog overlay">

      <div className="close">
        <span onClick={handleCloseOverlay} className="btn-icon icon is-times"></span>
      </div>

      <p><small>Vybráno:</small> {products.vehicle.manufacturer_text} {products.vehicle.model_text} <small>{(model_years.length === 0) ? "" : model_years}</small> {products.vehicle.modification_text} <small>{(modification_vehicle.length === 0) ? "" : "["+modification_vehicle+"]"}{(modification_engine.length === 0) ? "" : " "+modification_engine}</small></p>

      <div className="stretch">
        <VehicleSelector
            onClear={ (callback) => {clearCallbackRef.current=callback;} }
            onSet={ (callback) => {setCallbackRef.current=callback;} }
          />
      </div>

      <div className="buttons">
        <button onClick={handleClearVehicle} className="alt">Zrušit filtr</button>
        <button onClick={handleVehicleToGarage} disabled={(vehicle.modification_key === null) || (syncGarage === undefined)} className={/*(syncGarage === true) ? "alt_ok" : ""*/""}>
          {
            (syncGarage === undefined)
            ? <Loader />
            : "Přidat do mé garáže"
          }
        </button>
        <button onClick={handleSetVehicle} disabled={/*(vehicle.modification_key === null)*/false}>Zavřít</button>
      </div>

    </section>
  );

};

export default VehicleOverlay;
