import config from 'config.json';
import * as Data from '../data';
import * as Http from '../httpData';
import * as Core from 'helpers/core';
/*import * as Helpers from 'utils/helpers';
import * as Dt from 'utils/dt';*/


const previewUrl = config.apiUrl + '/admin/preview';



export async function previewPricelevel(id) {
  const params = {
    pricelevel: (Core.empty(id) ? 0 : id)
  };
  const response = await Http.patch( previewUrl + '/pricelevel' + config.apiFormat, params, id );
  return response.data.pricelevel;
}
