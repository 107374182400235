import * as React from "react";

const OrderContext = React.createContext();


const init = {
  sum: {
    productsNoTax: 0,
    productsTax: 0,
    feesNoTax: {},
    feesTax: {},
    feesTotalNoTax: 0,
    feesTotalTax: 0,
    shippingNoTax: 0,
    shippingTax: 0,
    paymentsNoTax: 0,
    paymentsTax: 0,
    totalNoTax: 0,
    totalTax: 0
  },
  shipping: {
    selected_id: null,
    selected_key: null,
  },
  payment: {
    id: null
  },
  ordered : {
    products: []
  }
};


const sumTotal = (oldData) => {
  oldData.totalNoTax = oldData.productsNoTax + oldData.shippingNoTax + oldData.paymentsNoTax;
  oldData.feesTotalNoTax = 0;
  for (let key in oldData.feesNoTax) {
    oldData.feesTotalNoTax += oldData.feesNoTax[key];
    oldData.totalNoTax += oldData.feesNoTax[key];
  }

  oldData.totalTax = oldData.productsTax + oldData.shippingTax + oldData.paymentsTax;
  oldData.feesTotalTax = 0;
  for (let key in oldData.feesTax) {
    oldData.feesTotalTax += oldData.feesTax[key];
    oldData.totalTax += oldData.feesTax[key];
  }
}


/*
* Each dispatcher must return object {} with new data to enter into new state
*/
const dispatcher = (action, oldData, newData) => {

  switch (action) {

    case 'SUM_SET':
      for (let [key, value] of Object.entries(newData)) {
        if (! (key in oldData.sum)) {
          throw new Error(`Order SUM action: Unsupported sync type: ${key}`);
        }
        oldData.sum[key] = value;
      }
      sumTotal(oldData.sum);
      break;


    case 'SHIPPING_SET':
      oldData.shipping = newData;
      break;


    case 'PAYMENT_SET':
      oldData.payment = newData;
      break;


    case 'ORDERED_COPY':
      oldData.ordered.products = [...newData];
      break;
    

    default:
      throw new Error(`ORDER context: Unsupported action type: ${action.type}`);
  }
}



function orderReducer(state, action) {
  //console.log("Debug ORDER state", action.type, action.payload);

  let newState = { ...state };

  if ( action.type == 'MULTI' ) {

    action.payload.forEach( dispatch => {
      dispatcher( dispatch.type, newState, dispatch.payload );
    })

  } else {

    dispatcher( action.type, newState, action.payload );

  }

  //console.log( newState );
  return newState;
}


// Own hook for reading/writing values/states
function useOrder() {
  const context = React.useContext(OrderContext);
  if (! context) {
    throw new Error("ORDER context: useOrder() must be used within a OrderProvider");
  }
  const [state, dispatch] = context;

  // OWN OPTIONAL DISPATCH methods here:
  const sumSet = (sum) => dispatch({ type: 'SUM_SET', payload: sum });
  const shippingSet = (shipping) => dispatch({ type: 'SHIPPING_SET', payload: shipping });
  const paymentSet = (payment) => dispatch({ type: 'PAYMENT_SET', payload: payment });
  const orderedCopy = (ordered) => dispatch({ type: 'ORDERED_COPY', payload: ordered });


  return {
    order : state,
    orderDispatch : dispatch,
    sumSet,
    shippingSet,
    paymentSet,
    orderedCopy
  }
}


// Providing context in app tree
function OrderProvider(props) {
  const [state, dispatch] = React.useReducer(orderReducer, init);
  const value = React.useMemo(() => [state, dispatch], [state]);
  return <OrderContext.Provider value={value} {...props} />
}


export {OrderProvider, useOrder};
