import React from 'react';


const Contact = ({ classes }) => {

  const contact = [
    { icon: "is-at", text: "<a href=\"mailto:info@prondo.cz\">info@prondo.cz</a>" },
    { icon: "ib-facebook", text: "<a href=\"https://www.facebook.com/PRONDO.CZ/\" target=\"_blank\">PRONDO.CZ</a>" },
    { icon: "is-mobile", text: "+420 326 728 002" },
    { icon: "is-map-marker", text: "Víta Nejedlého 742<br/>Kosmonosy 293 06" },
  ];

  return(
    <section className={`contact ${classes}`}>
      {
        contact.map( item =>
          <div key={item.text} className="row">
            <div className="col col-4"><div className={`icon ${item.icon}`}></div></div>
            {/*<div className="col col-8">{item.text.split('\n').map(i => {
                return <>{i}<br/></>
              }) }
            </div>*/}
            <div className="col col-8" dangerouslySetInnerHTML={{ __html: item.text }}></div>
          </div>
        )
      }
    </section>
  );

}

export default Contact;
