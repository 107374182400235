// Helpers
import {changeTitle} from 'helpers/page';
import {/*unifySearchData,*/ searchRemaining} from 'helpers/search';
import {strYears,strVehicle,strEngine} from 'helpers/vehicles';

// Context
import {useGlobal} from 'context/globalContext.js';
import {useProducts} from 'context/productsContext';
// Data
import * as DataProduct from 'data/productData';

// Config
import configFile from 'config';


const MenuSub = () => {
  // Hooks
  const {global, setOverlay} = useGlobal();
  const {products, searchSetText, searchSetInterchangeable, syncSet, productsReplaceType, productsMerge} = useProducts();


  // GET
  // TODO: The same function as in "productsPage"
  const searchLoad = async (text, exact, vehicle, page = 1) => {
    const type = (exact == true) ? "exact" : null;
    try {
      const data = await DataProduct.search({ text, type, vehicle, page });
      // Unify all search categories into single "products"
      //const search = unifySearchData(data);
      if ( vehicle !== 0 ) {
        productsReplaceType({ search: data.products });
      } else {
        productsMerge( data.products ); // TYPE CHANGED TO "search"
      }
      // TODO: MAKE IT WORKS WITH MERGE:!!!???
      //this.props.dispatch({ type: 'PRODUCTS_MERGE', payload: products });
      return 4;
    } catch(e) {
      console.log(e);
      return e.code * -1;
    }
  }


  // Handle

  const handleSearch = async e => {
    if (e !== undefined) {
      e.preventDefault();
    }

    // Prevent another search when searching
    if (products.sync.search === 2) {
      return;
    }

    changeTitle("PRONDO - "+ products.search.text);

    syncSet({search:2});
    handleShowSearch();
    const result = await searchLoad(products.search.text, products.search.interchangeable, products.vehicle.id);
    syncSet({search:result});
  };

  const handleSearchTextChange = ({currentTarget : input}) => {
    syncSet({search:1});  // Important to show "Hledání spustít kliknutím na ikonu..."
//    productsReplaceType({ search: [] });
    searchSetText(input.value);
  };

  const handleSearchInterchangeableChange = ({currentTarget : input}) => {
    syncSet({search:1});
//    productsReplaceType({ search: [] });
    searchSetInterchangeable(input.checked);
  }

  const handleShowVehicle = e => {
    if (e !== undefined) {
      e.preventDefault();
    }
    if ( global.overlay === "vehicle" ) {
      setOverlay(null);
    } else {
      setOverlay("vehicle");
    }
  };

  const handleHideVehicleIfSearch = e => {
    if (e !== undefined) {
      e.preventDefault();
    }
    if ( global.overlay === "vehicle" ) {
      setOverlay(null);
    }
  };

  const handleShowSearch = e => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setOverlay("search");
  };


  // RENDER >>>

  const model_years = strYears(products.vehicle.model_year_from, products.vehicle.model_year_to);
  const modification_vehicle = strVehicle(products.vehicle.modification_kw, products.vehicle.modification_hp, products.vehicle.modification_ccm);
  const modification_engine = strEngine(products.vehicle.modification_engine);

  const {remaining, remainingText} = searchRemaining(products.search.text, configFile.config.search.minSearchLength);

  return(
    <nav className="menu menu-sub">

      <div onClick={ () => handleShowVehicle() } className={`pointer${ (global.overlay === "vehicle") ? " active" : (global.overlay !== null) ? " inactive" : "" }`} >
        <div>
          {
            (products.vehicle.modification_key === null)
            ?
              "VYBERTE VÁŠ AUTOMOBIL"
            :
              <>
                {
                  (products.vehicle.id !== null) &&
                    <><span className="important">Aplikován filtr!</span><br /></>
                }
                {
                  (global.overlay !== "vehicle") &&
                    <>{products.vehicle.manufacturer_text} {products.vehicle.model_text} <small>{(model_years.length === 0) ? "" : model_years}</small> {products.vehicle.modification_text} <small>{(modification_vehicle.length === 0) ? "" : "["+modification_vehicle+"]"}{(modification_engine.length === 0) ? "" : " "+modification_engine}</small></>
                }
              </>
          }
        </div>
      </div>


      <form onClick={ () => handleHideVehicleIfSearch() } onSubmit={(e) => (e.preventDefault()) } className={/*pointer*/`${ (global.overlay === "search") ? " active" : (global.overlay !== null) ? " inactive" : "" }`}>

        <input onClick={ (e) => handleShowSearch(e) } onKeyUp={ (e) => (e.key === 'Enter') ? handleSearch(e) : null } onChange={ (e) => handleSearchTextChange(e) } value={products.search.text} type="text" placeholder="hledejte produkt..." />

        {
          (remaining <= 0) &&
          <>
            <div>
              <span onClick={ (e) => handleSearch(e) } disabled={(products.sync.search === 2)} className={`${(products.sync.search === 2) ? "loading " : global.overlay === "search" ? "inverted " : ""}btn-icon icon is-search`}></span>
            </div>

            <small>
              <input
                onChange={ (e) => handleSearchInterchangeableChange(e) }
                value={products.search.interchangeable}
                type="checkbox"
                defaultChecked={products.search.interchangeable}
              /> hledat přesný výraz
            </small>
          </>
        }
      </form>

    </nav>
  );

}

export default MenuSub;
