import React from 'react';


const Code404Page = ({ props }) => {

  return (
    <>

      <h1>404</h1>
      <p>Tuto stránku se nepodařilo najít.<br/>
        Pokud myslíte, že by zde mělo něco být, prosím kontaktujte nás.
      </p>

    </>
  );

}

export default Code404Page;
