
export const useAccount = {
  company: null,
  address_delivery: null,
}


export async function initUseAccount() {
  return {...useAccount};
}
