import axios from 'axios';
import * as Exc from 'fcs/exc';
// Helpers
import {isObject, isArray} from 'helpers/core';


function mapFromData(data, id = null) {
  /*return
    JSON.stringify(
      {
        id : id,
        data : ( Array.isArray(data) ) ? data : [ data ],
      }
    );*/
  return {
    id : id,
    data : (typeof data === 'object'/* && data !== null*/) ? data : { all:data },
  };
}


function mapToData(response) {
  if (response.data === null || typeof response.data !== 'object') {
    return {};
  } else {
    return response.data;
  }
}


function handleResponse(response, checkAuth) {
  if ((response === undefined) || (response.data === undefined) ) {
    return;
  }

  if (checkAuth && response.data.status == 5100) {
    //window.history.pushState({page:'login'}, 'Přihlaste se', '/login');
    window.location.href = '/login';
    return;
  }

  switch (response.data.status) {
    case undefined:
    default:
      break;
  }
}


function mergeData(data, formData = null) {
  if (formData === null) {
    formData = new FormData();
  }

  if ( isObject(data) ) {
    for ( const [key, value] of Object.entries(data) ) {
      formData.append(key, value);
    }
  } else if ( isArray(data) ) {
    for (let idx = 0; idx < data.length; idx++) {
      formData.append(idx, data[idx]);
    }
  } else {
    formData.append(0, data);
  }

  return formData;
}


/*
function getToken() {
  const tokenString = localStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token;
}

// Add a request interceptor + TOKEN HEADER
axios.interceptors.request.use(function (config) {
    const token = getToken();
    if (token === undefined) {
      console.log(window.location.pathname);
      if (window.location.pathname !== "/") {
        //toast.info("Vaše přihlášení vypršelo -> klikněte na ODHLÁSIT a znova se přihlašte.");
        localStorage.clear();
        window.location.href = "/";
      }
      return config;
    }

    config.headers.Authorization = "Bearer " + getToken();
    return config;
});
//OR: axios.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
*/
axios.interceptors.response.use(null, error => {
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

  if (! expectedError) {
    console.log("FZATLOUKAL.CZ Log: ", error);
    //alert("Nastala neočekávaná chyba při komunikaci se serverem.");
    //toast.error("Nastala neočekávaná chyba při komunikaci se serverem.");
  }

  return Promise.reject(error);
});


// https://pastebin.com/zEURbC6K

export async function get(url, parameters = {}, checkAuth = true) {
  const result = await axios.get(url, {
        params : parameters,
        withCredentials: true,
      }
    )
    .then( (response) => {
      handleResponse(response, checkAuth);
      if (response.status === 200) {
        return mapToData(response);
      }
      console.log( `Error "${url}": ${response.status}` );
      throw new Exc.HttpError( response.status, "" );
      //return [ response.status, "" ];
    })
    .catch( (err) => {
      console.log( `Error "${url}": ${err.toString()}` );
      throw new Exc.HttpError( err.response.status, err.toString() );
      //return [ err.response.status, err ];
    });

  return result;
}


export async function head(url, parameters = {}, checkAuth = true) {
  const result = await axios.head(url, {
        params : parameters,
        withCredentials: true,
      }
    )
    .then( (response) => {
      handleResponse(response, checkAuth);
      if (response.status === 200) {
        return mapToData(response);
      }
      console.log( `Error "${url}": ${response.status}` );
      throw new Exc.HttpError( response.status, "" );
      //return [ response.status, "" ];
    })
    .catch( (err) => {
      console.log( `Error "${url}": ${err.toString()}` );
      throw new Exc.HttpError( err.response.status, err.toString() );
      //return [ err.response.status, err ];
    });

  return result;
}


export async function post(url, data, id = null, parameters = {}, checkAuth = true) {
  return await axios.post( url, mapFromData(data, id), {
        params : parameters,
        withCredentials: true,
        //credentials: 'true',
        //headers: {
          //'Access-Control-Allow-Origin': '*',
          //'Content-type': 'application/json; charset=utf-8',
          //'Accept': 'application/json; charset=utf-8',
        //},
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        }
      }
    )
    .then( (response) => {
      handleResponse(response, checkAuth);
      if (response.status === 201) {
        return mapToData(response);
      }
      console.log( `Error "${url}": ${response.status}` );
      throw new Exc.HttpError( response.status, "" );
      //return [ response.status, "" ];
    })
    .catch( (err) => {
      console.log( `Error "${url}": ${err.toString()}` );
      //throw new Exc.HttpError( err.code, err.toString(), err.response );
      throw new Exc.HttpErrorWithResponse( err.response.status, err.toString(), err.response );
      //return [ err.response.status, err ];
    });

    /*return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        'Accept': 'application/json; charset=utf-8'
      },
      body: JSON.stringify( mapFromData(data, id) ),
  //    credentials: 'same-origin',
      credentials: 'include',
    });*/
}


export async function put(url, data, id, parameters = {}, checkAuth = true) {
  return await axios.put( url, mapFromData(data, id), {
        params : parameters,
        withCredentials: true,
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        }
      }
    )
    .then( (response) => {
      handleResponse(response, checkAuth);
      if (response.status === 200) {
        return mapToData(response);
      }
      console.log( `Error "${url}": ${response.status}` );
      throw new Exc.HttpError( response.status, "" );
      //return [ response.status, "" ];
    })
    .catch( (err) => {
      console.log( `Error "${url}": ${err.toString()}` );
      throw new Exc.HttpError( err.response.status, err.toString() );
      //return [ err.response.status, err ];
    });
}


export async function patch(url, data, id, parameters = {}, checkAuth = true) {
  return await axios.patch( url, mapFromData(data, id), {
        params : parameters,
        withCredentials: true,
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        }
      }
    )
    .then( (response) => {
      handleResponse(response, checkAuth);
      if (response.status === 200) {
        return mapToData(response);
      }
      console.log( `Error "${url}": ${response.status}` );
      throw new Exc.HttpError( response.status, "" );
      //return [ response.status, "" ];
    })
    .catch( (err) => {
      console.log( `Error "${url}": ${err.toString()}` );
      throw new Exc.HttpError( err.response.status, err.toString() );
      //return [ err.response.status, err ];
    });
}


export async function del(url, id = null, parameters = {}, checkAuth = true) {
  return await axios.delete(url, {
        data: { id },
        params : parameters,
        withCredentials: true,
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        }
      }
    )
    .then( (response) => {
      handleResponse(response, checkAuth);
      //if (response.status === 204) {  // 204 not returning data in response
      if (response.status === 200) {
        //return true;
        return mapToData(response);
      }
      console.log( `Error "${url}": ${response.status}` );
      throw new Exc.HttpError( response.status, "" );
      //return [ response.status, "" ];
    })
    .catch( (err) => {
      console.log( `Error "${url}": ${err.toString()}` );
      throw new Exc.HttpError( err.response.status, err.toString() );
      //return [ err.response.status, err ];
    });
}


export async function options(url, parameters = {}, checkAuth = true) {
  const result = await axios.options(url, {
        params : parameters,
        withCredentials: true,
      }
    )
    .then( (response) => {
      handleResponse(response, checkAuth);
      if (response.status === 200) {
        return mapToData(response);
      }
      console.log( `Error "${url}": ${response.status}` );
      throw new Exc.HttpError( response.status, "" );
      //return [ response.status, "" ];
    })
    .catch( (err) => {
      console.log( `Error "${url}": ${err.toString()}` );
      throw new Exc.HttpError( err.response.status, err.toString() );
      //return [ err.response.status, err ];
    });

  return result;
}

/*
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
}
*/
