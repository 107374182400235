import React, {useState, useEffect, useRef} from 'react';

// Parts
import Loader from 'components/parts/loader';


const Countdown = ({ fce, second, text_inProgress, text_finished, loader_afterFinished }) => {
    const [timer, setTimer] = useState(second);
    const id = useRef(null);


    const clear = () => {
        window.clearInterval(id.current);
    }

    useEffect( () => {
        id.current = window.setInterval( () => {
            setTimer( (time) => time-1 );
        }, 1000 );
        return () => clear();
    }, [] );

    useEffect( () => {
        if ( timer === 0 ) {
            fce();
            clear();
        }
    }, [timer] );



    if ( timer === 0 ) {
        if ( loader_afterFinished ) {
            return (
                <Loader text={text_finished} />
            );
        } else {
            return (
                <>{text_finished}</>
            );
        }
    }
    else {
        return (
            <Loader text={timer + text_inProgress} />
        );
    }

}

Countdown.defaultProps = {
    text_inProgress: "",
    text_finished: "",
    loader_afterFinished: false
  };

export default Countdown;