import { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

// Helpers
import { isBoolean } from 'helpers/core';

// Images
import img_logo from 'assets/images/logo.png';


const MainMenu = ({ title, links, classes }) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleClickedHamburger = ( show ) => {
    if ( isBoolean(show) ) {
      setShowMenu( show );
    } else {
      setShowMenu( false );
    }
  };


  return (
    <>
      <nav onClick={handleClickedHamburger} style={{ display: showMenu ? 'inherit' : 'none' }} className={`menu menu-main ${classes}`}>
        {
          title &&
          <h1>{title}</h1>
        }

        <ul className="pointers">
          {
            links &&
              links.map( item =>
                <li><NavLink to={item.link}>{item.text}</NavLink></li>
              )
          }
          <li className="important">
            <NavLink to="/produkty">Kategorie</NavLink>
          </li>
          <li className="important">
            <NavLink to="/produkty?typ=univerzalni">Ostatní díly</NavLink>
          </li>
          <li>
            <NavLink to="/">Domů</NavLink>
          </li>
          <li>
            <NavLink to="/pujcovna">Půjčovna nosičů</NavLink>
          </li>
          {/*<li className="menu-with-children">
            <NavLink to="/projekty">Naše projekty</NavLink>
            <ul className="menu-child">
              <li>První projekt</li>
              <li>Druhý projekt</li>
              <li>Další projekt</li>
            </ul>
          </li>*/}
          <li><a href="http://tenis.prondo.cz/" target="_blank">Tenisová liga</a></li>
          <li>
            <NavLink to="/kontakt">Kontakty</NavLink>
          </li>
        </ul>
      </nav>


      <div onClick={ () => handleClickedHamburger(!showMenu) } className="menu-hamburger pointer"><span className={`btn-icon icon ${showMenu ? "is-times" : "is-bars" }`}></span></div>
    </>
  );

}

export default MainMenu;
