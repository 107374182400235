import React from 'react';
import { Link } from 'react-router-dom';

// Helpers
import {isset} from 'helpers/core';


const ProdBanner = ({ title, link, icon }) => {

  return(
    <div className="prod-banner">
      <div className={ icon ? `icon ${icon}` : "" }></div>
      <div className="spacer"></div>
      <div>{title}</div>
      {
        ( isset(link) ) &&
          <Link to={link} className="inverted">Více >>></Link>
      }
    </div>
  );

}

export default ProdBanner;
