import { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Forms
import {setState, Check, Textarea, Input} from 'components/form';

// Parts
import MessagesBox from 'components/parts/messagesbox';
import Loader from 'components/parts/loader';

// Context
import {useGlobal} from 'context/globalContext.js';
import {useAuth} from 'context/authContext.js';
import {useProducts} from 'context/productsContext';

// Data
import * as UserData from 'data/userData';

// Config
import configFile from 'config';


const initData = {
  login: "",
  password: "",
}


const FormLogin = ({ product }) => {
  // Hooks
  const history = useHistory();
  const {messagesAdd, messagesSet, setOverlay} = useGlobal();
  const {auth, userSet, adminSet} = useAuth();
  const {vehicleSet} = useProducts();
  const {vehicle} = useProducts().products;
  const [data, setData] = useState(initData);
  const [loginMessages, setLoginMessages] = useState([]);


  const login = async (e) => {
    e.preventDefault();
    setLoginMessages(undefined);
    userSet(undefined);

    const out = { ...data };
    const input = await loginPost(out.login, out.password);

    if (input === null || input.status === undefined || input.user === undefined) {
      //setLoginMessages([]);
      setLoginMessages([ {type:1, text: "Nepodařilo se spojit se serverem. Zkuste to později znovu nebo nás kontaktujte."} ]);
      return;
    }
    // Some errors -> don't submited
    switch (input.status) {
      case undefined:
      case null:
      case 0:
      case -1:
      case -2:
        break;
      case 6403:
        setLoginMessages([ {type:3, text: "Vyplňte, prosím, všechny potřebné informace."} ]);
        return;
      case 6402:
        setLoginMessages([ {type:1, text: "Požadavek neobsahuje všechny informace, prosím kontaktujte nás."} ]);
        return;
      default:
        setLoginMessages([ {type:1, text: "Chyba aplikace, prosím kontaktujte nás."} ]);
        return;
    }

    if ( processLoginAnswer(input.status, input.user, input.admin) ) {
      if (configFile.config.user.applyVehicleAfterLogin) {  // Set vehicle only if allowed in config
        processVehicleAnswer(input.vehicle);
      }
    }
    //window.scrollTo(0, 0);  // Scroll to top

    history.push("/login");
  }

  const processVehicleAnswer = (loadedVehicle) => {
    // Some errors -> don't submited
    switch (loadedVehicle) {
      case undefined:
      case null:
        return false;
      default:
        break;
    }

    if (vehicle.id === null) {
      vehicleSet(loadedVehicle);
      messagesAdd(null, "POZOR! Do filtru byl nastaven výchozí automobil z Vaší garáže.");
      return true;
    } else {
      return false;
    }
  }

  const processLoginAnswer = (loginStatus, user, admin) => {
    // Some errors -> don't submited
    switch (loginStatus) {
      case undefined:
      case null:
      case 0:
        break;
      case -1:
        setUser(user, null, [ {type:3, text: "Jste již přihlášen/a. Chcete-li změnit uživatele, nejdříve se odhlaste."} ], true);
        return false;
      case -2:
        setUser(null, null, [ {type:2, text: "Chybné jméno nebo heslo, zkuste to znovu."} ]);
        return false;
      default:
        return false;
    }

    // User set
    setUser(user, admin, [ {type:0, text: "Přihlášení úspěšné."} ], true);
    return true;
  }


  const setUser = (user, admin = null, message = undefined, closeOverlay = false) => {
    // Set admin
    if (admin === undefined || admin === null) {
      adminSet(null);
    } else {
      adminSet(admin);
    }
    // Set user
    if (user === undefined || user === null) {
      userSet(null);
    } else {
      userSet(user);
    }
    // Set message
    if (message !== undefined) {
      closeOverlay ? messagesSet(message) : setLoginMessages(message);
    }
    // Close
    if (closeOverlay) {
      setOverlay(null);
    }
  }


  // POST

  const loginPost = async (email, password) => {
    try {
      return await UserData.loginPost(email, password);
    } catch (ex) {
      return null;
    }
  }


  // RENDER >>>


  return (
    <div className="form-login">

      <MessagesBox messages={loginMessages} />

      <form onSubmit={ (e) => (e.preventDefault()) } className="form-1">

        <div className="form-row">
          <div className="form-col">
            <Input labelText="E-mail" name="login" value={data["login"]} onChange={(e) => setState(e,data,setData)} />
          </div>
          <div className="form-col">
            <Input type="password" labelText="Heslo" name="password" value={data["password"]} onChange={(e) => setState(e,data,setData)} />
          </div>
          <div className="form-col">
            <button onClick={login} disabled={(loginMessages === undefined)}>
              {
                (loginMessages === undefined)
                ?
                  <Loader />
                :
                  "Přihlásit se"
              }
            </button>
          </div>
        </div>

      </form>

    </div>
  );
}

export default FormLogin;
