import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Forms
import FormOrder from 'components/forms/order';

// Parts
import MessagesBox from 'components/parts/messagesbox';
import Loader from 'components/parts/loader';

// Helpers
import { empty } from 'helpers/core';
import { filter } from 'helpers/products';

// Entities
import * as AccountE from 'entities/account';
import * as AddressE from 'entities/address';
import * as OrderE from 'entities/order';
import * as UseE from 'entities/use';

// Context
import {useProducts} from 'context/productsContext';
import {useOrder} from 'context/orderContext';
// Data
import * as DataProduct from 'data/productData';
import * as DataCart from 'data/cartData';
import * as DataUser from 'data/userData';

// Config
import configFile from 'config';

/*[
  {
    id: 1,
    name: "Česká pošta",
    priceTax: 95,
  },
],
[
  {
    id: 1,
    name: "Česká pošta",
    priceTax: 95,
const def = {
  freePrice: 2000,
}
  },
]*/


const CartPage = (props) => {
  const {products, productsMerge} = useProducts();
  const {order, sumSet} = useOrder();

  const [dataUse, setDataUse] = useState(undefined);
  const [dataAddressInvoicing, setDataAddressInvoicing] = useState(undefined);
  const [dataAddressShipping, setDataAddressShipping] = useState(undefined);
  const [dataOrder, setDataOrder] = useState( {...OrderE.order} );

  const [dataProducts, setDataProducts] = useState(undefined);
  const [dataShipping, setDataShipping] = useState(undefined);
  const [dataPayments, setDataPayments] = useState(undefined);

  const [syncProducts, setSyncProducts] = useState(false);

  const [transportAllowed, setTransportAllowed] = useState( {
    personal: true,
    transport: true
  } );


  // After mount - load product
  useEffect( () => {
    const fetchData = async () => {
      await productsLoad();
      await addressAndShippingAndPaymentsLoad();
    };
    fetchData();
  }, [] );


  // On products(+ quantity) update
  useEffect( () => {
    filterCartProducts();
  }, [products] );


  // GET

  const productsLoad = async () => {
    try {
      const cart = await DataProduct.getCart();
      productsMerge(cart.products);
      setSyncProducts(true);
      return cart.products;
    } catch(e) {
      return undefined;
    }
  }


  const filterCartProducts = () => {
    const productsInCart = filter(products.all, "cart");

    setDataProducts( productsInCart.products );
    sumSet({ productsNoTax:productsInCart.sum.productsNoTax, productsTax:productsInCart.sum.productsTax, feesNoTax:productsInCart.sum.feesNoTax, feesTax:productsInCart.sum.feesTax });
    setTransportAllowed({ personal:transportAllowed.personal, transport:productsInCart.transport });
  }


  const addressAndShippingAndPaymentsLoad = async () => {
    try {
      await Promise.all( [DataUser.accountGet(null, false), DataCart.getShipping(), DataCart.getPayments()] ).then( (responses) => {
        const [userData, shippingData, paymentsData] = responses;

        if ( empty(userData.data) || empty(userData.data.account) ) {
          setDataUse( {...UseE.useAccount} );
          setDataAddressInvoicing( {...AddressE.address} );
          setDataAddressShipping( {...AddressE.address} );
        } else {
          setDataUse(userData.data.use);
          setDataAddressInvoicing(userData.data.account.address_invoicing);
          setDataAddressShipping(userData.data.account.address_delivery);
        }
        setDataShipping(shippingData.shipping);
        setDataPayments(paymentsData.payments);
      });
    } catch(e) {
      console.log(e);
    }
  }


  // RENDER >>>


  // Loader (page)
  if ( ! syncProducts ) {
    return (
      <Loader type="page" text="Připravujeme Váš košík ..." />
    )
  }

  // Empty
  if ( (! dataProducts) || dataProducts.length === 0 ) {
    return (
      <div className="row-cols nobg">
        <main className="col-middle">
          <h1>Nákupní košík</h1>
          <div className="txt-banner">
            <p>Zatím tu nic nemáte...</p>
            <span><Link to={"/produkty"}>Vyberte si</Link> pro Váš automobil něco hezkého</span>
          </div>
        </main>
      </div>
    )
  }

  // Cart

  const filteredShipping = (dataShipping) ? dataShipping.filter( item =>
    (item.type == 1 && transportAllowed.personal) // Show "Personal pickup" for all cases
    
    || ( (item.type == 2)
      && (transportAllowed.transport)
      && (
        (configFile.config.order.freeShipping === false)  // Show ALL when FREE shipping disabled
        || (order.sum.productsTax < configFile.config.order.freeShipping) ? (! item.is_free) : (item.is_free) // If free shipping, show just fee shipping
      )
    )
  ) : undefined;
  
  const filteredPayments = dataPayments;

  // TODO!!! Add selected shipping and payment
  //const sumTotalTax = sumProductsTax;
  //const sumTotal = sumProductsNoTax;

  return (
    <>

      <div className="row-cols nobg">
        <main className="col-middle">
          <h1>Nákupní košík</h1>
          <FormOrder
            products={dataProducts}
            shipping={filteredShipping}
            payments={filteredPayments}
            dataUse={dataUse}
            setDataUse={setDataUse}
            dataAddressInvoicing = {dataAddressInvoicing}
            setDataAddressInvoicing = {setDataAddressInvoicing}
            dataAddressShipping = {dataAddressShipping}
            setDataAddressShipping = {setDataAddressShipping}
            dataOrder = {dataOrder}
            setDataOrder = {setDataOrder}
          />
        </main>
      </div>

    </>
  )
};


export default CartPage;
