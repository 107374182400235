import React from 'react';


const ContactPage = ({ props }) => {

  return (
    <>

        <h1>Kontakty</h1>

        <h2>Otevírací doba - prodejna Kosmonosy</h2>
        <table>
            <tr>
                <td>Po</td>
                <td>8:00 - 16:00</td>
            </tr>
            <tr>
                <td>Út</td>
                <td>8:00 - 16:00</td>
            </tr>
            <tr>
                <td>St</td>
                <td>8:00 - 16:00</td>
            </tr>
            <tr>
                <td>Čt</td>
                <td>8:00 - 16:00</td>
            </tr>
            <tr>
                <td>Pá</td>
                <td>8:00 - 16:00</td>
            </tr>
        </table>

        <h3>PRODEJNA A VELKOOBCHOD – POPTÁVKY NÁHRADNÍCH DÍLŮ, FAKTURACE</h3>
        <p>Tel: +420 326 728 002, Mobil +420 725 529 609 (whatsapp), e-mail: info@prondo.cz</p>

        <h3>PRONDO s.r.o.</h3>
        <p>IČO: 261 87 761<br/>
        DIČ: CZ26187761<br/>
        Sídlo: Víta Nejedlého 742, 293 06 Kosmonosy<br/>
        Iveta Cerhová - jednatelka, řízení a správa firmy<br/>
        E-mail: prondo@prondo.cz</p>

        <h3>Libor Čejna - Obchodní činnost, Reklamace, IT, nákup a prodej</h3>
        <p>Mobil: +420 725 529 609 (whatsapp), e-mail: cejna@prondo.cz</p>

        <h3>František Cerha - jednatel, obchodní a provozní činnost</h3>
        <p>Tel./fax (provoz skladu autodílů): +420 326 728 002, mobil: +420 724 028 811<br/>
        Tel./fax: +420 326 726 284, e-mail: sprava@prondo.cz, skype: prondo2</p>

        <h3>Autoservis - Vojtěch Novotný</h3>
        <p>Tel: +420 605 468 396, e-mail: autoservis@prondo.cz</p>
    </>
  );

}

export default ContactPage;
