import React from 'react';

// Images
import img_loading_page from 'assets/images/loading1.gif';
import img_loading_small from 'assets/images/loading3.gif';


const Loader = ({ type, text }) => {

  if (type === "block") {
    return <p className="loader loader-block"><img src={img_loading_small} alt="" />{ text ? " " + text : "" }</p>;
  }

  if (type === "page") {
    return (
      <div className="loader loader-page">
        {
          text &&
            <p>{text}</p>
        }
        <img src={img_loading_page} alt="" />
      </div>
    );
  }

  if (type === "app") {
    return (
      <div className="loader loader-app">
        {/*<h1>Prondo</h1>*/}
        <div>
          {
            text &&
              <p>{text}</p>
          }
          <img src={img_loading_page} alt="" />
        </div>
      </div>
    );
  }


  return <span className="loader loader-inline"><img src={img_loading_small} alt="" />{ text ? " " + text : "" }</span>;
}

export default Loader;
