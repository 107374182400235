import Page from 'components/page';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
// Forms
import FormDemand from 'components/forms/demand';
// Parts
import Hero from 'components/parts/hero';
import Aboutus from 'components/parts/aboutus';
import Products from 'components/parts/products';
import Placeholder from 'components/parts/placeholder';
// Context
import {useProducts} from 'context/productsContext';
// Data
import * as DataProduct from 'data/productData';

// Images
import img_car from 'assets/images/car.jpg';


/*products_discount : [
  {
    id: 1,
    manufacturer: "Firma",
    title: "Testovací produkt Testovací abc produkt Testovací produkt",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae ante ultrices, dictum justo at, consequat sapien. Mauris volutpat a dui pellentesque pulvinar. Mauris rhoncus libero nisi, vitae venenatis purus porta nec. Vivamus ac sapien in diam egestas accumsan. Nulla ultrices, quam sodales facilisis consequat, risus ligula consequat lectus, sed vehicula arcu ante vel nulla. Maecenas convallis porttitor tortor non vulputate. Vivamus mollis eros eu nulla elementum tempus. Nulla at orci scelerisque, sagittis enim sed, lobortis enim. Sed ultricies tellus a urna malesuada, ac rhoncus lorem condimentum. Morbi urna magna, finibus a laoreet ac, facilisis et arcu. Donec sodales nisi porttitor lectus viverra efficitur. Duis porta gravida ornare.",
    image: "/assets/images/test-product-small.jpg",
    price: 1234.56,
    tax: 21,
    stock: 100,
    local: true,
    delivery: "1.1.2021",
    flags: [20, 55]
  },
]*/


const IndexPage = (props) => {
  const {products, productsDispatch} = useProducts();

  // After mount - load product
  useEffect( async () => {
    await allLoad(products.vehicle.id);
  }, [] );


  // GET

  const allLoad = async (vehicle) => {
    try {
      await Promise.all([
        DataProduct.getProducts(null, null, {type:"favorite", vehicle}, "favorite"),
        DataProduct.getProducts(null, null, {type:"sale", vehicle}, "sale")
      ]).then( (responses) => {
          const [products_favorite, products_sale] = responses;
          // TODO: Make single call as: this.props.dispatch({ type: 'PRODUCTS_MERGE', payload: { favorite: products_favorite, sale: products_sale } });

          /*this.props.dispatch({ type: 'PRODUCTS_MERGE', payload: products_favorite });
          this.props.dispatch({ type: 'PRODUCTS_MERGE', payload: products_sale });
          this.props.dispatch({ type: 'SYNC_SET', payload: {products_favorite: true, products_sale: true} });*/
          productsDispatch({ type: 'MULTI', payload:
            [
              { type: 'PRODUCTS_MERGE', payload: products_favorite.products },
              { type: 'PRODUCTS_MERGE', payload: products_sale.products },
              { type: 'SYNC_SET', payload: {products_favorite:true, products_sale:true} },
            ]
          });
      });
    } catch(e) {
      return null;
    }
  }


  //let products_favorite = orderByStockAndPrice( products.all.filter(item => "favorite" in item.types ) );
  const products_favorite = products.all.filter(item => "favorite" in item.types );
  //let products_sale = orderByStockAndPrice( products.all.filter(item => "sale" in item.types ) );
  const products_sale = products.all.filter(item => "sale" in item.types );

  return (
    <>

      <div className="row-full nobg noedge">
        <Hero />
      </div>

      <div className="row-full">
        <Aboutus />
      </div>

      <div className="row-center nobg">
        <Products products={ ! products.sync.products_favorite ? undefined : products_favorite } banner_title="Oblíbené" banner_link="/produkty?typ=oblibene" banner_icon="ir-star" />
        <Products products={ ! products.sync.products_sale ? undefined : products_sale } banner_title="Výprodej" banner_link="/produkty?typ=vyprodej" banner_icon="is-percentage" />
      </div>

      <div className="row-cols">
        <aside className="col-left">
        </aside>
        <main className="col-middle">
          <h1>Vaše poptávka</h1>
          <FormDemand />
        </main>
        <aside className="col-right">
        </aside>
      </div>

      <div className="row-full noedge">
        <Placeholder image={img_car} />
      </div>

    </>
  )
};


export default IndexPage;
