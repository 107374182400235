import React from 'react';

import HighlightedManufacturers from 'components/parts/hero/highlightedmanufacturers';
import HighlightedNews from 'components/parts/hero/highlightednews';


class Hero extends React.Component {
  state = {
    manufacturers : [],
    news : [],
  }


  componentDidMount() {
    this.testManufacturers();
  }


  mapManufacturer(url, text, img) {
    const { manufacturers } = { ...this.state };
    manufacturers.push( {
      'key': manufacturers.length,
      'url': url,
      'text': text,
      'img': window.location.origin + img,
    } );
    return manufacturers;
  }


  testManufacturers() {
    this.mapManufacturer( 106, "Škoda", '/assets/images/manufacturers/skoda.png' );
    this.mapManufacturer( 121, "Volkswagen", '/assets/images/manufacturers/volkswagen.png' );
    this.mapManufacturer( 88, "Peugeot", '/assets/images/manufacturers/peugeot.png' );
    this.mapManufacturer( 21, "Citroën", '/assets/images/manufacturers/citroen.png' );
    const manufacturers = this.mapManufacturer( 36, "Ford", '/assets/images/manufacturers/ford.png' );
    this.setState({ manufacturers });
  }


  render () {
    const { manufacturers, news } = this.state;

    return (
      <section className="hero">

        <img src={window.location.origin + '/assets/images/news/news2.jpg'} alt="Novinka 1" className="img-crop" />

        <div>
          <div id="highlightedmanufacturers" className="box-highlighted">
            <HighlightedManufacturers manufacturers={ manufacturers } />
          </div>

          <div className="space"></div>

          <div id="highlightednews" className="box-highlighted">
            <HighlightedNews news={ news } />
          </div>
        </div>

      </section>
    );
  }

}

export default Hero;
