import config from 'config.json';
import * as Data from './data';
import * as Http from './httpData';
import * as Core from 'helpers/core';
/*import * as Helpers from 'utils/helpers';
import * as Dt from 'utils/dt';*/

import {listProducts} from './productData';

const accountUrl = config.apiUrl + '/account';


// ORDERS >>>

const ordersUrl = config.apiUrl + '/order';

function mapToOrder(item) {
  return item;
}
function mapFromOrder(item) {
  return item;
}
function listOrders(data) {
  let mapped = [];
  for ( const [key, value] of Object.entries(data) ) {
    let item = mapToOrder(value);
    item.key = key;
    mapped.push( item );
  }
  return mapped;
}


export async function ordersGet(params) {
  const response = await Http.get( ordersUrl + config.apiFormat + Data.params(params) );
  response.data.orders = ( (response.data.orders === undefined) || (response.data.orders === null) )
    ? null
    : listOrders(response.data.orders);
  return response;
}

export async function order(orderId, params) {
  const response = await Http.get( ordersUrl + "/" + orderId + config.apiFormat + Data.params(params) );
  response.data.products = ( response.data.products === undefined )
    ? null
    : listProducts( response.data.products, "order" );
  return response;
}


// GARAGE >>>

const garageUrl = accountUrl + '/garage';

function mapToGarage(item) {
  return item;
}
function mapFromGarage(item) {
  return item;
}
function listGarage(data) {
  let mapped = [];
  for ( const [key, value] of Object.entries(data) ) {
    let item = mapToGarage(value);
    item.key = key;
    mapped.push( item );
  }
  return mapped;
}


export async function garageGet(params) {
  const response = await Http.get( garageUrl + config.apiFormat + Data.params(params) );
  response.data.vehicles = ( (response.data.vehicles === undefined) || (response.data.vehicles === null) )
    ? null
    : listGarage(response.data.vehicles);
  return response.data;
}

export async function garageInsert(vehicle, params) {
  const response = await Http.post( garageUrl + config.apiFormat + Data.params(params), {vehicle} );
  response.status = (response.status === undefined) ? null : response.status;
  return response.status;
}

export async function garageSetDefault(id, params) {
  const response = await Http.patch( garageUrl + config.apiFormat + Data.params(params), null, id );
  response.data.status = response.status;
  response.data.changed = ( (response.data.changed === undefined) || (response.data.changed === null) )
    ? null
    : response.data.changed;
  return response.data;
}

export async function garageDel(id, params) {
  const response = await Http.del( garageUrl + config.apiFormat + Data.params(params), id );
  if ( (response.data.default === undefined) || (response.data.default === null) ) {
    response.data.default = null;
  }
  return response;
}
