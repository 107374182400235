import React from 'react';

// Forms
import {setState, Radio} from 'components/form';

// Functions
import { isObject } from 'helpers/core';
import * as money from 'helpers/money';

// Context
import {useOrder} from 'context/orderContext.js';


const PartialOrderPayment = ({ all, name, idKey, valueKey, priceKey, taxKey, data, setData, selectedShipping, typeKey, shortcutKey }) => {
  // Hooks
  const {order, sumSet} = useOrder();


  const handlePriceChange = (e, item) => {
    sumSet({ paymentsTax: money.priceToTax(item[priceKey],item[taxKey]), paymentsNoTax: item[priceKey] });
    setState(e,data,setData);
  }

  // RENDER >>>


  if ( ! data ) {
    return (
      <div className={`form-partial form-partial-order-select form-partial-order-select-${ name }`}>
      </div>
    );
  }


  if ( ! isObject(selectedShipping) ) {
    return (
      <div className={`form-partial form-partial-order-select form-partial-order-select-${ name }`}>
        <small>Nejdříve vyberte dopravu</small>
      </div>
    );
  }


  return (
    <div className={`form-partial form-partial-order-select form-partial-order-select-${ name }`}>

      {
        all.filter( item =>
          ( selectedShipping[typeKey] === 1 && (item[shortcutKey] != "ondelivery") )
          || ( selectedShipping[typeKey] === 2 && (item[shortcutKey] != "cash") )
        ).map( item =>
          <div key={item[idKey]}>
            <Radio checked={item[idKey] == data[name]} name={name} labelText={item[valueKey]} value={item[idKey]} onChange={ (e) => handlePriceChange(e,item) } />
            <div className="price">
              <span>{/*<small>{ money.formatCzk(item[priceKey]) }&nbsp;Kč bez DPH</small> */}{ money.formatCzk( money.priceToTax(item[priceKey],item[taxKey]) ) }&nbsp;Kč <small>s DPH</small></span>
            </div>
          </div>
        )
      }

    </div>
  );
}

export default PartialOrderPayment;
