import React from 'react';

// Parts
import Loader from 'components/parts/loader';

// Helpers
import { isset, empty, toInt } from 'helpers/core';


export const optionsCountries = [
  {
    id: "CZ",
    value: "Česká republika"
  },
  {
    id: "SK",
    value: "Slovensko"
  }
];


export function mapToSelect(data, valueName, idName="id") {
  let out = [];

  if ( ! isset(data) ) {
    return out;
  }

  data.forEach( function (item, index) {
    const option = mapToOption(item[idName], item[valueName]);
    if ( option !== null ) {
      out.push(option);
    }
  });

  return out;
}

export function mapToOption(id, value) {
  if ( (! isset(id)) || (! isset(value)) ) {
    return null;
  }
  return {
    id : id,
    value : value,
  };
}


export function name({currentTarget : e}) {
  return e.name;
}

export function fromBool(value) {
  if (value === undefined) { return undefined; }
  if (
    value
    || value == 1
    || value == '1'
  ) {
    return true;
  }
  return null;
}

export function toBool(value) {
  if (
    empty(value)
    || value == false
    || value == 0
    || value == '0'
  ) {
    return false;
  }
  return true;
}

export function setCheck({currentTarget : e}, setFunction, valueToSet) {
  const out = (e.value == valueToSet) ? null : valueToSet;
  if ( isset(setFunction) ) {
    setFunction( out );
  }
  return out;
}

export function setInt({currentTarget : e}) {
  return toInt(e.value);
}

export function setState({currentTarget : input}, state, setState, value) {
  const data = { ...state };

  if (value === undefined) {
    data[input.name] = input.value;
  } else {
    // For checkbox
    data[input.name] = (data[input.name] === null) ? value : null;
  }

  setState(data);
}


export const Msg = ({ text, ref, ...rest }) => {
  return (
    <div ref={ref} className={`${text.length === 0 ? "hide " : ""}alert alert-danger`} {...rest}>{text}</div>
  )
}


export const Label = ({ name, text, ref, ...rest }) => {
  return (
    <div className="form-data form-label">
      <label ref={ref} htmlFor={name} {...rest}>{text}</label>
    </div>
  )
}


export const Input = ({
  type, name, value, disabled, ref,
  labelText, labelRef,
  msgText, msgRef,
  ...rest
}) => {
  if ( ! isset(type) ) { type = "text"; }
  if ( ! isset(value) ) { value = ""; }

  return (
    <>
      <div className="form-data form-input">
        {
          labelText &&
            <Label ref={labelRef} name={name} text={labelText} />
        }
        <input type={type} className="form-control" ref={ref} name={name} value={value} disabled={disabled ? "disabled" : ""} {...rest} />
      </div>
      {
        msgText &&
          <Msg ref={msgRef} text={msgText} />
      }
    </>
  )
}



const RadioAndCheck = ({
  checked, type, name, value, disabled, ref,
  labelText, labelRef,
  msgText, msgRef,
  ...rest
}) => {
  if (value === undefined) {
    return <Loader text={labelText} />
  }

  if ( ! isset(value) ) { value = ""; }

  return (
    <>
      <div className={`form-data ${ (type=="radio") ? "form-radio" : (type=="checkbox") ? "form-check" : "form-input"}`}>
        {
          labelText &&
            <label ref={labelRef} htmlFor={name}>{labelText}</label>
        }
        <input type={type} checked={ /*((type=="radio")&&()) || ((type=="checkbox")&&(value !== ""))*/ checked || ((type=="checkbox")&&(value !== "")) } className="form-control" ref={ref} name={name} value={value} disabled={disabled ? "disabled" : ""} {...rest} />
      </div>
      {
        msgText &&
          <Msg ref={msgRef} text={msgText} />
      }
    </>
  )
}


export const Radio = (props) => {
  if ( ! isset(props.type) ) {
    props = {...props};
    props.type = "radio";
  }
  return RadioAndCheck(props);
}


export const Check = (props) => {
  if ( ! isset(props.type) ) {
    props = {...props};
    props.type = "checkbox";
  }
  return RadioAndCheck(props);
}


export const Textarea = ({
  rows, name, value, disabled, ref,
  labelText, labelRef,
  msgText, msgRef,
  ...rest
}) => {
  if ( ! isset(rows) ) { rows = 10; }
  if ( ! isset(value) ) { value = ""; }

  return (
    <>
      <div className="form-data form-textarea">
        {
          labelText &&
            <Label ref={labelRef} name={name} text={labelText} />
        }
        <textarea rows={rows} className="form-control" ref={ref} name={name} value={value} disabled={disabled ? "disabled" : ""} {...rest}></textarea>
      </div>
      {
        msgText &&
          <Msg ref={msgRef} text={msgText} />
      }
    </>
  )
}


export const Select = ({
  empty, options, name, value, disabled, ref,
  labelText, labelRef,
  msgText, msgRef,
  ...rest
}) => {
  if ( ! isset(empty) ) { empty = true; }
  if ( ! isset(options) ) { options = []; }
  if ( ! isset(value) ) { value = ""; }

  return (
    <>
      <div className="form-data form-select">
        {
          labelText &&
            <Label ref={labelRef} name={name} text={labelText} />
        }
        <select className="form-control" ref={ref} name={name} disabled={disabled ? "disabled" : ""} {...rest}>
          {
            empty &&
              <option value="" />
          }
          {
            options.map( item => {
              return <option selected={(item.id == value)} key={item.id} value={item.id}>{item.value}</option>
            })
          }
        </select>
      </div>
      {
        msgText &&
          <Msg ref={msgRef} text={msgText} />
      }
    </>
  )
}


// export const Date = ({ reference, name, label, disabled, error, ...rest }) => {
//   rest.value = rest.value ? rest.value : "";
//
//   return (
//     <div className="form-group">
//       <label htmlFor={name}>{label}</label>
//       <Datetime
//         {...rest}
//         ref={reference}
//         name={name}
//         disabled={disabled ? "disabled" : ""}
//         value={rest.value}
//         format="dd.MM.yyyy"
//         //selected={startDate}
//         //onChange={date => setStartDate(date)}
//       />
//       { error && <div className="alert alert-danger">{error}</div> }
//     </div>
//   )
// }
//
//
// export const DateTime = ({ reference, name, label, disabled, error, ...rest }) => {
//   rest.value = rest.value ? rest.value : "";
//
//   return (
//     <div className="form-group">
//       <label htmlFor={name}>{label}</label>
//       <Datetime
//         {...rest}
//         ref={reference}
//         name={name}
//         disabled={disabled ? "disabled" : ""}
//         value={rest.value}
//         format="dd.MM.yyyy"
//         //selected={startDate}
//         //onChange={date => setStartDate(date)}
//       />
//       { error && <div className="alert alert-danger">{error}</div> }
//     </div>
//   )
// }


export default class Form extends React.Component {
  // IMPLEMENT THIS IN EACH CHILD!!!
  state = {
    internal : {},
    errors : {},
    data : {},
  };

  references = {};


  // // Validations
  //
  // validate = () => {
  //   /*const { error } = Joi.validate(this.state.data, this.schema, { abortEarly : false});
  //   if (! error) return null;
  //
  //   const errors = {};
  //   for (let item of error.details) errors[item.path[0]] = item.message;
  //   return errors;*/
  //
  //   let errors = {};
  //  // Dont use: https://stackoverflow.com/questions/3010840/loop-through-an-array-in-javascript
  //   for (const key in this.state.data) {
  //     // Skip disabled components
  //     if ( isset(this.references[key]) && isset(this.references[key].current) && isset(this.references[key].current.disabled) && (this.references[key].current.disabled) ) {
  //       continue;
  //     }
  //
  //     const error = this.validateProperty(key, this.state.data[key]);
  //     if (error !== null) {
  //       errors[key] = error
  //     }
  //   }
  //
  //   return errors;
  // };
  //
  //
  // validateProperty = ( name, value ) => {
  //   const obj = { [name] : value };
  //   const schema = { [name] : this.schema[name] };
  //   const { error } = Joi.validate(obj, schema);
  //   return error ? error.details[0].message : null;
  // };
  //
  //
  // // Joi custom validation
  // validateMoment = (value, helpers) => {
  //   // Throw an error (will be replaced with 'any.custom' error)
  //   if ( ! DT.isValidMoment(value) ) {
  //       throw new Error('nope');
  //   }
  //   // Return the value unchanged
  //   return value;
  // };


  // For submit

  handleSubmit = e => {
    e.preventDefault();

    // const errors = this.validate();
    // //this.setState({ errors: errors || {} });
    // if ( errors.constructor === Object && Object.keys(errors).length !== 0 ) {
    //   this.setState({ errors });
    //   return;
    // }
    // this.setState({ errors : {} });

    this.doSubmit();
  };


  // For inputs

  handleValidation = (name, value) => {
    const errors = { ...this.state.errors };
    // const errorMessage = this.validateProperty(name, value);
    //
    // if (errorMessage) errors[name] = errorMessage;
    // else delete errors[name];

    return errors;
  }

  handleChangeInput = ({ currentTarget : input }) => {
    const errors = this.handleValidation(input.name, input.value);

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  // handleChangeDateTime = (name, mmnt, defaultMmntTime = null) => {
  //   const errors = this.handleValidation(name, mmnt);
  //
  //   // Set default time
  //   const currentDt = this.state.data[name];
  //   if ( (! DT.isValidMoment(currentDt)) && DT.isValidMoment(mmnt) && DT.isValidMoment(defaultMmntTime) ) {
  //     mmnt.hour( defaultMmntTime.hour() );
  //     mmnt.minute( defaultMmntTime.minute() );
  //   }
  //
  //   const data = { ...this.state.data };
  //   data[name] = mmnt;
  //
  //   this.setState({ data, errors });
  // };

  handleChangeCheckbox = ({ currentTarget : input }) => {
    const errors = this.handleValidation(input.name, input.checked);

    const data = { ...this.state.data };
    data[input.name] = input.checked;
    /*this.setState({
      data : {
        [input.name] : !this.state.data[input.name]
      }
    });*/
    this.setState({ data, errors });
  }

  handleInternalChangeCheckbox = ({ currentTarget : input }) => {
    const internal = { ...this.state.internal };
    internal[input.name] = input.checked;
    this.setState({ internal });
  }


  // Components

  renderInput(name, label, type="text", disabled=false) {
    const { data, errors } = this.state;

    this.references[name] = React.createRef();

    return(
      <Input
        type={type}
        value={data[name]}
        onChange={this.handleChangeInput}

        reference={this.references[name]}
        name={name}
        label={label}
        disabled={disabled}
        error={errors[name]}
      />
    )
  };


  // renderDate(name, label, disabled=false) {
  //   const { data, errors } = this.state;
  //
  //   this.references[name] = React.createRef();
  //
  //   /*return(
  //     <Date
  //       value={data[name]}
  //       onChange={this.handleChangeDateTime}
  //
  //       reference={this.references[name]}
  //       name={name}
  //       label={label}
  //       disabled={disabled}
  //       error={errors[name]}
  //     />
  //   )*/
  //   return(
  //     <div className="form-group">
  //       <label htmlFor={name}>{label}</label>
  //       <Datetime
  //         ref={name}
  //         name={name}
  //         //disabled={disabled ? "disabled" : ""}
  //         value={ DT.toDT(data[name]) }
  //         format="dd.MM.yyyy"
  //         onChange={date => this.handleChangeDateTime(name, DT.fromDT(date))}
  //       />
  //       { errors[name] && <div className="alert alert-danger">{errors[name]}</div> }
  //     </div>
  //   )
  // };
  //
  //
  // renderDateTime(name, label, disabled=false, defaultTime=null) {
  //   const { data, errors } = this.state;
  //
  //   this.references[name] = React.createRef();
  //
  //   /*return(
  //     <Date
  //       value={data[name]}
  //       onChange={this.handleChangeDateTime}
  //
  //       reference={this.references[name]}
  //       name={name}
  //       label={label}
  //       disabled={disabled}
  //       error={errors[name]}
  //     />
  //   )*/
  //   return(
  //     <div className="form-group">
  //       <label htmlFor={name}>{label}</label>
  //       <Datetime
  //         ref={name}
  //         name={name}
  //         //disabled={disabled ? "disabled" : ""}
  //         value={ DT.isValidMoment(data[name]) ? data[name] : null }
  //         dateFormat="DD.MM.YYYY"
  //         timeFormat="HH:mm"
  //         utc={true}
  //         onChange={date => this.handleChangeDateTime(name, date, defaultTime)}
  //         //onCalendarClose={ () => this.handleDefaultTime(defaultTime) }
  //         closeOnSelect={true}
  //
  //       />
  //       { errors[name] && <div className="alert alert-danger">{errors[name]}</div> }
  //     </div>
  //   )
  // };


  renderTextarea(name, label, rows=10, disabled=false) {
    const { data, errors } = this.state;

    this.references[name] = React.createRef();

    return(
      <Textarea
        rows={rows}
        value={data[name]}
        onChange={this.handleChangeInput}

        reference={this.references[name]}
        name={name}
        label={label}
        disabled={disabled}
        error={errors[name]}
      />
    )
  };


  renderCheckbox(name, label, disabled=false) {
    const { data, errors } = this.state;

    this.references[name] = React.createRef();

    return(
      <Input
        type="checkbox"
        checked={data[name]}
        value={name}
        onChange={this.handleChangeCheckbox}

        reference={this.references[name]}
        name={name}
        label={label}
        disabled={disabled}
        error={errors[name]}
      />
    )
  };


  renderSelect(name, label, options, disabled=false) {
    const { data, errors } = this.state;

    this.references[name] = React.createRef();

    return(
      <Select
        value={data[name]}
        options={options}
        onChange={this.handleChangeInput}

        reference={this.references[name]}
        name={name}
        label={label}
        disabled={disabled}
        error={errors[name]}
      />
    )
  }


  renderButton(label) {
    return(
      <button disabled={this.validate()}>{label}</button>
    )
  };


  renderInternalCheckbox(key, label) {
    const { internal } = this.state;

    this.references[key] = React.createRef();

    return(
      <Input
        type="checkbox"
        checked={internal[key]}
        value={true}
        onChange={this.handleInternalChangeCheckbox}

        reference={this.references[key]}
        name={key}
        label={label}
      />
    )
  }

}
