
const ScrollerItemText = ({ idx, option, isActive, onClick }) => {

    // RENDER >>>

    if (! option) {
        return null;
    }

    return (
        <li key={idx} onClick={onClick} className={ (isActive || option.active) ? "active" : "" }>
            {
                (option.text) &&
                    <><span>&#187;</span> {option.text}</>
            }
        </li>
    );
};

export default ScrollerItemText;