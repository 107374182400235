import React from 'react';


const Selectors = ({ props }) => {

  return(
    <div className="selectors">
      {/*
      <ul>
        <li>
          <Link to="">CZK</Link>
        </li>
        <li>
          <Link to="">LANG</Link>
        </li>
      </ul>
      */}
    </div>
  );

}

export default Selectors;
