import * as React from "react";

const AuthContext = React.createContext();


const init = {
  sync: false,
  user: undefined,  // = { id: ..., username: ... }
  admin: undefined,
};


/*
* Each dispatcher must return object {} with new data to enter into new state
*/
const dispatcher = (action, oldData, newData) => {
  switch (action) {

    case 'USER_SET':
      oldData.user = newData;
      break;


    case 'ADMIN_SET':
      oldData.admin = newData;
      break;


    default:
      throw new Error(`AUTH context: Unsupported action type: ${action.type}`);
  }
}


function authReducer(state, action) {
  let newState = { ...state };

  if ( action.type == 'MULTI' ) {
    action.payload.forEach( dispatch => {
      dispatcher( dispatch.type, newState, dispatch.payload );
    })
  } else {
    dispatcher( action.type, newState, action.payload );
  }

  return newState;
}


// Own hook for reading/writing values/states
function useAuth() {
  const context = React.useContext(AuthContext);
  if (! context) {
    throw new Error("AUTH context: useAuth() must be used within a AuthProvider");
  }
  const [state, dispatch] = context;

  // OWN OPTIONAL DISPATCH methods here:
  const userSet = (user) => dispatch({ type: 'USER_SET', payload: user });
  const adminSet = (admin) => dispatch({ type: 'ADMIN_SET', payload: admin });


  return {
    auth : state,
    authDispatch : dispatch,
    userSet,
    adminSet,
  }
}


// Providing context in app tree
function AuthProvider(props) {
  //const [auth, setAuth] = React.useState(init); // = old, without reducer
  const [state, dispatch] = React.useReducer(authReducer, init);
  const value = React.useMemo(() => [state, dispatch], [state]);
  return <AuthContext.Provider value={value} {...props} />
}


export {AuthProvider, useAuth};
