import { useState, useEffect } from 'react';
import { useLocation, Switch, Route, /*Redirect*/ } from 'react-router-dom';

// Styles
import './App.css';

// Parts
import Logo from 'components/parts/header/logo';
import Controls from 'components/parts/header/controls';
import Selectors from 'components/parts/header/selectors';
import MainMenu from 'components/parts/menu';
import MenuSub from 'components/parts/menusub';
import Footer from 'components/parts/footer';
import AdminBar from 'components/parts/adminbar';
import Bar from 'components/parts/bar';
import Breadcrumb from 'components/parts/breadcrumb';
import Loader from 'components/parts/loader';

// Pages
import IndexPage from 'components/page/indexPage';
import ProductsPage from 'components/page/productsPage';
import ProductPage from 'components/page/productPage';
import CartPage from 'components/page/cartPage';
import OrderedPage from 'components/page/orderedPage';
import LoginPage from 'components/page/loginPage';
import AccountPage from 'components/page/accountPage';
import OrdersPage from 'components/page/ordersPage';
import GaragePage from 'components/page/garagePage';
import SimplePage from 'components/page/simplePage';
import StatusPage from 'components/page/statusPage';

// Overlay (dialog, popup, modal, messages)
import MessagesOverlay from 'components/overlay/messagesOverlay';
import VehicleOverlay from 'components/overlay/vehicleOverlay';
import SearchOverlay from 'components/overlay/searchOverlay';
import UserOverlay from 'components/overlay/userOverlay';
import DemandOverlay from 'components/overlay/demandOverlay';
import InfoOverlay from 'components/overlay/infoOverlay';  // For product

// Helpers
import { isset, empty } from 'helpers/core';
import {pageCurrent, buildNavigation} from 'helpers/url';
import {responsive, isResponsive} from 'helpers/css';

// Context
import {useGlobal} from 'context/globalContext.js';
import {useAuth} from 'context/authContext.js';
import {OrderProvider} from 'context/orderContext.js';

// Config
import configFile from 'config';


/*
function addLink(url, text, active = false) {
  const { navigation } = { ...this.state };
  navigation.push( {
    'key': navigation.length,
    'url': url,
    'text': text,
    'active': active,
  } );
  return navigation;
}


function addLinkAndRender(url, text, active = false) {
  const navigation = this.addLink(url, text, active)
  this.setState({ navigation });
}
*/


function App() {
  const pages = configFile.config.pages;

  // Hooks
  const location = useLocation();
  const {global, setOverlay, navigationSet} = useGlobal();
  const {auth} = useAuth();
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(null); // TODO: Move to global context (beware, causing infinite loop!!!)


  // After mount - load product
  useEffect( () => {

    // Navigation
    const currentPage = pageCurrent();
    setPage( currentPage );

    if (! empty(currentPage)) {
      // Ignore some pages
      switch ( currentPage.key ) {
        case "products":
          break;

        default:
          // Set navigation
          const navigation = buildNavigation(currentPage/*, true*/);
          if (navigation !== undefined) {
            navigationSet( navigation );
          }
          break;
      }
    }

    // Scroll to top
    window.scrollTo(0, 0);

  }, [/*location.pathname*/ window.location.href ] );


  const handleCloseOverlay = () => {
    setOverlay(null);
  };

  // Render logic >>>
  const showBar = isset(configFile.config.page.bar);
  const isAdmin = ( isset(auth.user) && auth.user.roles.includes("ROLE_ADMIN") );

  // Render overlay >>>
  let overlayPosition = 140 + (showBar ? 50 : 0) + (isAdmin ? 50 : 0);
  if ( isResponsive(responsive.m, true) ) {
    overlayPosition += 20;
  }

  // RENDER >>>

  return (
    <>
      <div id={`${ ( empty(page) ) ? "" : "page-" + page.key }`} className={ loading ? "page-loading root" : "root" } >
        <AdminBar />

        <Bar />

        <header>
          <div id="menu-main">
            <div>
              <Logo />
            </div>
            <div>
              <MainMenu classes="inverted" />
              <Controls />
            </div>
            <div>
              <Selectors />
            </div>
          </div>

          <div id="menu-sub">
            <MenuSub />
          </div>

          <Breadcrumb />
        </header>

        <div id="page">
          <Switch>
            <Route exact path={ pages["aboutus"].urn } render={() => <SimplePage page="aboutus" />} />
            <Route exact path={ pages["career"].urn } render={() => <SimplePage page="career" />} />
            <Route exact path={ pages["carservice"].urn } render={() => <SimplePage page="carservice" />} />
            <Route exact path={ pages["carrierrent"].urn } render={() => <SimplePage page="carrierrent" />} />
            <Route exact path={ pages["complaint"].urn } render={() => <SimplePage page="complaint" />} />
            <Route exact path={ pages["contact"].urn } render={() => <SimplePage page="contact" />} />
            <Route exact path={ pages["documents"].urn } render={() => <SimplePage page="documents" />} />
            <Route exact path={ pages["goods"].urn } render={() => <SimplePage page="goods" />} />
            <Route exact path={ pages["projects"].urn } render={() => <SimplePage page="projects" />} />
            <Route exact path={ pages["retail"].urn } render={() => <SimplePage page="retail" />} />
            <Route exact path={ pages["return"].urn } render={() => <SimplePage page="return" />} />
            <Route exact path={ pages["shipping"].urn } render={() => <SimplePage page="shipping" />} />
            <Route exact path={ pages["terms"].urn } render={() => <SimplePage page="terms" />} />
            <Route exact path={ pages["wholesale"].urn } render={() => <SimplePage page="wholesale" />} />
            <Route exact path={ pages["eu"].urn } render={() => <SimplePage page="eu" />} />

            <Route exact path={ pages["products"].urn } component={ProductsPage} page="products" />;
            <Route exact path={ pages["product"].urn } component={ProductPage} />;
            <Route exact path={ pages["ordered"].urn } render={ (props) => { return (
                <OrderProvider>
                  <OrderedPage {...props} />
                </OrderProvider>
              ); }}
            />;
            <Route exact path={ pages["cart"].urn } render={ (props) => { return (
                <OrderProvider>
                  <CartPage {...props} />
                </OrderProvider>
              ); }}
            />;
            <Route exact path={ pages["login"].urn } component={LoginPage} />
            <Route exact path={ pages["account"].urn } component={AccountPage} />
            <Route exact path={ pages["orders"].urn } component={OrdersPage} />
            <Route exact path={ pages["garage"].urn } component={GaragePage} />;

            <Route exact path={ pages["home"].urn } component={IndexPage} />;

            <Route render={() => <StatusPage page="404" />} />
          </Switch>
        </div>

        <footer>
          <Footer page={page === null ? null : page.key} />
        </footer>

        <InfoOverlay />

        <div id="overlay" className={ (global.overlay === null) ? "" : (global.overlay === "vehicle" || global.overlay === "search") ? "dialog" : "popup" }>
          <div id="overlay-background" onClick={handleCloseOverlay}>
          </div>
          <div id="overlay-content" style={{top:overlayPosition}}>
            <VehicleOverlay />
            <SearchOverlay />
            <UserOverlay />
            <DemandOverlay />
          </div>
        </div>

        <MessagesOverlay />

      </div>

      {
        loading &&
          <Loader type="app" text="Načítá se ..." />
      }
    </>
  );
}


/*class AppClass {
  state = {
    popup: null,

    page: this.getCurrentPage(),
    navigation : [],
  }




  componentDidMount() {
    this.addLink( "/", "Domů" );
    this.addLink( "produkty", "Produkty" );
    this.addLinkAndRender( "eshop", "E-shop", true );
  }

  componentDidUpdate(prevProps, prevState) {
    if ( this.props.location.pathname !== prevProps.location.pathname ) {
      this.setState({ page: this.getCurrentPage() });
    }
  }


//<Route exact path="/kontakt" render={(props) => <SimplePage {...props} title={`Props through render`} />} />
  render () {
    const { popup, page, navigation } = this.state;

    return null;
  }
}*/

export default App;
