import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Parts
import Loader from 'components/parts/loader';

// Helpers
import * as money from 'helpers/money';

// Context
import {useProducts} from 'context/productsContext';
// Data
import * as DataProduct from 'data/productData';


const ControlCart = () => {
  // Hooks
  const {products, productsMerge} = useProducts();
  const [syncProducts, setSyncProducts] = useState(false);

  // After mount
  useEffect(() => {
    cartLoad();
  }, []);

  // GET
  const cartLoad = async () => {
    try {
      const cart = await DataProduct.getCart();
      productsMerge(cart.products);
      setSyncProducts(true);
      return cart.products;
    } catch(e) {
      return null;
    }
  };


  if (! syncProducts) {
    return <Loader />;
  }


  let price = 0.0;
  let fees = 0.0;
  let quantity = 0;
  products.all.forEach( function(product, index) {
    if (product.cart.in !== 0) {
      quantity += product.cart.in;
      price += (product.price.price * product.cart.in);
      // Fees
      if (product.price.fee) {
        for ( let key in product.price.fee ) {
          fees += (product.price.fee[key] * product.cart.in);
        }
      }
    }
  });

  const hasItems = ( price && price > 0 && quantity && quantity > 0);

  return(
    <Link to="/kosik" className={`control control-cart inverted${ (hasItems) ? "" : " empty" }`}>
      {
        (hasItems)
        ?
          <div>{ money.formatCzk(price+fees) } Kč</div>
        :
          <div>prázdný</div>
      }
      <div className="badge">{ (hasItems) ? quantity : 0 }</div>
      <div className="icon is-shopping-cart"></div>
    </Link>
  );

}

export default ControlCart;
