import React from 'react';
import { useHistory, Link } from 'react-router-dom';

// Helpers
import * as Url from 'helpers/url';


const Category = ({ type, id, name, backLink }) => {
  const history = useHistory();

  const handleGoTo = (url) => {
    history.push( url );
  }

  const handleGoToProduct = () => {
    history.push( Url.categoryLink(id, name, type) );
  }


  // Go back
  if (backLink) {
    return (
      <div onClick={ () => handleGoTo(backLink) } className="category back pointer">
        <div>&lt; </div>
        <Link to={backLink} className="inverted">{name}</Link>
      </div>
    );
  }

  return (
    <div onClick={ () => handleGoToProduct() } className="category">
      <Link to={ Url.categoryLink(id, name, type) } className="inverted">{name}</Link>
      <div> &gt;</div>
    </div>
  );

}

export default Category;
