import config from 'config.json';
import * as Data from './data';
import * as Http from './httpData';
/*import * as Helpers from 'utils/helpers';
import * as Dt from 'utils/dt';*/


// ORDER >>>

const orderUrl = config.apiUrl + '/order';


export async function paymentUrl(orderId, get = {}) {
  const response = await Http.get( orderUrl + `/${orderId}` + `/gateway/url` + config.apiFormat + Data.params(get) );
  response.data.gateway = ( (response.data.gateway === undefined) || (response.data.gateway === null) )
    ? null
    : mapToGateway(response.data.gateway);
  return response;
}


// GATEWAY >>>

const gatewayUrl = config.apiUrl + '/gateway';


function mapToGateway(item) {
  return item;
}
function mapFromGateway(item) {
  return item;
}
function listGatewais(data) {
  let mapped = [];
  data.forEach( function (item, index) {
    mapped.push( mapToGateway(item) );
  });
  return mapped;
}


export async function gatewayStatus(paymentId, get = {}) {
  const response = await Http.get( gatewayUrl + `/${paymentId}` + `/status` + config.apiFormat + Data.params(get) );
  response.data.gateway = ( (response.data.gateway === undefined) || (response.data.gateway === null) )
    ? null
    : mapToGateway(response.data.gateway);
  return response;
}
