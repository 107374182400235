import React from 'react';


const ReturnPage = ({ props }) => {

  return (
    <>
      <h1>Vrácení zboží</h1>

      <p>
        Zde je pro Vás několik užitečných rad, jak předejít nutnosti vracení zboží a když už k tomu dojde, jak zajistit, aby vše proběhlo bez problémů.
      </p>

      <ul>
        <li>
          Kupující má zákonné právo na vrácení zboží zakoupené na eshopu bez udání důvodu do 14 dnů od doručení.
        </li>
        <li>
          <b>Pozorně objednávejte - důkladně si ověřte, že objednáváte správný druh zboží,</b> pokud si nejste jisti, neváhejte se na nás obrátit s případnými dotazy či specifikacemi.
        </li>
        <li>
          Na zboží objednávané na zakázku se nevztahuje možnost vrácení, pokud si takové zboží objednáte, vždy vás na to upozorníme.
        </li>
        <li>
          Zboží musí být v originálním obalu a nesmí jevit známky opotřebení, díl nesmí být namontován.
        </li>
        <li>
          Při odesílání zásilky dejte pozor, aby vnější obal zásilky nebyl zároveň prodejním obalem zboží, aby nedošlo k znehodnocení prodejního obalu.
        </li>
        <li>
          Při vracení zboží musí být přiložen nákupní doklad a vyplněný formulář odstoupení od smlouvy.
        </li>
      </ul>

      <h2>Ke stažení</h2>
      <p><a href="download/documents/odstoupeni_od_smlouvy.pdf" target="_blank">Odstoupení od smlouvy</a></p>
    </>
  );

}

export default ReturnPage;
