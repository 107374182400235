import config from 'config.json';
import * as Data from './data';
import * as Http from './httpData';
/*import * as Helpers from 'utils/helpers';
import * as Dt from 'utils/dt';*/


// VEHICLE >>>

const urlVehicle = config.apiUrl + '/vehicle';

function mapToManufacturer(items) {
  return item;
}

function mapToModel(data) {
  return item;
}

function mapToModification(data) {
  return item;
}


export async function getManufacturers(params) {
  const response = await Http.get( urlVehicle + "/manufacturer" + config.apiFormat + Data.params(params) );
  response.data.manufacturers = ( response.data.manufacturers === undefined )
    ? []
    //: listManufacturers( response.data.manufacturers );
    : response.data.manufacturers;
  return response.data;
}

export async function getModels(manufacturerId, params) {
  const response = await Http.get( urlVehicle + "/"+manufacturerId+"/model" + config.apiFormat + Data.params(params) );
  response.data.models = ( response.data.models === undefined )
    ? []
    //: listModels( response.data.models );
    : response.data.models;
  return response.data;
}

export async function getModifications(modelId, params) {
  const response = await Http.get( urlVehicle + "/"+modelId+"/modification" + config.apiFormat + Data.params(params) );
  response.data.modifications = ( response.data.modifications === undefined )
    ? []
    //: listModifications( response.data.modifications );
    : response.data.modifications;
  return response.data;
}
