import React from 'react';


const ShippingPage = ({ props }) => {

  return (
    <>

      <h1>Doprava</h1>

      <table>
          <caption>Přepravní služba Intime</caption>
          <tr>
              <td>Dobírka</td>
              <td>140 Kč *zdarma při nákupu nad 5.000 Kč</td>
          </tr>
          <tr>
              <td>Platba předem</td>
              <td>110 Kč *zdarma při nákupu nad 5.000 Kč</td>
          </tr>
      </table>

      <p>Osobní odběr v areálu PRONDO s.r.o. zdarma</p>

      <p> * - Cena poštovného či poštovné zdarma při objednávce nad 5.000 Kč může být změněno při jednotlivých
          objednávkách, dle hmotnosti a nákladů na přepravu konkrétního zboží. V takovém případě budeme vše se
          zákazníkem
          konzultovat.
      </p>
      <p> U zásilek do zahraničí řešíme poštovné individuálně.
      </p>

    </>
  );

}

export default ShippingPage;
