import React from 'react';

// Functions
import * as money from 'helpers/money';

/**
* tax counting:
*   if entered "price..." without "tax" => nothing counted
*   if entered "price..." with "tax" => counted another/missing price
*   "countTaxForOld": true count TO tax; false = count FROM tax; undefined/null = nothing
*/
const ProdPrice = ({ priceTax, priceNoTax, priceOldTax, priceRefundableTax, tax, countTaxForOld, countTaxForRefundable, quantity }) => {

  // Return if no price entered
  if ( (! priceTax) && (! priceNoTax) ) {
    return null;
  }

  if (! priceTax || priceTax < 0.0) {
    priceTax = null;
  }
  if (! priceNoTax || priceNoTax < 0.0) {
    priceNoTax = null;
  }
  if (! priceOldTax || priceOldTax < 0.0) {
    priceOldTax = null;
  }
  if (! priceRefundableTax || priceRefundableTax < 0.0) {
    priceRefundableTax = null;
  }

  // Choose where tax is directly applied, or where to calculate tax?
  if (tax) {
    if (! priceTax && priceNoTax) {
      priceTax = money.priceToTax(priceNoTax, tax);
    }
    if (priceTax && ! priceNoTax) {
      priceNoTax = money.priceFromTax(priceTax, tax);
    }
    if (priceOldTax) {
      if (countTaxForOld === true) {
        priceOldTax = money.priceToTax(priceOldTax, tax);
      } else if (countTaxForOld === false) {
        priceOldTax = money.priceFromTax(priceOldTax, tax);
      }
    }
    if (priceRefundableTax) {
      if (countTaxForRefundable === true) {
        priceRefundableTax = money.priceToTax(priceRefundableTax, tax);
      } else if (countTaxForRefundable === false) {
        priceRefundableTax = money.priceFromTax(priceRefundableTax, tax);
      }
    }
  }

  // Multiply by quantity?
  if (quantity !== undefined) {
    if (quantity > 0.0) {
      if (priceTax) {
        priceTax *= quantity;
      }
      if (priceNoTax) {
        priceNoTax *= quantity;
      }
      if (priceOldTax) {
        priceOldTax *= quantity;
      }
      if (priceRefundableTax) {
        priceRefundableTax *= quantity;
      }
    } else {
      priceTax = null;
      priceNoTax = null;
      priceOldTax = null;
      priceRefundableTax = null;
    }
  }

  if (! priceTax) {
    return (
      <div className="prod-price">
        <div className="prod-price-nothing row">
          <div>cena na dotaz</div>
        </div>
      </div>
    );
  }

  return (
    <div className="prod-price">
      {
        priceOldTax && (priceOldTax > priceTax) &&
          <div className="prod-price-old row">
            <div></div>
            <div>{ money.formatCzk(priceOldTax) } Kč</div>
          </div>
      }
      <div className="prod-price-tax row">
        <div>cena s DPH</div>
        <div><span className="large">{ money.formatCzk(priceTax) }</span> Kč</div>
      </div>
      {
        priceRefundableTax &&
          <div className="prod-price-refundable row">
            <div>+ k ceně</div>
            <div>{ money.formatCzk(priceRefundableTax) } Kč za vratný díl</div>
          </div>
      }
      {
        priceNoTax &&
          <div className="prod-price-notax row">
            <div>cena bez DPH</div>
            <div>{ money.formatCzk(priceNoTax) } Kč</div>
          </div>
      }
    </div>
  );

}

export default ProdPrice;

ProdPrice.defaultProps = {
  tax: 21,
  countTaxForOld: null,
  countTaxForRefundable: null
}
