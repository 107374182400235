import React from 'react';

// Forms
import FormNewsletter from 'components/forms/newsletter';


const Newsletter = ({ props }) => {


  // TODO: REMOVE and show newsletter back
  return null;


  return (
    <section className="newsletter">
      <h1>Přihlaste se k odběru novinek</h1>
      <FormNewsletter />
    </section>
  );

}

export default Newsletter;
