import React from 'react';

// Parts
import Category from 'components/parts/product/category';


const Categories = ({ categories, backLink, type }) => {

  return(
    <section className="categories">
      {
        (backLink) &&
          <Category backLink={backLink} name="zpět" type="back" />
      }

      {
        categories && categories.map( item =>
          <Category
            key={ item.id }
            id={ item.id }
            name={ item.name }
            type={type}
          />
        )
      }

      {
        // Width from CSS!!!
        [...Array( Math.floor(1100 / (250+4)) ).keys()].map( idx =>
          <div key={idx * -1} className="dummy"></div>
        )
      }
    </section>
  );

}

export default Categories;
