import { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';

// Parts
import ProductRow from 'components/parts/productrow';
import Loader from 'components/parts/loader';

// Helpers
import {searchRemaining} from 'helpers/search';
import {orderByBackend} from 'helpers/products';

// Context
import {useGlobal} from 'context/globalContext.js';
import {useProducts} from 'context/productsContext';

// Config
import configFile from 'config';


const SearchList = ({ onSet, onClear }) => {
  // Hooks
  const {setOverlay} = useGlobal();
  const {products} = useProducts();
  const history = useHistory();

  // After mount - load products
  /*useEffect( () => {
    const fetchData = async () => {
      await searchLoad(products.search.text, exact, products.vehicle.id);
    };
    fetchData();
  }, [] );*/


  const handleGoToProduct = (id, slug) => {
    setOverlay(null);
    //history.push( "/produkt/" + id + "/" + slug );
  }


  // RENDER >>>


  const sorted_products = orderByBackend( products.all );
  const {remaining, remainingText} = searchRemaining(products.search.text, configFile.config.search.minSearchLength);
  const sync = true;


  if (remaining > 0) {
    return (
      <form className="form-col">
        <div className="form-row">
          <p className="h-center">{ remainingText }</p>
        </div>
      </form>
    );
  }

  if ( products.sync.search < 0) {
    return (
      <form className="form-col">
        <div className="form-row">
          <div className="h-center msg alt_err">
            {
              (products.sync.search === -504) && <>Server je zřejmě vytížen.<br/></>
            }
            Nepodařilo se získat výsledky ze serveru. Vyzkoušejte hledání opakovat později.
          </div>
        </div>
      </form>
    );
  }

  if (products.sync.search === 0) {
    return (
      <form className="form-col">
        <div className="form-row">
          <p className="h-center">Hledání spustíte kliknutím na ikonu <span className="btn-icon icon is-search"></span></p>
        </div>
      </form>
    );
  }

  if ( products.sync.search === 2) {
    return (
      <form className="form-col">
        <div className="form-row">
          <Loader type="block" text="Vyhledávám..." />
        </div>
      </form>
    );
  }

  let foundProductsCount = 0;

  return (
    <form className="search-list form-col">

      <div className="form-row">
        <div className="search-results" >
          {
            // Use className "scroller scroller-y" ???
            ( products.sync.search === 1) &&
              <div className="h-center msg alt_warn">Níže zobrazené výsledky jsou z předchozícho vyhledávání. Nové spustíte kliknutím na ikonu lupy.</div>
          }
          {
            (sorted_products !== 0) &&
              sorted_products.map( item => {
                  if ("search" in item.types) {
                    foundProductsCount += 1;
                    return (
                      <Link to={"/produkt/" + item.id + "/" + item.slug} onClick={ () => handleGoToProduct(item.id, item.slug) } className="pointer" >
                        <hr/>
                        <ProductRow
                          product={ item }
                          format="small"
                          applyLinks={false}
                          showDetail={true}
                          showCart={false}
                          showRemove={false}
                          showDetailManufacturer={false}
                          showFlags={true}
                          largestCol={4}
                          useLinks={false}
                        />
                      </Link>
                    );
                  } else {
                    return null;
                  }
                }
              )
          }
          {
            (foundProductsCount === 0) &&
              <div className="h-center">Pro tento výraz jsme nenašli žádnou položku.</div>
          }
        </div>
      </div>

    </form>
  );

};

export default SearchList;
