import React from 'react';


const ProjectsPage = ({ props }) => {

  return (
    <>

      <h1>Naše projekty</h1>
      <p>... co vše sem dáme? ...</p>

    </>
  );

}

export default ProjectsPage;
