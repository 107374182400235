
export const password = {
  password_old: "",
  password_new: "",
  password_repeat: "",
}

export const user = {
  email: "",
  roles: {},
  firstname: "",
  lastname: "",
}


export async function initPassword() {
  return {...password};
}

export async function initUser() {
  return {...user};
}
