import * as React from "react";

// Config
import configFile from 'config';


const GlobalContext = React.createContext();


const init = {
  data: null,
  item_id: null,
  messages: [],
  overlay: null,
  navigation: [],
  bar: configFile.config.page.bar,
};


/*
* Each dispatcher must return object {} with new data to enter into new state
*/
const dispatcher = (action, oldData, newData) => {

  switch (action) {
        
    case 'DATA_SET':
      oldData.data = newData;
      break;
    case 'ITEM_ID_SET':
      oldData.item_id = newData;
      break;


    case 'MESSAGES_SET':
      oldData.messages = newData;
      break;
    case 'MESSAGES_CLEAR':
      oldData.messages = [];
      break;
    case 'MESSAGES_ADD':
      oldData.messages = oldData.messages.concat(newData);
      break;
    case 'MESSAGES_REMOVE':
      oldData.messages = oldData.messages.filter(oldMessage => oldMessage.id !== newData.id);
      break;


    case 'OVERLAY_SET':
      oldData.overlay = newData;
      break;
    

    case 'NAVIGATION_CLEAR':
      oldData.navigation = [];
      break;
    case 'NAVIGATION_SET':
      oldData.navigation = newData;
      break;


    case 'BAR_SET':
      oldData.bar = newData;
      break;


    default:
      throw new Error(`GLOBAL context: Unsupported action type: ${action.type}`);
  }
};



function globalReducer(state, action) {
  //console.log("Debug GLOBAL state", action.type, action.payload);

  let newState = { ...state };

  if ( action.type == 'MULTI' ) {

    action.payload.forEach( dispatch => {
      dispatcher( dispatch.type, newState, dispatch.payload );
    })

  } else {

    dispatcher( action.type, newState, action.payload );

  }

  //console.log( newState );
  return newState;
}


// Own hook for reading/writing values/states
function useGlobal() {
  const context = React.useContext(GlobalContext);
  if (! context) {
    throw new Error("GLOBAL context: useGlobal() must be used within a GlobalProvider");
  }
  const [state, dispatch] = context;

  // OWN OPTIONAL DISPATCH methods here:
  const messagesSet = (messages) => {
    dispatch({ type: 'MESSAGES_SET', payload: messages });
    setTimeout(() => {
        dispatch({ type: 'MESSAGES_CLEAR' });
      }, 3000);
  };
  const messagesClear = () => dispatch({ type: 'MESSAGES_CLEAR' });
  const messagesAdd = (type, text) => {
    dispatch({ type: 'MESSAGES_ADD', payload: {type,text} });
    setTimeout(() => {
        dispatch({ type: 'MESSAGES_CLEAR' });
      }, 3000);
  };

  const setOverlay = (type) => dispatch({ type: 'OVERLAY_SET', payload: type });

  const setData = (data) => dispatch({ type: 'DATA_SET', payload: data });
  const setItem_id = (item_id) => dispatch({ type: 'ITEM_ID_SET', payload: item_id });

  const navigationClear = () => dispatch({ type: 'NAVIGATION_CLEAR' });
  const navigationSet = (navigation) => dispatch({ type: 'NAVIGATION_SET', payload: navigation });

  const barSet = (bar) => dispatch({ type: 'BAR_SET', payload: bar });

  return {
    global : state,
    globalDispatch : dispatch,
    messagesSet,
    messagesClear,
    messagesAdd,
    setOverlay,
    setData,
    setItem_id,
    navigationClear,
    navigationSet,
    barSet,
  }
}


// Providing context in app tree
function GlobalProvider(props) {
  const [state, dispatch] = React.useReducer(globalReducer, init);
  const value = React.useMemo(() => [state, dispatch], [state]);
  return <GlobalContext.Provider value={value} {...props} />
}


export {GlobalProvider, useGlobal};
