import {empty} from 'helpers/core';


export function strVehicle(kw, hp, ccm) {
  let out = "";
  if ( ! empty(kw) ) {
    out += kw + "kW";
  }
  if ( ! empty(hp) ) {
    out += (out.length === 0) ? hp : " / " + hp + "hp";
  }
  if ( ! empty(ccm) && (ccm != "0") ) {
    out += (out.length === 0) ? ccm : ", " + ccm + "ccm³";
  }
  return out;
}

export function strYears(yearFrom, yearTo) {
  let out = "";
  if ( ! empty(yearFrom) ) {
    let toCut = yearFrom.length - 4;
    if (toCut > 0) {
      yearFrom = yearFrom.slice(0,toCut*-1);
    }
    out += yearFrom;
  }
  if ( ! empty(yearTo) ) {
    let toCut = yearTo.length - 4;
    if (toCut > 0) {
      yearTo = yearTo.slice(0,toCut*-1);
    }
    out += (out.length === 0) ? yearTo : " - " + yearTo;
  }
  return out;
}

export function strEngine(engine) {
  let out = "";
  if ( ! empty(engine) ) {
    out = engine;
  }
  return out;
}
