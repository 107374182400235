import React from 'react';


const Aboutus = ({ props }) => {

  return (
    <section className="aboutus">

      <div>
        <div className="icon ir-thumbs-up"></div>
        <div>
          <h1>Tradice a stabilita</h1>
          <p>Jsme na trhu od roku 1991. <br/>
            Chováme se zodpovědně a proto <br/>
            jsme tu stále pro vás.</p>
        </div>
      </div>

      <div>
        <div className="icon is-clipboard-list"></div>
        <div>
          <h1>Široká nabídka produktů</h1>
          <p>Přes 8.000 položek skladem u nás, <br/>
            přes 100.000 položek skladem <br/>
            u dodavatelů</p>
        </div>
      </div>

      <div>
        <div className="icon ir-check-circle"></div>
        <div>
          <h1>Odbornost a specializace</h1>
          <p>Vyznáme se v dílech, <br/>
            obzvlášť na Škodovky. <br/>
            Vyhledáme i poradíme <br/>
            s ostatními značkami.</p>
        </div>
      </div>

    </section>
  );

}

export default Aboutus;
