import {isset} from 'helpers/core';


export function unifySearchData(dataSearch) {
  let search = [];
  dataSearch.products_search_categories.forEach( function (item, index) {
    search.push(item);
  });
  dataSearch.products_search_codes.forEach( function (item, index) {
    search.push(item);
  });
  dataSearch.products_search_refs.forEach( function (item, index) {
    search.push(item);
  });
  dataSearch.products_search_names.forEach( function (item, index) {
    search.push(item);
  });
  return search;
}

export function searchRemaining(text, minLength) {
  if ( !isset(text) || !isset(minLength) ) {
    return undefined;
  }

  const remaining = minLength - text.length;
  let remainingText = `Zadejte ještě ${remaining} znak`;

  if (remaining === 1) {
    remainingText += ".";
  } else if ( (2 <= remaining) && (remaining <= 4) ) {
    remainingText += "y.";
  } else {
    remainingText += "ů.";
  }

  return {remaining, remainingText};
};
