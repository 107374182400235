import config from 'config.json';
import * as Data from './data';
import * as Http from './httpData';
import * as Core from 'helpers/core';
/*import * as Dt from 'utils/dt';*/


// PRODUCT + PRODUCTS >>>

const productUrl = config.apiUrl + '/product';
const productsUrl = config.apiUrl + '/products';

function mapToProduct(item, typeKey, typeValue = null) {
  /*Helpers.renameObjKey(data, "pcId", "_id");
  data._id = Helpers.parsePc(data._id);
  data.fcUserAuthor = Helpers.parseFc(data.fcUserAuthor);
  data.dtCreated = Dt.fromMysql(data.dtCreated);
  data.dtPublished = Dt.fromMysql(data.dtPublished);
  */

  // TYPES
  if ( !("types" in item) ) {
    item.types = {};
  }
  if (typeKey) {
    item.types[typeKey] = typeValue;
  }

  const intId = Core.toInt(item.id);
  if (intId !== null) {
    item.id = intId;
  }

  // TODO: Description <br> tags to new lines
  // TEXT
  if (item.text.description) {
    item.text.description = Core.brToLine(item.text.description);
  }

  // STOCK
  if ( !("stock" in item) ) {
    item.stock = {};
  }
  if ( Core.isInt(item.stock.stock) && item.stock.stock > 0) {
    item.stock.delivery = new Date(item.stock.delivery.date);
    if ( ! Core.isDate(item.stock.delivery) ) {
      item.stock.delivery = null;
    }
  } else {
    item.stock.delivery = null;
  }

  // CART
  if ( !("cart" in item) ) {
    item.cart = {};
  }
  item.cart.to = (item.cart.in && item.cart.in > 0) ? item.cart.in : (item.order.together > 0) ? item.order.together : 1; // TODO: Change according to backend

  return item;
}
function mapFromProduct(item) {
  /*Helpers.renameObjKey(data, "_id", "pcId");
  Helpers.removeObjKey(data, "fcUserAuthor");
  Helpers.removeObjKey(data, "dtCreated");
  Helpers.removeObjKey(data, "dtPublished");
  */
  return item;
}
export function listProducts(data, typeKey, typeValue = null) {
  let mapped = [];
console.log(data);
  data.forEach( function (item, index) {
    item.row = index;
    mapped.push( mapToProduct(item, typeKey, typeValue) );
  });
console.log( mapped );
  return mapped;
}


/*
* Types:
*   load type -> into "params"
*   local type -> into "type" arg
*/
export async function getProducts(id, slug, params, type = "list") {
  const response =
    ( Core.isset(id) && Core.isset(slug) )
      ? await Http.get( productsUrl + `/${id}/${slug}` + config.apiFormat + Data.params(params) )
      : await Http.get( productsUrl + config.apiFormat + Data.params(params) );
  response.data.products = ( response.data.products === undefined )
    ? []
    : listProducts( response.data.products, (type ? type : "list") );
  return response.data;
}

export async function getProduct(id, slug, params) {
  const response = await Http.get( productUrl + `/${id}/${slug}` + config.apiFormat + Data.params(params) );
  response.data.product = ( response.data.product === undefined )
    ? null
    : mapToProduct( response.data.product, "detail" );
  return response.data;
}

// To params: word, exact
export async function search(params) {
  const response = await Http.get( productsUrl + "/search" + config.apiFormat + Data.params(params) );
  // TODO: Add to output only if isn't already in output from another category
  response.data.products = ( response.data.products === undefined )
    ? []
    : listProducts( response.data.products, "search", ( (params && "vehicle" in params && params["vehicle"] == 0) ? "nontd" : null ) );
  //response.data.nextpage = ( response.data.nextpage );  // TODO: Delete later
  return response.data;
}


// PRODUCTS CART >>>

const cartUrl = config.apiUrl + '/cart';

export async function insertOrUpdateToCart(data, params) {
  const response = await Http.post( cartUrl + config.apiFormat + Data.params(params), {product: mapFromProduct(data)} );
  response.data.product = ( response.data.product === undefined )
    ? null
    : mapToProduct( response.data.product, "cart" );
  return response.data;
}

export async function getCart(params) {
  const response = await Http.get( cartUrl + config.apiFormat + Data.params(params) );
  response.data.products = ( response.data.products === undefined )
    ? []
    : listProducts( response.data.products, "cart" );
  return response.data;
}



// INFO >>>

const infoUrl = config.apiUrl + '/product';

function mapToInfoParam(item) {
  return item;
}
function listInfoParams(data) {
  let mapped = [];
  /*data.forEach( function (item, index) {
    mapped.push( mapToInfoParam(item) );
  });*/
  for ( const [key, value] of Object.entries(data) ) {
    let item = mapToInfoParam(value);
    item.id = key;
    mapped.push( item );
  }
  return mapped;
}

function mapToInfoReplacement(item) {
  return item;
}
function listInfoReplacements(data) {
  let mapped = [];
  /*data.forEach( function (item, index) {
    mapped.push( mapToInfoParam(item) );
  });*/
  for ( const [key, value] of Object.entries(data) ) {
    let item = mapToInfoReplacement(value);
    item.id = key;
    mapped.push( item );
  }
  return mapped;
}

function mapToInfoCross(item) {
  return item;
}
function listInfoCross(data) {
  let mapped = [];
  /*data.forEach( function (item, index) {
    mapped.push( mapToInfoParam(item) );
  });*/
  for ( const [key, value] of Object.entries(data) ) {
    let item = mapToInfoCross(value);
    item.id = key;
    mapped.push( item );
  }
  return mapped;
}

function mapToInfoOe(item) {
  return item;
}
function listInfoOe(data) {
  let mapped = [];
  /*data.forEach( function (item, index) {
    mapped.push( mapToInfoParam(item) );
  });*/
  for ( const [key, value] of Object.entries(data) ) {
    // Create a new row
    let item = {
      manufacturer: key,
      oe: mapToInfoOe(value)
    }
    mapped.push( item );
  }
  return mapped;
}

function mapToInfoUsage(item) {
  return item;
}
function listInfoUsage(data) {
  let mapped = [];
  /*data.forEach( function (item, index) {
    mapped.push( mapToInfoParam(item) );
  });*/
  for ( const [key, value] of Object.entries(data) ) {
    let item = mapToInfoUsage(value);
    //item.id = key;
    mapped.push( item );
  }
  return mapped;
}


export async function getInfo(id, slug, info, params) {
  const response = await Http.get( infoUrl + `/${id}/${slug}/${info}` + config.apiFormat + Data.params(params) );
  switch (info) {
    case "params":
      response.data.params = ( response.data.params === undefined )
        ? []
        : listInfoParams( response.data.params );
      break;
    case "replacements":
      /*response.data.replacements = ( response.data.replacements === undefined )
        ? []
        : listInfoReplacements( response.data.replacements );*/
      response.data.replacements = ( response.data.replacements === undefined )
        ? []
        : listProducts( response.data.replacements, "cross" );
      response.data.cross = ( response.data.cross === undefined )
        ? []
        : listInfoCross( response.data.cross );
      break;
    case "oe":
      response.data.oe = ( response.data.oe === undefined )
        ? []
        : listInfoOe( response.data.oe );
      break;
    case "usage":
      response.data.usage = ( response.data.usage === undefined )
        ? []
        : listInfoUsage( response.data.usage );
      break;
    default:
      // TODO: Handle non-existing info type
      return undefined;
  }
  return response.data;
}
