import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Parts
import ProdFlags from 'components/parts/product/prodflags';
import ProdPrice from 'components/parts/product/prodprice';
import ProdTocart from 'components/parts/product/prodtocart';
import ProdStock from 'components/parts/product/prodstock';
import DetailInfo from 'components/parts/product/detailinfo';
import DetailOrder from 'components/parts/product/detailorder';
import Loader from 'components/parts/loader';

// Helpers
import { empty } from 'helpers/core';
import { countDiscount } from 'helpers/money';
import * as Url from 'helpers/url';

// Images
import img_empty from 'assets/images/noimg.png';

// Context
import {useGlobal} from 'context/globalContext';
import {useProducts} from 'context/productsContext';
// Data
import * as DataProduct from 'data/productData';


const ProductRow = ({ product, format, applyQuantity, applyLinks, showFlags, showDetail, showCart, showStock, showPrice, showRemove, showInfo, info, largestCol, useLinks }) => {
  const {messagesAdd, setOverlay} = useGlobal();
  const {productsReplaceType, productSet} = useProducts();
  const [cartSync, setCartSync] = useState(false);


  // POST

  const cartInsertOrUpdate = async (product) => {
    try {
      const updatedProduct = await DataProduct.insertOrUpdateToCart(product);
      return updatedProduct.product;
    } catch (ex) {
      return null;
    }
  }


  const removeFromCart = async (e) => {
    e.preventDefault();

    let prevQuantity = product.cart.to;
    product.cart.to = 0;  // Set curently selected quantity to 0

    // Push to remote
    setCartSync(undefined);
    const newProduct = await cartInsertOrUpdate(product);
    if ( newProduct === null ) {
      product.cart.to = prevQuantity;
      setCartSync(false);
      messagesAdd(1, "Nepodařilo se změnit zboží v košíku. Zřejmě chyba komunikace, prosím, kontaktujte nás.");
      return;
    }
    setCartSync(true);

    // Set new quantities
    product.cart.to = product.order.together > 0 ? product.order.together : 1;
    product.cart.in = newProduct.cart.in;

    // Change product locally
    productSet(product);

    messagesAdd(0, "Zboží odebráno z košíku");
  };


  // RENDER (buy) >>>

  if ( format == "buy" ) {
    return (
      <div className={`${(product.stock.stock > 0) ? "" : "zerostock "}format-buy product-row row`}>

        <Link to={ Url.productLinkSlugify(product.id, product.text.title) } className="col-image col-2">
          <img src={ product.image.thumbnail ? product.image.thumbnail : img_empty } alt={"Foto produktu "/* + product.text.title*/} className={ product.image.thumbnail ? "img-fit" : "img-empty" } />
          {
            (showFlags) &&
              <ProdFlags flags={product.flag} discount={countDiscount(product.price.recommended, product.price.price)} />
          }
        </Link>

        <div className="col-text col-4">
          <Link to={ Url.productLinkSlugify(product.id, product.text.title) } className="inverted">{product.text.title}</Link>
          <DetailInfo code={product.code.code} manufacturer={product.text.manufacturer} ean={product.code.ean} />
        </div>

        <div className="col-price col-3">
          <ProdPrice quantity={undefined} priceTax={product.price.price} priceRefundableTax={product.price.fee.refundable} tax={product.price.tax} />
        </div>

        <div className="col-cart col-3">
          <ProdTocart showButton={true} product={product} />
          <ProdStock quantity={product.stock.stock} local={product.stock.local} delivery={product.stock.delivery} />
        </div>

      </div>
    );
  }


  // RENDER (others) >>>

  let picCol = 2;

  /*if (largestCol === undefined) {
    // 2 from image
    largestCol = 3; // 2 base size
    if (showCart === false) { largestCol += 1; }
    if (showStock === false) { largestCol += 2; }
    if (showPrice === false) { largestCol += 3; }
    if (showRemove === false) { largestCol += 1; }
  } else {
    picCol = 12 - largestCol;
  }*/

  // 2 from image
  //largestCol = 3; // 2 base size
  if (showCart === false) { largestCol += 1; }
  if (showStock === false) { largestCol += 2; }
  if (showPrice === false) { largestCol += 3; }
  if (showRemove === false) { largestCol += 1; }

  let restCol = 12 - picCol - largestCol;

  return (
    <div className={`${(product.stock.stock > 0) ? "" : "zerostock "}${(format.length === 0) ? "" : `format-${format} `}product-row row`}>

      {
        useLinks
        ?
          <Link to={ ! applyLinks ? null : Url.productLinkSlugify(product.id, product.text.title) } className={`${applyLinks ? "pointer " : ""}col-image col-${picCol}`}>
            <img src={ product.image.thumbnail ? product.image.thumbnail : img_empty } alt={"Foto produktu "/* + product.text.title*/} className={ product.image.thumbnail ? "img-fit" : "img-empty" } />
            {
              (showFlags) &&
                <ProdFlags flags={product.flag} discount={countDiscount(product.price.recommended, product.price.price)} />
            }
          </Link>
        :
          <div className={`${applyLinks ? "pointer " : ""}col-image col-${picCol}`}>
            <img src={ product.image.thumbnail ? product.image.thumbnail : img_empty } alt={"Foto produktu "/* + product.text.title*/} className={ product.image.thumbnail ? "img-fit" : "img-empty" } />
            {
              (showFlags) &&
                <ProdFlags flags={product.flag} discount={countDiscount(product.price.recommended, product.price.price)} />
            }
          </div>
      }

      <div className={`col-text col-${largestCol}`}>
        {
          useLinks
          ?
            <Link to={ ! applyLinks ? null : Url.productLinkSlugify(product.id, product.text.title) } className={`${applyLinks ? "inverted " : ""}`}>{product.text.title}</Link>
          :
            <span>{product.text.title}</span>
        }
        {
          (showDetail) &&
            <DetailInfo code={product.code.code} ean={product.code.ean} manufacturer={product.text.manufacturer} />
        }
      </div>

      <div className={`col-rest col-${restCol}`}>

        <div className="row-rest row">
          {
            (showCart)
            ?
              <div className="col-cart col-2">
                <ProdTocart showButton={false} product={product} />
              </div>
            : (showCart === null) &&
              <div className="col-cart col-2">
                Počet: {product.cart.in}x
              </div>
          }

          {
            (showStock) &&
              <div className="col-stock col-4">
                <ProdStock quantity={product.stock.stock} local={product.stock.local} delivery={product.stock.delivery} />
              </div>
          }

          {
            (showPrice) &&
              <div className="col-price col">
                <ProdPrice quantity={ (applyQuantity) ? product.cart.in : undefined } priceTax={product.price.price} priceRefundableTax={product.price.fee.refundable} tax={product.price.tax} />
              </div>
          }

          {
            (showRemove) &&
            <>
              {
                (cartSync === undefined)
                ?
                  <Loader />
                :
                  <div onClick={ (e) => removeFromCart(e) } className="col-remove col-1">
                    <span className="btn-icon icon is-trash-alt"></span>
                  </div>
              }
            </>
          }
        </div>

        {
          ( showInfo ) &&
            <div className="row-info row">
              <DetailOrder
                info={info}
                incart={product.cart.in}
                onstock={product.stock.stock}
                together={product.order.together}
                transport={product.order.transport}
              />
            </div>
        }

      </div>
    </div>
  );

}


ProductRow.defaultProps = {
  info: [],
  format: "",
  applyQuantity: false,
  applyLinks: true,
  showFlags: false,
  showDetail: true,
  showCart: true,
  showStock: true,
  showPrice: true,
  showRemove: true,
  showInfo: false,
  largestCol: 3,
  useLinks: true
};

export default ProductRow;
