import { useState, useEffect } from 'react';

// Parts
import Loader from 'components/parts/loader';

// Helpers
import { isset } from 'helpers/core';
import {changeTitle} from 'helpers/page';
import {strYears,strVehicle,strEngine} from 'helpers/vehicles';

// Context
import {useGlobal} from 'context/globalContext';
import {useProducts} from 'context/productsContext';
// Data
import * as AccountData from 'data/accountData';

// Config
import configFile from 'config';


const initVehicles = [];

const GaragePage = (props) => {
  // Hooks
  const {messagesAdd} = useGlobal();
  const {vehicleSet} = useProducts();
  const {vehicle} = useProducts().products;
  const [vehicles, setVehicles] = useState(undefined);
  const [sync, setSync] = useState(false);

  useEffect( () => {
    const fetchData = async () => {
      await vehiclesLoad();
    };
    fetchData();
  }, [] );


  // GET
  const vehiclesLoad = async () => {
    setVehicles(undefined);
    try {
      const garage = await AccountData.garageGet();
      setVehicles(garage.vehicles);
      return garage.vehicles;
    } catch(e) {
      setVehicles([]);
      return null;
    }
  };

  // PATCH
  const vehicleDefault = async (id) => {
    setSync(undefined);
    try {
      const data = await AccountData.garageSetDefault(id);
      setSync(id);
      return data;
    } catch(e) {
      setSync(null);
      return null;
    }
  };

  // DELETE
  const vehicleDelete = async (id) => {
    setSync(undefined);
    try {
      const response = await AccountData.garageDel(id);
      setSync(id);
      return response;
    } catch(e) {
      setSync(null);
      return null;
    }
  };


  const handleVehicleApply = async (id) => {
    vehicles.forEach( function (item, index) {
      if ( item.id == id ) {
        //changeTitle("PRONDO - "+ item.manufacturer_text +" "+ item.model_text +" "+ item.modification_kw +"kW "+ item.modification_engine);
        changeTitle("PRONDO - "+ item.manufacturer_text +" "+ item.model_text +" "+ item.modification_kw +"kW");

        vehicleSet(item);
      }
    });
  }


  const handleVehicleDefault = async (id) => {
    const data = await vehicleDefault(id);

    switch (data.status) {
      case 0:
      case -1:
        break;
      case 5100:
        messagesAdd(3, "Musíte se nejdříve přihlásit.");
        return;
      case 6402:
        messagesAdd(1, "Systémová chyba: není vybráno vozidlo.");
        return;
      case null:
        messagesAdd(1, "Nepodařilo se nastavit jiné vozidlo, prosím kontaktujte nás.");
        return;
      default:
        messagesAdd(1, "Systémová chyba " + data.status + ", prosím kontaktujte nás.");
        return;
    }
    if (data.changed === null) {
      messagesAdd(1, "Nepodařilo se nastavit jiné vozidlo, prosím kontaktujte nás.");
      return;
    }

    const newVehicles = [...vehicles];
    newVehicles.forEach( newVehicle => {
      newVehicle.default = (newVehicle.id == id) ? 1 : 0;
    });

    setVehicles(newVehicles);
  }


  const handleVehicleDelete = async (id) => {
    const response = await vehicleDelete(id);

    switch (response.status) {
      case 0:
      case -1:
        messagesAdd(0, "Vozidlo odstraněno z garáže.");
        break;
      case 5100:
        messagesAdd(3, "Musíte se nejdříve přihlásit.");
        return;
      case 6402:
        messagesAdd(2, "Systémová chyba: není vybráno vozidlo.");
        return;
      case null:
        messagesAdd(1, "Nepodařilo se odstranit vozidlo, prosím kontaktujte nás.");
        return;
      default:
        messagesAdd(1, "Systémová chyba " + response.status + ", prosím kontaktujte nás.");
        return;
    }

    const newVehicles = [];
    const newDefault = ( ! isset(response.data.default) || ! isset(response.data.default.id) ) ? null : response.data.default.id;

    vehicles.forEach( newVehicle => {
      if (newVehicle.id == id) {
        return;
      }
      newVehicle.default = ( newDefault != null && newVehicle.id == newDefault) ? 1 : 0;
      newVehicles.push(newVehicle);
    });

    setVehicles(newVehicles);
  }


  // RENDER >>>


  if ( vehicles === undefined ) {
    return (
      <Loader type="page" text="Načítám Vaše automobily ..." />
    )
  }


  const configApplyVehicleAfterLogin = configFile.config.user.applyVehicleAfterLogin;

  return (
    <section id="garagepage" className="row-cols">
      <aside className="col-left">
      </aside>
      <main className="col-middle">

        <h1>Moje garáž</h1>
        <div className="table">
          {
            ( ! isset(vehicles) || (vehicles.length === 0) )
            ?
              <div className="txt-banner">
                <p>Zatím jste si nepřidal(a) žádný automobil.</p>
                <small>Můžete tak učinit z menu "Vyberte Váš automobil".</small>
              </div>
            :
              <div className="table-data">
                {
                  vehicles.map( (item,idx) =>
                    <div className="row">
                      <div className={configApplyVehicleAfterLogin ? "col-7" : "col-9"}>
                        {item.manufacturer_text} {item.model_text} <small>{ strYears(item.model_year_from, item.model_year_to) }</small> {item.modification_text} <small>[{ strVehicle(item.modification_kw,item.modification_hp,item.modification_ccm) }] { strEngine(item.modification_engine) }</small>
                      </div>
                      {
                        (sync === undefined)
                        ?
                          <div className={`${configApplyVehicleAfterLogin ? "col-5" : "col-3"} h-center`}>
                            <Loader />
                          </div>
                        :
                          <>
                            <div className={`${vehicle.id == item.id ? "h-center" : "h-center"} col-2`}>
                              {
                                (vehicle.id == item.id)
                                ? <small>je aplikováno</small>
                                : <button onClick={ (e) => handleVehicleApply(item.id) }>Aplikovat</button>
                              }
                            </div>
                            {
                              (configApplyVehicleAfterLogin) &&
                                <div className={`${item.default == 1 ? "h-center" : "h-left"} col-2`}>
                                  {
                                    (item.default == 1)
                                    ? <small>je výchozím</small>
                                    : <button onClick={ (e) => handleVehicleDefault(item.id) }>Výchozí</button>
                                  }
                                </div>
                            }
                            <div className="col-1">
                              <span onClick={ (e) => handleVehicleDelete(item.id) } className="btn-icon icon is-trash-alt"></span>
                            </div>
                          </>
                      }
                    </div>
                  )
                }
              </div>
          }
        </div>

      </main>
      <aside className="col-right">
      </aside>
    </section>
  );

}

export default GaragePage;
