import React from 'react';
import { Link } from 'react-router-dom';


const HighlightedNews = ({ props, news }) => {

  if (! news) {
    return null;
  }

//<li>Vygenerovány všechny OE a cross kódy, nyní se budou párovat crossy na odkazované produkty.</li>
//<li>Funkční celoobrazová stránka hledání zaměnitelných dílů (u vyhledávání ještě dělám drobné úpravy).</li>
//<li>Z Cezara se synchroinují výrobci (i pro netecdocové položky)</li>
//<li className="alt_text_err">Přepojeno na ostrý sklad</li>
//<li>Administrátorská lišta: vylazeno + přidána možnost "nákpuní ceny"</li>
//<li>Dokončeno předělání logiky "přesného" vyhledávání</li>
//<li>Generují se pomocná data, která jsou potřeba pro skrývání prázdných kategorií při výběru filtru vozidla</li>

//<p>Dříve:</p>
//<ul>
//  <li>Dynamické přepínání názvu titulku okna (ze zadání)</li>
//  <li>Nová stránka Půjčovna nosičů</li>
//  <li>Vytvořeny animace pro vizuální zvýraznění informačních oken a zpráv (přidáno do košíku, vyplňte, ...)</li>
//  <li>Zvýrazněna informace o skladech (skladem, skladem u dodavatele, ...)</li>
//</ul>

/*
<h1>Nový web pro Vás</h1>
<p>Chceme Vás přivítat na našem webu, který jsme Vám připravili na míru.</p>
<p>&nbsp;</p>
<ul>
  <li>Tisíce produktů skladem</li>
  <li>Vybírejte z dílů přímo pro Vaše vozidlo</li>
  <li>Přehledné a jednoduché ovládání</li>
</ul>
<p>&nbsp;</p>
<p><small>Přejete si prozatím využít starý e-shop? Zde:</small> <a href="http://eshop.prondo.cz" target="_blank">eshop.prondo.cz</a></p>
*/

/*
      <p><b className="alt_text_err">POZOR!!! Zde, na ostré verzi prosím nevytvářejte nyní (= dokud se nedokončí generování) objednávky, poptávky, ani registrace uživatelů</b>. Děkuji.</p>
      <br />
*/
  return (
    <div className="box-hero">
      <p>Starý e-shop naleznete na: <a href="http://eshop.prondo.cz" target="_blank">eshop.prondo.cz</a><br/>Pokud nenaleznete co hledáte, využijte prosím poptávkový formulář níže.<br/><br/>Děkujeme.</p>
    </div>
  );

}

export default HighlightedNews;
