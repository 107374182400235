import React from 'react';


const DetailInfo = ({ code, our, ean, manufacturer }) => {

  return(
    <ul className="detail-info">
      {
        (code && manufacturer)
        ?
          <li><b>{code}</b> / {manufacturer}</li>
        :
          (code)
          ?
            <li><b>{code}</b></li>
          :
            (manufacturer)
            ?
              <li>{manufacturer}</li>
            :
              null
      }
      {
        our &&
          <li><span>Náš kód:</span> {our}</li>
      }
      {
        ean &&
          <li><span>EAN:</span> {ean}</li>
      }
    </ul>
  );

}

export default DetailInfo;
