import React from 'react';
import { NavLink, Link } from 'react-router-dom';


const MenuCustom = ({ title, links, classes }) => {

  if (! links) {
    return null;
  }

  return(
    <nav className={`menu menu-custom ${classes}`}>
      {
        title &&
        <h1>{title}</h1>
      }

      <ul>
        {
          links.map( (item,idx) =>
            <li key={idx}><NavLink to={item.link}>{item.text}</NavLink></li>
          )
        }
      </ul>
    </nav>
  );

}

export default MenuCustom;
