import { useState } from 'react';

// Helpers
import { isset } from 'helpers/core';

// Config
import configFile from 'config';


const Bar = () => {
  if ( ! isset(configFile.config.page.bar) ) {
    return null;
  }

  /*return(
    <div id="bar" className="bar">
      {configFile.config.page.bar.text}
      {
        (configFile.config.page.bar.link) &&
        <>
          &nbsp; <a href={configFile.config.page.bar.link} target="_blank">{configFile.config.page.bar.linkText}</a>
        </>
      }
    </div>
  );*/

  return (
    <div id="bar" className="bar" style={{"color":"#A0A0A0"}}>
      <div className="icon is-phone"></div>&nbsp;326&nbsp;728&nbsp;002 &nbsp; <div className="icon is-mobile"></div>&nbsp;725&nbsp;529&nbsp;609 &nbsp; <div className="icon ir-clock"></div>&nbsp;Po-Pá&nbsp;8:00-16:00
    </div>
  );
}

export default Bar;
