import React from 'react';


const EuPage = ({ props }) => {

  return (
    <>
      <h1>Dotační tituly</h1>

      <h2>Kreativní vouchery II</h2>

      <img src={window.location.origin + '/assets/images/eu/eu.png'} alt="dotace-eu" />
      <img src={window.location.origin + '/assets/images/eu/npo.png'} alt="dotace-eu" />
      <img src={window.location.origin + '/assets/images/eu/mk.png'} alt="dotace-eu" />

      <p class="important">Projekt "Kompletní zastřešení digitálního marketingu" je spolufinancován Evropskou unií.</p>
      <p>Projekt byl realizován s podporou dotačního programu Kreativní vouchery II, jehož cílem je podpora spolupráce mezi podniky a kreativními profesionály. Díky této dotaci jsme mohli využít kreativní služby pro rozvoj a inovaci našich marketingových aktivit.</p>

      <dl>
        <dt>Poskytovatel podpory:</dt>
        <dd>Ministerstvo kultury, Maltézské nám. 1, 118 11 Praha 1, Česká republika (Odbor umění, knihoven a kreativních odvětví)</dd>
        
        <dt>Číslo operace:</dt>
        <dd>0461/2024</dd>

        <dt>Název/názvy operace:</dt>
        <dd>NPO - Kreativní vouchery II</dd>

        <dt>Název komponenty:</dt>
        <dd>4.5 Rozvoj kulturního a kreativního sektoru</dd>

        <dt>Název opatření:</dt>
        <dd>4.5.6 Kreativní vouchery</dd>
      </dl>
    </>
  );

}

export default EuPage;
