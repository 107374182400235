import React from 'react';


const Placeholder = ({ image, image_alt }) => {

  if (! image) {
    return null;
  }

  return(
    <div className="placeholder">
      <img src={image} alt={image_alt} />
    </div>
  );

}

export default Placeholder;
