import React from 'react';


const WholesalePage = ({ props }) => {

  return (
    <>

      <h1>Velkoobchod</h1>

      <p> Zabýváme se prodejem náhradních dílů pro osobní automobily, se specializací na vozy Škoda. Dokážeme
          pokrýt
          kompletní sortiment náhradních dílů na vozy všech značek, v některých případech i na nákladní vozy, a to
          ve
          velice příjemných cenových relacích.
      </p>
      <p> Spolupracujeme s velkoobchody, maloobchodními prodejnami, autoservisy, autobazary, autopůjčovnami,
          přepravními
          společnostmi i dalšími OSVČ jako například s taxikáři, rozvozci, obchodníky apod.
      </p>
      <p> Dokážeme vytvořit příjemné podmínky pro spolupráci, snažíme se o osobní přístup k zákazníkovi a
          minimalizaci
          chyb v dodávkách.
      </p>

      <img src="http://prondo.cz/soubory_editor/image/velkoobchod1.jpg" alt="" width="30%" />
      <img src="http://prondo.cz/soubory_editor/image/velkoobchod2.jpg" alt="" width="30%" />


      <h2> Podmínky nákupu:
      </h2>
      <p> Pro možnost spolupráce s naším velkoobchodem je zapotřebí pouze jednoduchá registrace, ke které slouží
          kopie
          ŽL/Výpisu z OR, a pokud je subjekt plátce DPH, pak i kopie registrace k DPH. Registrovat se můžete
          telefonicky,
          e-mailem, faxem či osobně v areálu PRONDO.
      </p>

      <h2> Objednávání zboží
      </h2>
      <p> Děkujeme zá Váš zájem, objednávat můžete následujícímy způsoby:
      </p>

      <ul>
          <li> Prostřednictvím e-shopu http://eshop.prondo.cz
          </li>
          <li> E-mailem na adresy sprava@prondo.cz , nebo cejna@prondo.cz</li>
          <li> Faxem na 326 728 002 nebo 326 726 284</li>
          <li> Telefonicky na 326 728 002 nebo 326 726 284 nebo 326 728 325</li>
      </ul>

      <h2> Dodání zboží
      </h2>
      <p> Zboží zasíláme na dobírku, balíkem do druhého dne prostřednictvím zásilkové služby.
          V tomto případě je účtováno poštovné (dle hmotnosti balíku a ceníku přepravní společnosti – cca od
          100Kč)</p>
      <p> Zboží si také můžete osobně vyzvednout u nás ve skladu, rádi Vás přivítáme.
      </p>

      <h2> Reklamace
      </h2>
      <p> Na zboží se vztahují standartní reklamační lhůty dle občanského/obchodního zákoníku.
      </p>
      <p>Ke zboží prosím přikládejte vyplněný reklamační protokol, který je k dispozici ke stažení zde (PDF, DOC -
          Word).
      </p>

      <h2> Vratné díly
      </h2>
      <p> Kritéria vracení servočerpadel ke stažení zde
      </p>

    </>
  );

}

export default WholesalePage;
