import config from 'config.json';
import * as Data from './data';
import * as Http from './httpData';
/*import * as Helpers from 'utils/helpers';
import * as Dt from 'utils/dt';*/


// ORDER >>>

const orderUrl = config.apiUrl + '/order';

function mapToOrder(item) {
  return item;
}
function mapFromOrder(item) {
  return item;
}
function listOrder(data) {
  let mapped = [];
  /*data.forEach( function (item, index) {
    mapped.push( mapToInfoParam(item) );
  });*/
  for ( const [key, value] of Object.entries(data) ) {
    let item = mapToOrder(value);
    item.id = key;
    mapped.push( item );
  }
  return mapped;
}


export async function insertOrder(data, params) {
  const response = await Http.post( orderUrl + config.apiFormat + Data.params(params), mapFromOrder(data) );
  response.data.order = ( response.data.order === undefined )
    ? null
    : mapToOrder(response.data.order);
  return response;
}


// SHIPPING, PAYMENTS >>

const cartUrl = config.apiUrl + '/cart';

function mapToShipping(item) {
  return item;
}
function mapFromShipping(item) {
  return item;
}
function listShipping(data) {
  let mapped = [];
  data.forEach( function (item, index) {
    mapped.push( mapToShipping(item) );
  });
  return mapped;
}

export async function getShipping(params) {
  const response = await Http.get( cartUrl + "/shipping" + config.apiFormat + Data.params(params) );
  response.data.shipping = ( response.data.shipping === undefined )
    ? []
    : listShipping( response.data.shipping );
  return response.data;
}


function mapToPayment(item) {
  return item;
}
function mapFromPayment(item) {
  return item;
}
function listPayments(data) {
  let mapped = [];
  data.forEach( function (item, index) {
    mapped.push( mapToPayment(item) );
  });
  return mapped;
}

export async function getPayments(params) {
  const response = await Http.get( cartUrl + "/payment" + config.apiFormat + Data.params(params) );
  response.data.payments = ( response.data.payments === undefined )
    ? []
    : listPayments( response.data.payments );
  return response.data;
}
