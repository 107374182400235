import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Forms

// Parts
import Categories from 'components/parts/categories';
import Products from 'components/parts/products';
import VehicleSelector from 'components/parts/vehicleselector';
import Loader from 'components/parts/loader';
import Paginator from 'components/parts/paginator';

// Helpers
import {empty} from 'helpers/core';
import * as Url from 'helpers/url';
//import {unifySearchData} from 'helpers/search';
import {strYears,strVehicle,strEngine} from 'helpers/vehicles';


// Context
import {useGlobal} from 'context/globalContext.js';
import {useProducts} from 'context/productsContext';

// Data
import * as DataProduct from 'data/productData';
import * as DataCategory from 'data/categoryData';


/*products : [
  {
    id: 1,
    manufacturer: "Firma",
    title: "Testovací produkt Testovací abc produkt Testovací produkt",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae ante ultrices, dictum justo at, consequat sapien. Mauris volutpat a dui pellentesque pulvinar. Mauris rhoncus libero nisi, vitae venenatis purus porta nec. Vivamus ac sapien in diam egestas accumsan. Nulla ultrices, quam sodales facilisis consequat, risus ligula consequat lectus, sed vehicula arcu ante vel nulla. Maecenas convallis porttitor tortor non vulputate. Vivamus mollis eros eu nulla elementum tempus. Nulla at orci scelerisque, sagittis enim sed, lobortis enim. Sed ultricies tellus a urna malesuada, ac rhoncus lorem condimentum. Morbi urna magna, finibus a laoreet ac, facilisis et arcu. Donec sodales nisi porttitor lectus viverra efficitur. Duis porta gravida ornare.",
    image: "/assets/images/test-product-small.jpg",
    price: 1234.56,
    tax: 21,
    stock: 100,
    local: true,
    delivery: "1.1.2021",
    flags: [20, 55]
  },
],
[
  {
    id: 1,
    title: "Kategorie",
  },
]*/

/*
* Display types:
*   1 = Group (Products only) : favorites, sale,...
*   2 = Categories and Products
*   3 = Search
*/
const productTypes = {
  category: 2,
  universal: 2,
  search: 3,
  interchangeable: 3,
  favorite: 1,
  sale: 1,
};


/*
* Search when: Clicked "search"; Page loaded; Switched "STR"
*/
const ProductsPage = (props) => {
  // Hooks
  const {global, navigationSet} = useGlobal();
  const {products, searchSetText, searchSetInterchangeable, syncSet, productsReplaceType, productsMerge} = useProducts();
  const [categories, setCategories] = useState(undefined);

  const [paginator, setPaginator] = useState(1);
  const [nextpage, setNextpage] = useState(false);  // Delete later (after search reimplementation)


  const productTypeGroup = (type) => {
    if (! (type in productTypes)) {
      return 0;
    } else {
      return productTypes[type];
    }
  }


  const url = Url.fromGet(props);
  const typeEn = Url.productTypeToEn(url.typ);
  const typeCz = Url.productTypeToCz(url.typ);
  const typeGroup = productTypeGroup(typeEn);
  const vehicleMustBeSelected = (typeEn == "category");


  const fetchData = async (page, fetchCategories = true) => {
    if (vehicleMustBeSelected && products.vehicle.id === null) {
      return false;
    }

    let result = false;

    switch ( typeGroup ) {

      case 1: // Favorite, Sale
        if (products.sync["products_"+typeEn] === 2) {  // Sync already in progress
          return false;
        }
        setCategories(null);
        syncSet({ ["products_"+typeEn] : 2 });
        result = await productsLoad(url.id, url.slug, typeEn, products.vehicle.id, page);
        syncSet({ ["products_"+typeEn] : result });
        break;

      case 2: // Category, Universal
        if (products.sync.products_list === 2) {        // Sync already in progress
          return false;
        }
        syncSet({ products_list : 2 });
        if (fetchCategories) {
          setCategories(null);
        }
        result = await allLoad(url.id, url.slug, typeEn, products.vehicle.id, page, fetchCategories); // FIXED TYPE "list"
        syncSet({ products_list : result });
        break;

      case 3: // Search, Exact
        if (products.sync.search === 2) {               // Sync already in progress
          return false;
        }
//        if (url.params.text == products.search.text && url.params.typ == typeCz) {
////          syncSet({search:true});
//        } else {
        searchSetText(url.params.text);
        searchSetInterchangeable( typeEn == "interchangeable" );
        syncSet({ search : 2 });
        result = await searchLoad(url.params.text, (products.search.interchangeable ? "exact" : "search"), products.vehicle.id, page);  // FIXED TYPE "search"
        syncSet({ search : result });

        // Load NON-TD products also
        if ( ! empty(products.vehicle.id) ) {
          result = await searchLoad(url.params.text, (products.search.interchangeable ? "exact" : "search"), 0, page);
        }
        break;

      default:
        return false;
    }

    return true;
  };

  // After mount - load product
  /*useEffect( async () => {
    await allLoad(url.id, url.slug, category_type, products.vehicle.id, paginator);

    function handleShowLoaders() {
      setCategories(undefined);
      syncSet({search:undefined});
    }

    return () => {
      console.log("cleaned up");
      handleShowLoaders();
    };
  }, [props.match.params.id] );*/


  // Called when changing VEHICLE
  /*useEffect( () => {
    setPaginator(1);
  //}, [window.location.href, products.vehicle.id] );
  }, [products.vehicle.id] );*/


  // Trigger for "SEARCH" only; RUN ONLY ONCE WHEN FIRSTLY(DIRECTLY) LOADED!
  useEffect( () => {
    let sync = false;
    switch ( typeGroup ) {
      case 1: // Favorite, Sale
        if (products.sync["products_"+typeEn] === 0) {  // Sync already in progress
          sync = true;
        }
        break;
      case 2: // Category, Universal
        if (products.sync.products_list === 0) {        // Sync already in progress
          sync = true;
        }
        break;
      case 3: // Search, Interchangeable
        if (products.sync.search === 0) {               // Sync already in progress
          sync = true;
        }
      default:
        break;
    }

    // Return if sync not needed
    if (! sync) {
      return;
    }

    //navigationSet(undefined);
    navigationSet( navigation() );

    if (! fetchData(paginator)) {
      return;
    }

  }, [
    products.sync["products_"+typeEn]
    , products.sync.products_list
    , products.sync.search
  ] );


  // Trigger "SEARCH" by "URL"; Everytime when URL changed, except when going from "Searched result (from popup)"
  useEffect( () => {
    // Skip search when already found in pop-up
    if (typeGroup === 3 && products.sync.search >= 3) {
      return;
    }

    //navigationSet(undefined);
    navigationSet( navigation() );

    setPaginator(1);

    if (! fetchData(1)) {
      return;
    }
  }, [window.location.href] );


  // Trigger when vehicle changed
  useEffect( () => {
    //navigationSet(undefined);
    navigationSet( navigation() );

    setPaginator(1);

    if (! fetchData(1)) {
      return;
    }
  }, [products.vehicle.id] );


  // GET

  // Products load
  const productsLoad = async (id, slug, type, vehicle, page) => {
    try {
      const productsData = await DataProduct.getProducts(id, slug, {type, vehicle, page}, type);
      // Products
      /*let productsToReplace = {};
      productsToReplace[type] = productsData.products;
      productsReplaceType(productsToReplace);*/
      productsReplaceType({ [type]:productsData.products });
      return 4;
    } catch(e) {
      console.log(e);
      return e.code * -1;
    }
  };

  // TODO: CHECK, IF WORKING!!! >>>
  const allLoad = async (id, slug, type, vehicle, page, fetchCategories) => {
    try {
      if (fetchCategories) {
        await Promise.all([
          DataCategory.getCategories(id, slug, {type, vehicle}),
          DataProduct.getProducts(id, slug, {type, vehicle, page})
        ]).then( (responses) => {
          const [categoriesData, productsData] = responses;
          // Categories
          setCategories(categoriesData.categories);
          // Products
          /*let productsToReplace = {};
          productsToReplace[type] = productsData.products;
          productsReplaceType(productsToReplace);*/
          productsReplaceType({ list:productsData.products });  // TYPE CHANGED TO "list"
          // Navigation
          processNavigationAfter(categoriesData.navigation);
        });
      } else {
        const productsData = await DataProduct.getProducts(id, slug, {type, vehicle, page});
        productsReplaceType({ list:productsData.products });  // TYPE CHANGED TO "list"
      }
      return 4;
    } catch(e) {
      console.log(e);
      return e.code * -1;
    }
  }

  // Search
  const searchLoad = async (text, type, vehicle, page) => {
    try {
      const data = await DataProduct.search({ text, type, vehicle, page });
      // Unify all search categories into single "products"
      //const search = unifySearchData(data);
      if ( vehicle !== 0 ) {
        productsReplaceType({ search: data.products }); // TYPE CHANGED TO "search"
      } else {
        productsMerge( data.products ); // TYPE CHANGED TO "search"
      }
      setNextpage( data.nextpage );
      return 4;
    } catch(e) {
      console.log(e);
      return e.code * -1;
    }
  }


  // Process custom navigation

  const navigation = () => {
    let out = Url.buildNavigation();
    // Change "products" nav
    out[1] = Url.pageNew(
        1
        , [ `/products?type=${typeEn}`, `/produkty?typ=${typeCz}` ]
        , [ "home" ]
        , (typeEn === "universal") ? [ "Universal parts", "Ostatní díly" ] : [ "Products", "Produkty" ]
        , "products"
    );
    return out;
  }


  const processNavigationAfter = (categoriesNavigation) => {
    let out = navigation();

    // Change "products" nav
    for (let idx = 0; idx < categoriesNavigation.length; idx++) {
      const nav = categoriesNavigation[idx];
      out.push( Url.pageNew(
          idx + 2,
          [ `/products/${nav.id}/${nav.slug}?type=${typeEn}`, `/produkty/${nav.id}/${nav.slug}?typ=${typeCz}` ],
          [ "home", "products" ],
          [ nav.name, nav.name ]
        )
      );
    }

    navigationSet( out );
  }


  const handlePageChange = (page) => {
    setPaginator(page);
    fetchData(page, false);
  }


  // RENDER >>>

  const renderLoading = (text) => {
    return (
      <div className="row-cols nobg">
        <main className="col-middle nobg noedge">
          <Loader type="block" text={text} />
        </main>
      </div>
    );
  };

  const renderEmpty = (text) => {
    return (
      <div className="row-cols nobg">
        <main className="col-middle nobg noedge">
          <div className="txt-banner">
            <span>{text}</span>
          </div>
        </main>
      </div>
    );
  };

  const renderProducts = (productList, headerText = "Produkty", oldResults = false) => {
    return (
      <div className="row-cols nobg">
        <main className="col-middle nobg noedge">

          <div>
            <h1>{headerText} {(paginator > 1) ? <> <small>strana {paginator}</small></> : null}</h1>
            {
              (oldResults) &&
                <div className="h-center msg alt_warn">Níže zobrazené výsledky jsou z předchozícho vyhledávání. Nové spustíte kliknutím na ikonu lupy.</div>
            }
          </div>

          <Paginator nextpage={nextpage} page={paginator} total={productList.length} setPage={handlePageChange} />

          <Products products={productList} />

          <Paginator nextpage={nextpage} page={paginator} total={productList.length} setPage={handlePageChange} />

        </main>
      </div>
    );
  };

  const renderSelectVehicle = () => {
    const model_years = strYears(products.vehicle.model_year_from, products.vehicle.model_year_to);
    const modification_vehicle = strVehicle(products.vehicle.modification_kw, products.vehicle.modification_hp, products.vehicle.modification_ccm);
    const modification_engine = strEngine(products.vehicle.modification_engine);

    return (
      <div className="row-cols nobg">
        <main className="col-middle novehicle">
          <h1>Vyberte automobil</h1>
          <p>Než budete pokračovat, vyberte, pro který automobil chcete zobrazit díly.</p>

          <p><small>Vybráno:</small> {products.vehicle.manufacturer_text} {products.vehicle.model_text} <small>{(model_years.length === 0) ? "" : model_years}</small> {products.vehicle.modification_text} <small>{(modification_vehicle.length === 0) ? "" : "["+modification_vehicle+"]"}{(modification_engine.length === 0) ? "" : " "+modification_engine}</small></p>

          <VehicleSelector />

          <div>
            <button disabled={(products.vehicle.modification_key === null)}>Vybrat</button>
          </div>
        </main>
      </div>
    );
  };


  let productCount = 0;
  let productList = false;
  let productList_nonTd = false;
  /*let productListCode = false;
  let productListRefs = false;
  let productListNames = false;*/

  // RENDER "SELECT VEHICLE" >>>
  if ( vehicleMustBeSelected && (products.vehicle.id === null) ) {
    return renderSelectVehicle();
  }


  // RENDER if is "SEARCH" view >>>
  if (typeGroup === 3) {
    if (products.sync.search === 2) {                                     // If sync in progress
      return renderLoading("Vyhledávám...");

    } else if (products.sync.search === 0) {                              // If sync set to "empty"
      return renderEmpty("Zadejte text pro vyhledávání a klikněte na ikonu lupy.");

    } else if (products.sync.search === 1 || products.sync.search >= 3) { // If products loaded
      // Get products + Sort
      //productList = orderByBackend( products.all.filter( item => (("search" in item.types) && (item.types.search === null)) ) );
      productList = products.all.filter( item => (("search" in item.types) && (item.types.search === null)) );
      //productList_nonTd = orderByBackend( products.all.filter( item => (("search" in item.types) && (item.types.search === "nontd")) ) );
      productList_nonTd = products.all.filter( item => (("search" in item.types) && (item.types.search === "nontd")) );

      if (
        ( ! productList || (productList.length === 0) )
        && (! productList_nonTd || (productList_nonTd.length === 0))
      ) {
        return renderEmpty("Nebyly nalezeny žádné produkty.");

      } else {
        productCount = productList.length;

        return (
          <div className="row-cols nobg">
            <main className="col-middle nobg noedge">

              <div>
                <h1>Nalezené produkty {(paginator > 1) ? <> <small>strana {paginator}</small></> : null}</h1>
                {
                  (false) &&
                    <div className="h-center msg alt_warn">Níže zobrazené výsledky jsou z předchozícho vyhledávání. Nové spustíte kliknutím na ikonu lupy.</div>
                }
              </div>

              <Paginator nextpage={nextpage} page={paginator} total={productCount} setPage={handlePageChange} />

              {
                (productList && productList.length > 0) &&
                <>
                  <Products products={productList} />
                </>
              }
              {
                (productList_nonTd && productList_nonTd.length > 0) &&
                <>
                  <hr />
                  <h2>Našli jsme pro Vás další produkty</h2>
                  <p>Pozor, tyto díly nemusí být určené pro Vaše vozidlo!</p>
                  <Products products={productList_nonTd} /*banner_title="Další" banner_icon="is-plus"*/ />
                </>
              }

              <Paginator nextpage={nextpage} page={paginator} total={productCount} setPage={handlePageChange} />

            </main>
          </div>
        );
      }
    }
  }


  // RENDER if is "CATEGORY/PRODUCTS" view >>>
  if (typeGroup === 2) {
    if (products.sync.products_list === 1 || products.sync.products_list >= 3) {
      //productList = orderByBackend( products.all.filter( item => "list" in item.types ) );
      productList = products.all.filter( item => "list" in item.types );
    }

    return (
      <>
        <div className="row-cols nobg">
          <main className="col-middle nobg noedge">
            {
              (categories === null)
              ?
                <Loader type="block" text="Načítám kategorie..." />
              :
                <Categories type={typeEn} categories={categories} backLink={ (empty(global.navigation) || global.navigation.length <= 2) ? null : global.navigation[ global.navigation.length-2 ].urn[1] } />
            }
          </main>
        </div>

        <div className="row-cols nobg">
          <main className="col-middle nobg noedge">

          <Paginator nextpage={nextpage} page={paginator} total={productList.length} setPage={handlePageChange} />

            {
              (products.sync.products_list === 2)
              ?
                <Loader type="block" text="Načítám produkty..." />
              :
              ( ! productList || (productList.length === 0) )
              ?
                <div className="txt-banner">
                  <span>Vyberte podkategorii, zde nejsou produkty k zobrazení.</span>
                </div>
              :
                <>
                  <div>
                    <h1>Produkty{(paginator > 1) ? <><small>strana {paginator}</small></> : null}</h1>
                    <div></div>
                  </div>
                  <Products products={productList} />
                </>
            }

            <Paginator page={paginator} total={productList.length} setPage={handlePageChange} />

          </main>
        </div>
      </>
    );
  }


  // RENDER if is "GROUP" view >>>
  if (typeGroup === 1) {
    if (products.sync["products_"+typeEn] === 2) {                                                    // If sync in progress
      return renderLoading("Načítám produkty...");

    } else if (products.sync["products_"+typeEn] === 1 || products.sync["products_"+typeEn] >= 3) { // If products loaded
      // Get products + Sort
      //productList = orderByBackend( products.all.filter( item => typeEn in item.types ) );
      productList = products.all.filter( item => typeEn in item.types );
      if ( ! productList || (productList.length === 0) ) {
        return renderEmpty("V této sekci nejsou žádné produkty k zobrazení.");

      } else {
        switch (typeEn) {
          case "favorite":
            return renderProducts(productList, "Oblíbené produkty");
          case "sale":
            return renderProducts(productList, "Zlevněné produkty");
          default:
            return renderProducts(productList);
        }

      }
    }
  }


  // TODO: Maybe not needed?
  return null;

};


export default ProductsPage;
