import React from 'react';

// Parts
import ControlCart from 'components/parts/header/control-cart';
import ControlUser from 'components/parts/header/control-user';

// Context
import {CountProvider} from 'context/countContext.js';


const Controls = () => {

  return(
    <div className="controls">
      <ControlCart />
      <div></div>
      <ControlUser />
    </div>
  );

}

export default Controls;
