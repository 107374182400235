import { Link as L, useHistory } from 'react-router-dom';


const A = ({ text, to, classLink, classRoot }) => {
  const history = useHistory();

  const handleLink = (whereToGo) => {
    history.push(whereToGo);
  }

  const handleAnchor = (e) => {
    e.preventDefault();
    return false;
  }

  /*return (
    <L to={to} className={`${classes}`}>
      <a href={to}>
        {text}
      </a>
    </L>
  );*/

  return (
    <span onClick={ () => handleLink(to) } className={`pointer ${classRoot}`}>
      <a onClick={handleAnchor} href={to} className={`${classLink}`}>
        {text}
      </a>
    </span>
  );

}


A.defaultProps = {
  classLink: "",
  classRoot: "",
};
export default A;
