import React from 'react';
import { useState, useEffect } from 'react';


const onPage = 18;

const Paginator = ({ page, total, setPage, nextpage }) => {

  if (! total) {
    return null;
  }

  return (
    <p className="paginator h-center">
      {
        (page != 1) &&
          <span onClick={ () => {setPage(page - 1);} } className="link">&lt;&lt;&lt; PŘEDCHOZÍ</span>
      }
      {
        ( ( (total >= onPage) || (page != 1) )
        || (nextpage) ) &&
          <>&nbsp; &nbsp; strana {page} &nbsp; &nbsp;</>
      }
      {
        ( (total >= onPage)
        || (nextpage) ) &&
          <span onClick={ () => {setPage(page + 1);} } className="link">DALŠÍ &gt;&gt;&gt;</span>
      }
    </p>
  );

}

export default Paginator;
