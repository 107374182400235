import * as React from "react";

// Helpers
import { isset, echo, toInt } from 'helpers/core';
import {isSame} from 'helpers/products';

const ProductsContext = React.createContext();


const initVehicle = {
  manufacturer_key : null,
  manufacturer_text : "",
  model_key : null,
  model_text : "",
  modification_key : null,
  modification_text : "",
  modification_engine: "",
  modification_kw: "",
  modification_hp: "",
  modification_ccm: "",
  id : null
};

const initInfoIndex = {
  mouseX: 0,
  mouseY: 0,
  productIndex: null,
};


const init = {
  /* Sync:
   *  -X = error
   *  0 = not synced: initialized
   *  1 = not synced: value changed
   *  2 = not synced: sync in progress (running)
   *  3 = synced: but results are empty
   *  4 = synced ok
   */
  sync: {
    cart: 0,
    search: 0,
    products_list: 0,
    products_favorite: 0,
    products_sale: 0,
  },

  all: [],  // = all products

  infoIndex: {...initInfoIndex},

  vehicle: {...initVehicle},

  search: {
    text: "",
    interchangeable: false,
  },
};



// VEHICLE >>>

function vehicle(type, oldVehicle, key, text, id, customData = null) {

  switch (type) {
    case 'clear':
      oldVehicle = {...initVehicle};
      break;

    case 'manufacturer':
      oldVehicle.manufacturer_key = key;
      oldVehicle.manufacturer_text = echo(text);

      oldVehicle.model_key = initVehicle.model_key;
      oldVehicle.model_text = initVehicle.model_text;
      oldVehicle.model_year_from = initVehicle.model_year_from;
      oldVehicle.model_year_to = initVehicle.model_year_to;

      oldVehicle.modification_key = initVehicle.modification_key;
      oldVehicle.modification_text = initVehicle.modification_text;
      oldVehicle.modification_engine = initVehicle.modification_engine;
      oldVehicle.modification_kw = initVehicle.modification_kw;
      oldVehicle.modification_hp = initVehicle.modification_hp;
      oldVehicle.modification_ccm = initVehicle.modification_ccm;

      oldVehicle.id = initVehicle.id;
      break;

    case 'model':
      oldVehicle.model_key = key;
      oldVehicle.model_text = echo(text);
      oldVehicle.model_year_from = customData.year_from;
      oldVehicle.model_year_to = customData.year_to;

      oldVehicle.modification_key = initVehicle.modification_key;
      oldVehicle.modification_text = initVehicle.modification_text;
      oldVehicle.modification_engine = initVehicle.modification_engine;
      oldVehicle.modification_kw = initVehicle.modification_kw;
      oldVehicle.modification_hp = initVehicle.modification_hp;
      oldVehicle.modification_ccm = initVehicle.modification_ccm;

      oldVehicle.id = initVehicle.id;
      break;

    case 'modification':
      oldVehicle.modification_key = key;
      oldVehicle.modification_text = echo(text);
      oldVehicle.modification_engine = customData.engine;
      oldVehicle.modification_kw = customData.kw;
      oldVehicle.modification_hp = customData.hp;
      oldVehicle.modification_ccm = customData.ccm;

      if (isset(id)) {
        //oldVehicle.id = isset(id) ? toInt(id) : 0;
        oldVehicle.id = id;
      } else {
        oldVehicle.id = initVehicle.id;
      }
      break;

    case 'id':
      //oldVehicle.id = isset(id) ? toInt(id) : 0;
      oldVehicle.id = isset(id) ? id : null;
      break;

    default:
      throw new Error(`VEHICLE action: Unsupported action type: ${type}`);
  }

  return oldVehicle;
}


// INFO >>>


function info(productIndex, mouseX, mouseY) {
  const oldInfo = {...initInfoIndex};

  if ( (productIndex === undefined) || (productIndex === null) ) {
    return oldInfo;
  }

  oldInfo.productIndex = productIndex;
  oldInfo.mouseX = mouseX;
  oldInfo.mouseY = mouseY;

  return oldInfo;
}



// PRODUCTS >>>


/*
* Merge product
*   -> transfer "cart status" from old one to a new one
*   -> merge types
*/
function mergeProducts(oldProduct, newProduct) {
  newProduct.cart.to = oldProduct.cart.to;
  newProduct.cart.in = oldProduct.cart.in;
  // Merge types
  for (let [type, value] of Object.entries(oldProduct.types)) {
    if ( (! (type in newProduct.types)) || ((value !== null) && (newProduct.types[type] === null)) ) {
      newProduct.types[type] = value;
    }
  }
  return newProduct;
}

/*
* Add/Replace single product (if exists replace, if not-exists add)
* input: [ array with new products ]
*/
function dispatcherProductSet(oldData, newData) {
  let exists = false;
  // Iterate all current products
  for (let idx = 0; idx < oldData.all.length; idx++) {
    if ( isSame(oldData.all[idx], newData) ) {
      oldData.all[idx] = newData;
      exists = true;
      break;  // To make it faster
    }
  }
  if (! exists) {
    oldData.all.push(newData);
  }
  return oldData.all;
}

function dispatcherProductsClear(oldData, productType) {
  // If no products
  if ( ! oldData.all ) {
    return oldData.all;
  }
  // If "type" to delete is "undefined"
  if (productType === undefined) {
    oldData.all = [];
    return oldData.all;
  }

  oldData.all = oldData.all.filter( item => ( ! (productType in item.types) ) );
  return oldData.all;
}

/*
* Merge newly loaded products to existing -> Don't remove anything!
* input: [ array with new products ]
*/
function dispatcherProductsMerge(oldData, newData) {
  // Iterate all new
  for (let idxNew = 0; idxNew < newData.length; idxNew++) {
    const newProduct = newData[idxNew];
    let exists = false;

    // Iterate all existing products
    for (let idxOld = 0; idxOld < oldData.all.length; idxOld++) {
      const oldProduct = oldData.all[idxOld];

      // If product found (exists)
      if ( isSame(oldProduct, newProduct) ) {
        // MERGE/Replace old by a new one
        oldData.all[idxOld] = mergeProducts(oldProduct, newProduct);
        exists = true;
        break;  // To make it faster
      }
    }

    if (! exists) {
      oldData.all.push(newProduct);
    }
  }
  return oldData.all;
}

// IMPORTANT!!! If product is "in cart", it can be without any type!!! -> If that product (without type) is removed from cart, remove it also from products
/*
* Replace all products of type by a new set, merge products, that are in cart
*   -> if a new product of the same type exist in previous -> merge
*   -> if a new product not exists in previous -> add
*   -> iterate all old object and remove all not existing
* input: { type:products } DON'T USE MULTIPLE TYPES!!! As {type1:products, type2:products} not implemented
*/
function dispatcherProductsReplaceType(oldData, newData) {
  let counter = 0;
  let type = undefined;
  let newProducts = undefined;
  for (let [t, prod] of Object.entries(newData)) {
    counter += 1;
    type = t;
    newProducts = prod;
  }
  if (counter !== 1) {
    throw new Error('Empty or multiple types from input');  // ERROR! Multiple types from input
  }

  /*if (newProducts.length === 0) { // DON'T USE -> IT WILL PREVENTS REMOVING ALL PRODUCTS if "newProducts" is empty
    return state;
  }*/

  let cartProducts = [];
  let oldProducts = [];

  // Iterate all existing products
  for (let idxOld = 0; idxOld < oldData.all.length; idxOld++) {
    const oldProduct = oldData.all[idxOld];

    // Iterate all new products to:
    // - update the old one = keep it (if product has different type)
    // - remove the old one (if product isn't in new products)
    let newExistsIdx = -1;
    for (let idxNew = 0; idxNew < newProducts.length; idxNew++) {
      const newProduct = newProducts[idxNew];

      if ( isSame(oldProduct, newProduct) ) {
        // Old(existing) product found in new
        newExistsIdx = idxNew;
        break;  // To make it faster
      }
    }


    // If is different type -> keep old product
    if (! (type in oldProduct.types)) {

      if (newExistsIdx !== -1) {
        // Old product is in new array -> update it
        if (oldProduct.cart.in >= 1) {
          // Move products in cart to top!
          cartProducts.push( mergeProducts(oldProduct,newProducts[newExistsIdx]) );
        } else {
          oldProducts.push( mergeProducts(oldProduct,newProducts[newExistsIdx]) );
        }

        // Remove merged product from "newProducts": All new products are also added, so removed this new product, because is already inserted to old products
        newProducts.splice(newExistsIdx, 1);

      } else {
        // Old product isn't in a new list -> keep it unchenged
        oldProducts.push(oldProduct);
      }

      continue;
    }

    // If is the same type -> remove from OLD if not exists in newProducts OR merge if exists

    // New product exists in old -> MERGE it and remove from new
    if (newExistsIdx !== -1) {
      if (oldProduct.cart.in >= 1) {
        // Move products in cart to top!
        cartProducts.push( mergeProducts(oldProduct,newProducts[newExistsIdx]) );
      } else {
        oldProducts.push( mergeProducts(oldProduct,newProducts[newExistsIdx]) );
      }

      // Remove merged product from "newProducts": To not add it to "new products" at the end, but to add to "cartProducts" or to "oldProducts"
      newProducts.splice(newExistsIdx, 1);

      continue;
    }

    // Old product not exists in new -> remove type
    delete oldProduct.types[type];

    // KEEP OLD ONLY IF: IS IN CART
    // WARN: This can cause "empty"/"no" types (if product is in cart, but current type is removed - product is no longer in currecnt type)
    if (oldProduct.cart.in >= 1) {
      cartProducts.push( oldProduct );
      continue;
    }
    // KEEP OLD ONLY IF: HAD MULTIPLE TYPES (after current type removed)
    if (Object.keys(oldProduct.types).length !== 0) {
      oldProducts.push( oldProduct );
      continue;
    }

    // Old object will be skipped here!
  }

  oldData.all = [...cartProducts, ...newProducts, ...oldProducts];
  return oldData.all;
}


function dispatcherCartAdd(oldData, newData) {
  // Can't use -> reducer isn't synchronous!!!
  /*tmp = { ...state };
  if (tmp.products.cart === undefined) {
    tmp.products.cart = [];
  }
  let found = false;
  for (let i = 0; i < tmp.products.cart; i++) {
    if (tmp.products.cart[i].id = action.payload.id) {
      found = true;
      tmp.products.cart[i] = action.payload;
    }
  }
  if (! found) {
    tmp.products.cart.push( action.payload );
  }
  return tmp;*/
}


/*
* Each dispatcher must return object {} with new data to enter into new state
*/
const dispatcher = (action, oldData, newData) => {

  switch (action) {

    case 'PRODUCT_SET':
      dispatcherProductSet(oldData, newData);
      break;

    case 'PRODUCTS_CLEAR':
      dispatcherProductsClear(oldData, newData);
      break;

    case 'PRODUCTS_MERGE':
      dispatcherProductsMerge(oldData, newData);
      break;

    //case 'CART_ADD':
      //dispatcherCartAdd(oldData, newData);
      //break;

    case 'PRODUCTS_REPLACE_TYPE':
      dispatcherProductsReplaceType(oldData, newData);
      break;


    case 'SYNC_SET':
      for (let [key, value] of Object.entries(newData)) {
        if (! (key in oldData.sync)) {
          throw new Error(`Products SYNC action: Unsupported sync type: ${key}`);
        }
        if (oldData.sync[key] === 0 && value === 1) {
          continue; // Don't use "value changed" when wasn't synced at least once (to prevent showing "Search value changed")
        }
        oldData.sync[key] = value;
      }
      break;


    case 'INFO_SET':
      oldData.infoIndex = newData;
      break;


    case 'VEHICLE_SET':
      oldData.vehicle = newData;
      break;


    case 'SEARCH_TEXT':
      oldData.search.text = newData;
      break;

    case 'SEARCH_INTERCHANGEABLE':
      oldData.search.interchangeable = newData;
      break;


    default:
      throw new Error(`PRODUCTS context: Unsupported action type: ${action.type}`);
  }
};



function productsReducer(state, action) {
  //console.log("Debug PRODUCTS state", action.type, action.payload);

  let currentState = { ...state };

  if ( action.type == 'MULTI' ) {

    action.payload.forEach( dispatch => {
      dispatcher( dispatch.type, currentState, dispatch.payload );
    })

  } else {

    dispatcher( action.type, currentState, action.payload );

  }

  //console.log( currentState );
  return currentState;
}


// Own hook for reading/writing values/states
function useProducts() {
  const context = React.useContext(ProductsContext);
  if (! context) {
    throw new Error("PRODUCTS context: useProducts() must be used within a ProductsProvider");
  }
  const [state, dispatch] = context;

  // OWN OPTIONAL DISPATCH methods here:
  const syncSet = (sync) => dispatch({ type: 'SYNC_SET', payload: sync });

  const productsClear = (productType) => dispatch({ type: 'PRODUCTS_CLEAR', payload: productType });
  const productsReplaceType = (productsInObj) => dispatch({ type: 'PRODUCTS_REPLACE_TYPE', payload: productsInObj });
  const productsMerge = (productsInArray) => dispatch({ type: 'PRODUCTS_MERGE', payload: productsInArray });
  const productSet = (product) => dispatch({ type: 'PRODUCT_SET', payload: product });

  // Modal info
  const infoClear = () => dispatch({ type: 'INFO_SET', payload: info(null) });
  const infoSet = (productIndex, mouseX, mouseY) => dispatch({ type: 'INFO_SET', payload: info(productIndex, mouseX, mouseY) });

  const vehicleClear = () => dispatch({ type: 'VEHICLE_SET', payload: vehicle('clear', state.vehicle) });
  const vehicleSet = (vehicle) => dispatch({ type: 'VEHICLE_SET', payload: vehicle });
  const vehicleSetManufacturer = (key,text) => dispatch({ type: 'VEHICLE_SET', payload: vehicle('manufacturer', state.vehicle, key, text) });
  const vehicleSetModel = (key,text,year_from,year_to) => dispatch({ type: 'VEHICLE_SET', payload: vehicle('model', state.vehicle, key, text, undefined, {year_from,year_to}) });
  const vehicleSetModification = (key,text,id,engine,kw,hp,ccm) => dispatch({ type: 'VEHICLE_SET', payload: vehicle('modification', state.vehicle, key, text, id, {engine,kw,hp,ccm}) });
  const vehicleSetId = (id) => dispatch({ type: 'VEHICLE_SET', payload: vehicle('id', state.vehicle, undefined, undefined, id) });
  // Change search input field (text/word)
  const searchSetText = (text) => dispatch({ type: 'SEARCH_TEXT', payload: text });
  const searchSetInterchangeable = (interchangeable) => dispatch({ type: 'SEARCH_INTERCHANGEABLE', payload: interchangeable });


  return {
    products : state,
    productsDispatch : dispatch,
    syncSet,
    productsClear,
    productsReplaceType,
    productsMerge,
    productSet,
    infoClear,
    infoSet,
    vehicleClear,
    vehicleSet,
    vehicleSetManufacturer,
    vehicleSetModel,
    vehicleSetModification,
    vehicleSetId,
    searchSetText,
    searchSetInterchangeable,
  }
}


// Providing context in app tree
function ProductsProvider(props) {
  const [state, dispatch] = React.useReducer(productsReducer, init);
  const value = React.useMemo(() => [state, dispatch], [state]);
  return <ProductsContext.Provider value={value} {...props} />
}


export {ProductsProvider, useProducts};
