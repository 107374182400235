import React from 'react';
import { Link } from 'react-router-dom';


const HighlightedManufacturer = ({ on, url, text, img }) => {

  return(
    <div onClick={ (e) => on(url, text) } className="highlightedmanufacturer row">
      <div className="col-3"><img src={img} className="img-fit" /></div>
      <div className="col-6"><p>{text}</p></div>
      <div className="col-3">>></div>
    </div>
  );

}

export default HighlightedManufacturer;
