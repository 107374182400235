import React from 'react';


const CareerPage = ({ props }) => {

  return (
    <>

      <h1>Kariéra</h1>
      <p>Zájemci o pozici prodejce náhradních dílů posílejte CV na <a href="mailto:prondo@prondo.cz">prondo@prondo.cz</a></p>

    </>
  );

}

export default CareerPage;
