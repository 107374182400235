import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

// Parts
import MessagesBox from 'components/parts/messagesbox';
import ProductRow from 'components/parts/productrow';
import Loader from 'components/parts/loader';
import Countdown from 'components/parts/countdown';

// Helpers
import { isset, empty, isInt, isString } from 'helpers/core';
import { fromGet } from 'helpers/url';
import { filter } from 'helpers/products';
import * as money from 'helpers/money';

// Context
import {useOrder} from 'context/orderContext';
// Data
import * as DataGateway from 'data/gatewayData';


const OrderedPage = (props) => {
  const history = useHistory();
  // Hooks
  const {order} = useOrder();

  const [paymentInfo, setPaymentInfo] = useState();
  const [gatewayUrl, setGatewayUrl] = useState(); // 0 = ERROR, but we can repeat "getUrl"; >0 = ERROR, don't repeat "getUrl"; null = we don't need to load URL (page accessed from gateway)
  const [messagesOrder, setMessagesOrder] = useState([]);     // Messages from eshop
  const [messagesGateway, setMessagesGateway] = useState([]); // Messages from gateway

  const [useRedirect, setUseRedirect] = useState(false);  // If "ordered_products" is EMPTY = eshop opened from external web/link -> don't use redirect; If "ordered_products" isn't empty -> accessed from order -> redirect
  const [redirectNow, setRedirectNow] = useState(false);

  const ordered_products = order.ordered.products;


  /* Payment steps
   * 1) show info from e-shop
   * 2) load URL for payment
   * 3) after paid, returned from gw -> knowing just payment ID
   * 4) load info about payment
   */
  useEffect( () => {
    const fetchData = async () => {
      const url = fromGet(props);

      //let info = undefined;
      if ( isset(url.params.payment) ) {
        await initFromEshop( url.params.payment );  // Page ACCESSED from eshop
      }
      else if ( isset(url.params.platba) ) {
        await initFromEshop( url.params.platba );   // Page ACCESSED from eshop
      }
      else if ( isset(url.params.id) ) {
        await initFromGateway( url.params.id );     // Page ACCESSED from gateway
      }
      else {
        // Page ACCESSED from unknown source or URL parameter is needed, but isn't set (= Invalid page ... URL without "payment" parameter)
        const info = intoPaymentInfo();
        info.accessedFrom = 0;
        //info.valid = false;
        info.status = undefined;
        info.message_level = 1;
        info.message_text = "Před vstupem na tuto stránku musí proběhnout objednávka.";
        info.text_line1 = "Není možné zobrazit stav objednávky.";
        setPaymentInfo( info );

        if ( (info.message_level !== null) && (info.message_text !== null) ) {
          setMessagesOrder([ { type:info.message_level, text:info.message_text } ]);
        }

        return;
      }

      //productsClear("cart");
    }

    fetchData();
  }, [ window.location.href ] );

  /*useEffect( () => {
    if ( redirectNow && isString(gatewayUrl) && (! (paymentInfo.status < 0)) ) {
      window.location.href = gatewayUrl;
    }
  }, [gatewayUrl, redirectNow] );*/
  useEffect( () => {
    if ( isString(gatewayUrl) && useRedirect ) {
      window.location.href = gatewayUrl;
    }
  }, [gatewayUrl] );


  const intoPaymentInfo = () => {
    return {
      accessedFrom: undefined,  // 0 = from unknown source; 1 = from eshop; 2 = from gateway
      //valid: false,
      status: undefined,  // undefined = unknown state; null = payment info not set; a-z = don't use gateway (different payment used); 1+ = order ID (redirect to gateway); 0 = paid (when accessed from gateway); -1- = error from gateway (when accessed from gateway)
      message_level: null,
      message_text: null,
      text_line1: null,
      orderId: null,
      loadGateway: false,
      // Info from GATEWAY only
      code1: null,
      code2: null,
    };
  }

  const initFromEshop = async (paymentType) => {
    // Set payment info
    let info = intoPaymentInfo();
    info.accessedFrom = 1;
    if (
      paymentType == "a" || paymentType == "b" || paymentType == "c"
    ) { // a = 4 = Use cash/card (on branch office); b = 1 = Use bank account; c = 3 = Use ondelivery
      //info.valid = true;
      info.status = paymentType;  // Order OK, Payment OK / OR / DON'T use gateway
      info.message_level = 0;
      info.message_text = "Objednávka vytvořena.";
      info.text_line1 = "Děkujeme, informace o objednávce obdržíte emailem.";
    }
    else if ( paymentType > 0 ) { // 2 = Card online / Use gateway -> redirect to gateway
      //info.valid = true;
      info.status = paymentType;  // Order OK, redirect to gateway
      info.message_level = 4;
      info.message_text = "Objednávka vytvořena.";
      info.orderId = paymentType;
      info.loadGateway = true;
    }
    /*else if ( paymentType == "" || paymentType == 0 || paymentType == null || paymentType == undefined ) {  // Invalid URL "payment" parameter
      info.status = null; // Order OK, Payment unknown / Gateway ERROR
      info.message_level = 4;
      info.message_text = "Objednávka vytvořena, ale nepodařilo se získat informace o platbě.";
      info.text_line1 = "Prosím kontaktujte nás a sdělte, jak si přejete objednávku uhradit.";
    }*/
    else {  // paymentType < 0 || paymentType == "d-z": Unable to get payment method
      info.status = null;
      info.message_level = 1;
      info.message_text = "Chybný odkaz.";
      info.text_line1 = "Prosím kontaktujte nás a sdělte, jak si přejete objednávku uhradit.";
      //info.loadGateway = true;
      //info.code1 = -1;
      //info.code2 = -1;
    }

    setPaymentInfo( info );

    // Set messages
    if ( (info.message_level !== null) && (info.message_text !== null) ) {
      setMessagesOrder([ { type:info.message_level, text:info.message_text } ]);
    }

    // Get gateway URL (if selected to pay by card)
    if ( info.loadGateway ) {
      const url = await gatewayLoadUrl( info.orderId );
      setUseRedirect( (! empty(ordered_products)) && isString(url) && (info.status > 0) );
    }
  }

  const retryGetPaymentUrl = async () => {
    const url = await gatewayLoadUrl( paymentInfo.orderId );
  }

  const initFromGateway = async (paymentId) => {
    setPaymentInfo( null );
    const loaded = await gatewayLoadStatus( paymentId );
    
    const info = intoPaymentInfo();
    info.accessedFrom = 2;

    if ( loaded === undefined || loaded === null ) {
      info.status = null;
      info.text_line1 = "Stav platby neověřen, akci můžete opakovat.";
    }
    else if ( isInt(loaded) ) {
      info.status = Math.abs( loaded );
      /*if ( loaded === 4000 ) {
        info.text_line1 = "Stav platby neověřen, akci můžete opakovat.";
      } else {*/
        info.text_line1 = "Tato platba nelze ověřit.";
      //}
    }
    else {
      info.status = (loaded.data.paid !== null || loaded.data.gateway.status === 0 || loaded.data.gateway.status === 2) ? 0 : Math.abs(loaded.data.gateway.status) * -1;
      //info.valid = true;
      if ( info.status === 0 ) {
        info.message_level = 0;
        info.message_text = "Objednávka byla uhrazena.";
        info.text_line1 = "Děkujeme, informace o objednávce obdržíte emailem.";
      } else {
        // Check Gateway errors
        info.message_level = 4;
        info.message_text = "Proběhlo ověření platby.";
        info.text_line1 = "Objednávka prozatím nebyla uhrazena.";
        info.orderId = loaded.id;
        info.loadGateway = true;
        info.code1 = loaded.data.gateway.status;
        info.code2 = loaded.data.gateway.code;
      }
    }

    setPaymentInfo( info );

    // Set messages
    if ( (info.message_level !== null) && (info.message_text !== null) ) {
      setMessagesGateway([ { type:info.message_level, text:info.message_text } ]);
    }
  }

  const goToPayment = async () => {
    if ( empty(paymentInfo) || ! isInt(paymentInfo.orderId) ) {
      setMessagesGateway([ { type:1, text:"Nepodařilo se získat informace o objednávce. Prosím, kontaktujte nás." } ]);
      return;
    }
    if ( window.location.pathname === "/objednano" ) {  // TODO: Make a function in 'helpers/url'
      history.push( "/objednano?platba=" + paymentInfo.orderId );
    } else {
      history.push( "/ordered?payment=" + paymentInfo.orderId );
    }
  }

  const retryGetPaymentStatus = async () => {
    const url = fromGet(props);
    if ( ! isset(url.params.id) ) {
      setMessagesOrder([ { type:3, text:"Objednávka vytvořena, ale nepodařilo se získat informace o platbě." } ]);
    }
    await initFromGateway( url.params.id );
  }


  // GET
  // Set: setMessagesGateway; setGatewayUrl
  const gatewayLoadUrl = async (orderId) => {
    setMessagesGateway(undefined);
    setGatewayUrl(undefined);
    try {
      const response = await DataGateway.paymentUrl(orderId);

      if ( response === null ) {
        // If no response from server
        setMessagesGateway([ { type:1, text:"Nepodařilo se spojit se serverem, zkuste akci opakova." } ]);
        setGatewayUrl(0);
        return undefined;
      }
      else if ( response.status !== 0 ) {
        if ( response.status === 6404 ) {
          setMessagesGateway([ { type:1, text:"Chybné číslo platby. Tuto objednávku nelze uhradit kartou. Kontaktujte nás." } ]);
        }
        else if ( response.status === 5101 ) {
          setMessagesGateway([ { type:3, text:"Platbu kartou přerušena, protože u objednávky je zvolen jiný druh platby. Pro řešení nás kontaktujte a nahlaste kód: \""+ error_order(orderId,response.status) +"\"." } ]);
        }
        else if ( response.status === 1 ) {
          setMessagesGateway([ { type:0, text:"Objednávka již byla uhrazena." } ]);
        }
        else if ( response.status < 0 ) {
          setMessagesGateway([ { type:1, text:"Z platební brány se nepodařilo získat potřebné informace." } ]);
        }
        else {
          setMessagesGateway([ { type:1, text:"Nespecifikovaná chyba." } ]);
        }
        // Set URL
        setGatewayUrl( response.status );
        return response.status;
      }

      // Set URL
      const url = response.data.gateway.url;
      setGatewayUrl(url);
      return url;

    } catch(e) {
      setMessagesGateway([ { type:1, text:"Nepodařilo se kontaktovat server. Zkuste to za chvíli." } ]);
      console.log( e );
      setGatewayUrl(0);
      return null;
    }
  };

  // GET
  /* Set: setMessagesGateway
   *  @return:
   *    undefined = no response from server
   *    null = exception from connection
   *    int = error from gateway
   *    object = OK, data
   *      {
   *        paid: null = not paid; date = paid
   *        gateway.status: 0/2 = paid; int = status
   *        gateway.status_text: text for status
   *        gateway.code: null = not set; int = code from gateway
   *        gateway.code_text: null = not set; string = text for code
   *      }
   */
  const gatewayLoadStatus = async (id) => {
    setMessagesGateway(undefined);
    //setGatewayUrl(null);
    try {
      const response = await DataGateway.gatewayStatus(id);
      if ( response === null ) {
        // If no response from server
        setMessagesGateway([ { type:1, text:"Nepodařilo se spojit se serverem, zkuste akci opakova." } ]);
        return undefined;
      }
      else if ( response.status !== 0 ) {
        if ( response.status === 6402 || response.status === 5001 ) {
          setMessagesGateway([ { type:1, text:"Chybné číslo platby. Tuto platbu nelze provést. Kontaktujte nás." } ]);
        }
        else if ( response.status === 4000 ) {
          setMessagesGateway([ { type:1, text:"Brána nevrátila informace o platbě. Kontaktujte nás." } ]);
        }
        else if ( response.status === 6404 ) {
          setMessagesGateway([ { type:1, text:"Objednávka spojená s touto platbou neexistuje. Kontaktujte nás." } ]);
        }
        else if ( response.status === 5101 || response.status === 1 ) {
          setMessagesGateway([ { type:3, text:"Platbu kartou přerušena, protože u objednávky je zvolen jiný druh platby. Pro změnu nás kontaktujte a nahlaste kód: \""+ error_order(id,response.status) +"\"." } ]);
        }
        else {
          setMessagesGateway([ { type:1, text:"Nespecifikovaná chyba." } ]);
        }
        return response.status;
      }

      return response;

    } catch(e) {
      setMessagesGateway([ { type:1, text:"Nepodařilo se kontaktovat server. Zkuste to za chvíli." } ]);
      console.log( e );
      return null;
    }
  };


  const error_order = (orderNumber, code) => {
    let out = "";
    if ( isset(orderNumber) ) {
      out += Math.abs(orderNumber);
    }
    if ( isset(code) ) {
      out += ( "E" + Math.abs(code) );
    }
    return out;
  }


  const error_gateway = (code1, code2, orderNumber) => {
    let out = "";
    if ( isset(orderNumber) ) {
      out += Math.abs(orderNumber);
    }
    if ( isset(code1) ) {
      out += ( "P" + Math.abs(code1) );
    }
    if ( isset(code2) ) {
      out += ( "S" + Math.abs(code2) );
    }
    return out;
  }



  // RENDER >>>

  const renderNumberError = (show) => {
    if (! show) {
      return null;
    }

    if (paymentInfo.status < 0) {
      show = error_gateway(paymentInfo.code1, paymentInfo.code2, paymentInfo.orderId);
    }
    else if ( isInt(gatewayUrl) ) {
      show = error_order(paymentInfo.orderId, gatewayUrl);
    }
    else {
      show = Math.abs(paymentInfo.orderId) + "E0";
    }

    return (
      <div className="h-center">
        <p>Pokud se Vám opakovaně nedaří realizovat platbu, rádi Vám pomůžeme.<br />
          <b>Nahlaste nám číslo chyby: {show}</b></p>
      </div>
    );
  }



  if ( paymentInfo === undefined ) {
    // ORDER Not initialized

    return (
      <Loader type="page" text="Připravujeme objednávku ..." />
    )

  }
  else if ( paymentInfo === null ) {
    // PAYMENT INFO Must be loaded from gateway

    return (
      <Loader type="page" text="Načítá se stav platby ..." />
    )

  }
  else if ( paymentInfo.accessedFrom === 1 ) {
    // Acessed from ESHOP

    const renderPaymentInfo = (show) => {
      if (! show) {
        return null;
      }
      else if ( order.payment.type === 1 ) {  // Use bank account
        return (
          <div className="">
            <h2>Platba</h2>
            <p>Platbu proveďte předem převodem na náš bankovní účet.</p>
            <ul className="list">
              <li>Číslo účtu: {order.payment.bank_account}</li>
              <li>Variabilní symbol: {order.payment.bank_vs}</li>
              <li>Částka k úhradě: { money.formatCzk(order.payment.pay) } Kč</li>
            </ul>
            <p>Informace o platbě naleznete také v e-mailu.</p>
          </div>
        );
      }
      else if ( order.payment.type === 3 ) {  // Use ondelivery
        return (
          <div className="">
            <h2>Platba</h2>
            <p>Platbu proveďte dobírkou při převzetí zásilky.</p>
          </div>
        );
      }
      else if ( order.payment.type === 4 ) {  // Use cash/card (on branch office)
        return (
          <div className="">
            <h2>Platba</h2>
            <p>Objednávku uhradíte hotově nebo kartou při převzetí na naší pobočce.</p>
          </div>
        );
      }
      else {
        return null;
      }
    }

    return (

      <div className="row-cols nobg">
        <main className="col-middle">
          <h1>Objednávka</h1>
          <MessagesBox messages={messagesOrder} />
          <MessagesBox messages={messagesGateway} />
  
          <div className="txt-banner">
            <div>
              {
                (paymentInfo.status === null) // Payment method not set
                ?
                  <>
                    <p>{paymentInfo.text_line1}</p>
                    <span>Pokračujte na <Link to="/">Domovskou stránku</Link>.</span>
                  </>
                :

                /*( paymentInfo.status < 0 )  // = ERROR from gateway
                ?
                  isString(gatewayUrl)  // -> retry allowed
                  ? // Ready
                    <>
                      <p>{ paymentInfo.text_line1 }</p>
                      <span><a href={gatewayUrl}>Přejít na platební bránu.</a></span>
                    </>
                  : // Preparing ...
                    <>
                      <p>{ paymentInfo.text_line1 }</p>
                      <span><Loader text="Generuji odkaz na platební bránu..." /></span>
                    </>
                :*/

                (gatewayUrl === 0)  // Payment error -> repeat
                ?
                  <>
                    <p>Platba kartou se nezdařila.</p>
                    <span>
                      <button type="button" onClick={retryGetPaymentUrl}>Opakujte platbu</button>
                    </span>
                  </>
                :

                isInt(gatewayUrl) // Payment error -> don't repeat!
                ?
                  <>
                    <p>Platba kartou přerušena.</p>
                    <span>Pokračujte na <Link to="/">Domovskou stránku</Link>.</span>
                  </>
                :

                ( // After INIT
                  paymentInfo.status === "a" || paymentInfo.status === "b" || paymentInfo.status === "c"  // = different payment method then by card
                  || paymentInfo.status === 0 // = already paid
                )
                ?
                  <>
                    <p>{ paymentInfo.text_line1 }</p>
                    <span>Pokračujte na <Link to="/">Domovskou stránku</Link>.</span>
                  </>
                :

                ( paymentInfo.status > 0 )  // = paymentID -> redirect to gateway
                ?
                  (useRedirect)
                  ? // Redirect to gateway?
                    ( redirectNow && isString(gatewayUrl) )
                    ? // Ready
                      <>
                        <p>Přesměrování na platební bránu.</p>
                        <span>Neproběhlo-li přesměrování, klikněte <a href={gatewayUrl}>zde</a></span>
                      </>
                    : // Preparing ...
                      <>
                        <p>Přesměrování na platební bránu.</p>
                        <span><Countdown fce={ () => setRedirectNow(true) } second={4} text_inProgress=" probíhá..." text_finished=" čeká se na odpověď od platební brány..." loader_afterFinished={true} /></span>
                      </>
                  : // Access gateway manually
                    ( isString(gatewayUrl) )
                    ? // Ready
                      <>
                        <p>Přístup na platební bránu.</p>
                        <span><a href={gatewayUrl}>Přejít na platební bránu.</a></span>
                      </>
                    : // Preparing ...
                      <>
                        <p>Přístup na platební bránu.</p>
                        <span><Loader text="Generuji odkaz..." /></span>
                      </>
                
                : // Payment state unknown -> don't repeat!
                  <>
                    <p>Není možné zobrazit stav objednávky.</p>
                    <span>Pokračujte na <Link to="/">Domovskou stránku</Link>.</span>
                  </>                
              }
            </div>
          </div>
  
          {
            renderNumberError( /*paymentInfo.status < 0*/ isInt(gatewayUrl) && (gatewayUrl !== 1) )
          }
          {
            renderPaymentInfo( true )
          }
  
          {
            ( ! empty(ordered_products) ) &&
            <>
              <h2>Produkty z objednávky</h2>
              <div id="products">
                {
                  ordered_products.map( (item,idx) =>
                    <ProductRow key={idx} product={item} showFlags={true} showDetail={true} showCart={false} showStock={false} showPrice={true} showRemove={false} showInfo={false} />
                  )
                }
              </div>
            </>
          }
  
        </main>
      </div>
  
    );

  }
  else if ( paymentInfo.accessedFrom === 2 ) {
    // Acessed from GATEWAY

    return (
      <div className="row-cols nobg">
        <main className="col-middle">
          <h1>Platba</h1>
  
          <MessagesBox messages={messagesGateway} />
  
          <div className="txt-banner">
            <div>
              {
                (paymentInfo.status === 0)  // Checked: Paid
                ?
                  <>
                    <p>{ paymentInfo.text_line1 }</p>
                    <span>Pokračujte na <Link to="/">Domovskou stránku</Link>.</span>
                  </>
                :

                (paymentInfo.status === null || paymentInfo.status > 0) // ERROR from gateway
                ? // Payment error -> repeat
                  <>
                    <p>Nezdařilo se získat informace o úhradě.</p>
                    <span>
                      <button type="button" onClick={retryGetPaymentStatus}>Opakovat dotaz</button>
                    </span>
                  </>
                :

                ( paymentInfo.status < 0 )  // Checked: NOTPaid
                ?
                  <>
                    <p>{ paymentInfo.text_line1 }</p>
                    <span>
                      <button type="button" onClick={goToPayment}>Přejít na platbu</button>
                    </span>
                  </>
                
                : // Payment error -> don't repeat!
                  <>
                    <p>Není možné zobrazit stav platby.</p>
                    <span>Pokračujte na <Link to="/">Domovskou stránku</Link>.</span>
                  </>
              }
            </div>
          </div>
  
          {
            renderNumberError( paymentInfo.status !== 0 )
          }
        </main>
      </div>
    );

  }
  else {

    return (
      <div className="row-cols nobg">
        <main className="col-middle">
          <h1>Objednávka</h1>
  
          <MessagesBox messages={messagesOrder} />
  
          <div className="txt-banner">
            <div>
              <p>{ paymentInfo.text_line1 }</p>
              <span>Pokračujte na <Link to="/">Domovskou stránku</Link>.</span>
            </div>
          </div>
        </main>
      </div>
    );

  }

};

export default OrderedPage;
