import React from 'react';
import { Link } from 'react-router-dom';

// Images
import img_logo from 'assets/images/logo.png';


const Logo = ({ props }) => {

  return(
    <div className="logo">
      <Link to="/">
        <img src={img_logo} alt="Prondo logo" />
      </Link>
    </div>
  );

}

export default Logo;
