import { useState } from 'react';

// Forms
import {setState, Check, Textarea, Input} from 'components/form';

// Parts
import MessagesBox from 'components/parts/messagesbox';
import Loader from 'components/parts/loader';

// Data
import * as DemandData from 'data/demandData';


const initData = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  message: "",
  vin: "",
  model: "",
  year: "",
  engine: "",
  terms: null,
}


const FormDemand = ({ product }) => {
  // Hooks
  const [data, setData] = useState( {...initData} );
  const [demandStatus, setDemandStatus] = useState([]);


  const processDemandAnswer = (answer) => {
    // If demand is undefined -> remove all messages
    if (answer === undefined || answer.data === undefined || answer.data.demand === undefined) {
      setDemandStatus([]);
      return;
    }

    //window.scrollTo(0, 0);  // Scroll to top

    // If connection error
    if (answer === null || answer.data.demand === null) {
      setDemandStatus([ {type:2, text: "Nepodařilo se odeslat poptávku na server. Zkuste to znovu nebo nás kontaktujte jinak."} ]);
      return;
    }

    // Some errors -> don't submited
    if (answer.status != 0) {
      setDemandStatus(answer.messages);
      return;
    }

    // Demand created
    setDemandStatus( [ {type:0, text: "Děkujeme za Vaši poptávku, brzy Vás budeme kontaktovat."} ] );
    setData( {...initData} );
  }


  // POST

  const demandInsert = async (demand, productId, productSlug) => {
    try {
      const demandData = await DemandData.insertDemand(demand, productId, productSlug);
      processDemandAnswer(demandData);
      return demandData.demand;
    } catch (ex) {
      processDemandAnswer(null);
      return null;
    }
  }


  const placeDemand = async (e) => {
    e.preventDefault();
    setDemandStatus(undefined);

    const out = { ...data };

    if (product) {
      const demand = await demandInsert( out, product.id, product.slug );
    } else {
      const demand = await demandInsert( out );
    }

  }


  // RENDER >>>


  return (
    <div className="form-demand">

      <MessagesBox messages={demandStatus} />

      <form onSubmit={(e) => (e.preventDefault()) } className="form-row form-3">

        <div className="form-col">
          <Input labelText="Jméno" name="firstname" value={data["firstname"]} onChange={(e) => setState(e,data,setData)} />
          <Input labelText="Příjmení" name="lastname" value={data["lastname"]} onChange={(e) => setState(e,data,setData)} />
          <Input labelText="E-mail" name="email" value={data["email"]} onChange={(e) => setState(e,data,setData)} />
          <Input labelText="Telefon" name="phone" value={data["phone"]} onChange={(e) => setState(e,data,setData)} />
        </div>

        <div className="form-col">
          <Input labelText="VIN" name="vin" value={data["vin"]} onChange={(e) => setState(e,data,setData)} />
          <Input labelText="Model" name="model" value={data["model"]} onChange={(e) => setState(e,data,setData)} />
          <Input labelText="Rok výroby" name="year" value={data["year"]} onChange={(e) => setState(e,data,setData)} />
          <Input labelText="Motor" name="engine" value={data["engine"]} onChange={(e) => setState(e,data,setData)} />
        </div>

        <div className="form-col">
          <div className="form-row">
            <Textarea rows={2} name="message" labelText="Vaše zpráva" value={data["message"]} onChange={(e) => setState(e,data,setData)} />
          </div>
          <div className="form-row">
              <Check name="terms" labelText="Souhlasím se zpracováním osobních údajů" value={data["terms"]} onChange={(e) => setState(e,data,setData,1)} />
            <button onClick={placeDemand} disabled={(demandStatus === undefined)}>{ (demandStatus === undefined) ? <Loader /> : "Poptat" }</button>
          </div>
        </div>

      </form>

    </div>
  );
}

export default FormDemand;
