import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// Forms
import FormLogin from 'components/forms/login';
import FormRegister from 'components/forms/register';
import FormPasswordReset from 'components/forms/password-reset';

// Context
import {useGlobal} from 'context/globalContext';
import {useProducts} from 'context/productsContext';


const UserOverlay = () => {
  // Hooks
  const {global, setOverlay} = useGlobal();
  const [tab, setTab] = useState(0);

  // Handle

  const handleCloseOverlay = () => {
    setOverlay(null);
  }

  const handleTableTab = (newTab) => {
    if ( tab === newTab ) {
      return;
    }
    setTab(newTab);
  }


  // RENDER >>>


  if (global.overlay !== "login" && global.overlay !== "register" && global.overlay !== "password")
  {
    return null;
  }



  // User login

  return (
    <section id="overlay-user" className="popup overlay">

      <div className="close">
        <span onClick={handleCloseOverlay} className="btn-icon icon is-times"></span>
      </div>

      <div className="table">
        <ul className="table-tabs">
          <li onClick={() => handleTableTab(0)} className={ (tab === 0) ? "active" : "" }>Přihlásit</li>
          <li onClick={() => handleTableTab(1)} className={ (tab === 1) ? "active" : "" }>Vytvořit účet</li>
        </ul>

        <hr />

        <h1>
          {
            (tab === 0) ? "Přihlaste se"
            : (tab === 1) ? "Registrace"
            : (tab === 2) ? "Obnova hesla"
            : ""
          }
        </h1>

        {
          (tab === 0) ?
            <div className="tab-login">
              <FormLogin />
              <div>
                <p><Link onClick={() => handleTableTab(2)}>Zapomněli jste heslo?</Link></p>
              </div>
            </div>
          :
          (tab === 1) ?
            <div className="tab-registration">
              <FormRegister />
            </div>
          :
          (tab === 2) ?
          <div className="tab-password">
            <FormPasswordReset />
            <div>
              <p><Link onClick={() => handleTableTab(0)}>Zpět na přihlášení</Link></p>
            </div>
          </div>

          : <></>
        }
      </div>

    </section>
  );

};

export default UserOverlay;
