import React from 'react';
import { Link } from 'react-router-dom';

// Parts
import Loader from 'components/parts/loader';

// Context
import {useGlobal} from 'context/globalContext.js';


const Breadcrumb = ({ props }) => {

  const {global} = useGlobal();


  if (global.navigation === null) {
    return null;
    //return <section id="breadcrumb"></section>;
  }

  if (global.navigation === undefined) {
    return ( <Loader className="h-left" type="block" text="Načítám ..." /> );
  }


  return (
    <section id="breadcrumb">
      {
        global.navigation.map( (item,idx) =>

          ( (idx+1) === global.navigation.length ) // Last item
          ?
            <div key={idx} className="active">{item.name[1]}</div>
          :
            [
              <Link key={idx+"a"} to={item.urn[1]}>{item.name[1]}</Link>
              //, ' > '
              , <span key={idx+"b"}>&#187;</span>
            ]

        )
      }
    </section>
  );

}

export default Breadcrumb;
