import React from 'react';

// Forms
import {setState, Check, Textarea} from 'components/form';


const PartialOrderOthers = ({ completeName, notesName, data, setData }) => {

  return (
    <div className="form-partial form-partial-order-others form-col">

      <Check name={completeName} labelText="Zaslat pouze kompletní objednávku" value={data[completeName]} onChange={(e) => setState(e,data,setData,1)} />

      <div className="form-col">
        <Textarea rows={4} name={notesName} labelText="Poznámky" value={data[notesName]} onChange={(e) => setState(e,data,setData)} />
      </div>

    </div>
  );
}

export default PartialOrderOthers;
