// Helpers
import {isset} from 'helpers/core';
import * as money from 'helpers/money';


export function isSame(oldProduct, newProduct) {
  if ( !isset(oldProduct) || !isset(newProduct) ) {
    return false;
  }

  return (
    (oldProduct.id == newProduct.id)
     && (
      (oldProduct.id != 0)  // Local product
      || (oldProduct.slug == newProduct.slug) // Remote product
    )
  );
}


export function orderByBackend(products) {
  if ( ! Array.isArray(products) ) {
    return products;
  }

  let outToSort = [];
  let outNoSort = [];

  products.forEach( product => {
    if ( isset(product) && isset(product.search) && isset(product.search.order) ) {
      outToSort.push( product );
    } else {
      outNoSort.push( product );
    }
  } );

  outToSort.sort( (a, b) =>
    (
      isset(a) && isset(a.search) && isset(a.search.order)
      && isset(b) && isset(b.search) && isset(b.search.order)
    )
    ?
      a.search.order - b.search.order
    :
      0
  );

  return outToSort.concat(outNoSort);
}


function compare(a, b) {
  if (a == b) return 0;
  if (a < b) return 1;
  if (a > b) return -1;
}
/*
  COMPARING:  isLocalStock  = true/false
              onStock       = true(=onStock/infinite/moreThen)/onDemand/false
              price         = valid/onDemand
  Importance:
    8)  isLocalStock = true
        onStock = true
        price = valid
    7)  isLocalStock = false
        onStock = true
        price = valid
    6)  isLocalStock = true
        onStock = unknown but allow order
        price = valid
    5)  isLocalStock = false
        onStock = unknown but allow order
        price = valid
    4)  isLocalStock = true
        onStock OR price = onDemand
    3)  isLocalStock = true
        onStock OR price = onDemand
    2)  isLocalStock = true
        onStock = false
    1)  isLocalStock = false
        onStock = false
*/
function compareValidPrice(price) {
  if (price === undefined || price === null || isNaN(price)) return false;
  return (price > 0.0);
}
function compareStockAndPrice(product) {
  const isLocalStock = product.stock.local;
  const stock = product.stock.stock;
  const stockMin = (product.order !== undefined && product.order.together !== undefined && product.order !== null && product.order.together !== null) ? product.order.together : 1;
  const validPrice = compareValidPrice(product.price.price);
  const validStock = (stock !== undefined && stock !== null) && (! isNaN(stock));

  if ( ! validStock || ! validPrice ) {
    return 1; // On demand
  }
  if (stock == -1) return isLocalStock ? 6 : 5; // Is: unknown(infinite) but allow order
  if ( Math.abs(stock) < stockMin ) return 3; // Is: outOfStock
  return isLocalStock ? 8 : 7;  // Is: onStock(stock > 0) / moreThen(stock < -1)
  //return 1;  OR in case of error
}

export function orderByStockAndPrice(products) {
  if ( ! Array.isArray(products) ) {
    return products;
  }

  // https://stackoverflow.com/questions/6913512/how-to-sort-an-array-of-objects-by-multiple-fields
  return [...products].sort(
    function(a, b) {
      // Compare by stock and price
      const byStockAndPrice = compare( compareStockAndPrice(a), compareStockAndPrice(b) );
      if ( byStockAndPrice !== 0 ) {
        return byStockAndPrice;
      }
      // Compare by title
      return a.text.title > b.text.title ? 1 : -1;
    }
  );
}


export function filter(allProducts, type) {
  switch (type) {
    case "cart":
      return filter_cart(allProducts);
  
    default:
      return null;
  }
}

function filter_cart(allProducts) {
  const out = {
    products : [],
    sum : {
      productsNoTax : 0,
      productsTax : 0,
      feesNoTax : {},
      feesTax : {}
    },
    transport : true
  };

  // Iterate ALL products
  for (let idx in allProducts) {
    const product = allProducts[idx];
    if (product.cart.in <= 0) {
      continue;
    }
    out.products.push(product);
    // Product prices
    out.sum.productsNoTax += ( product.cart.in * money.priceFromTax(product.price.price, product.price.tax) );
    out.sum.productsTax += ( product.cart.in * product.price.price );
    // Fees
    if ( product.price.fee ) {
      for ( let [key, value] of Object.entries(product.price.fee) ) {
        // For NON-VAT
        if ( ! (key in out.sum.feesNoTax) ) {
          out.sum.feesNoTax[key] = 0.0;
        }
        out.sum.feesNoTax[key] += ( product.cart.in * money.priceFromTax(value, product.price.tax) );
        // For VAT
        if ( ! (key in out.sum.feesTax) ) {
          out.sum.feesTax[key] = 0.0;
        }
        out.sum.feesTax[key] += ( product.cart.in * value );
      }
    }
    // Transport allowed?
    if ( ! product.order.transport ) {
      out.transport = false;
    }
  }

  return out;
}
