import { useState } from 'react';

// Forms
import {setState, Check, Textarea, Input} from 'components/form';

// Parts
import Loader from 'components/parts/loader';
import MessagesBox from 'components/parts/messagesbox';

// Context
import {useGlobal} from 'context/globalContext.js';
import {useAuth} from 'context/authContext.js';
import {useProducts} from 'context/productsContext';

// Data
import * as UserData from 'data/userData';


const initUserData = {
  email: "",
  password: "",
  password2: "",
  firstname: "",
  lastname: "",
}

const initCompanyData = {
  company: "",
  bin: "",
  tin: "",
  address_street: "",
  address_city: "",
  address_zip: "",
  address_state: "",
}


const FormRegister = ({ product }) => {
  // Hooks
  const {messagesAdd, messagesSet, setOverlay} = useGlobal();
  const {auth, userSet} = useAuth();

  const [userData, setUserData] = useState(initUserData);
  const [companyData, setCompanyData] = useState(null);
  const [registerMessages, setRegisterMessages] = useState([]);


  const register = async (e) => {
    e.preventDefault();
    //window.scrollTo(0, 0);  // Scroll to top
    //userSet(null);
    setUser(null);
    setRegisterMessages(undefined);

    //const outUser = { ...userData };
    //const outCompany = { ...companyData };
    //const input = await registerPost({ user: outUser, company: outCompany });
    const input = await registerPost({ user:userData, company:companyData });

    if (input === null || input.status === undefined || input.messages === undefined) {
      //setRegisterMessages([]);
      setRegisterMessages([ {type:1, text: "Nepodařilo se spojit se serverem. Zkuste to později znovu nebo nás kontaktujte."} ]);
      return;
    }

    if (input.messages.length !== 0) {
      setRegisterMessages(input.messages);
    }
    if (input.status != 0) {
      return;
    }

    // User set
    setUser(input.account.user, [ {type:0, text: "Registrace úspěšná."} ], true);

    setUserData({ ...initUserData });
    setCompanyData({ ...initCompanyData });
  }


  const setUser = (user, message = undefined, closeOverlay = false) => {
    // Set user
    if (user === undefined || user === null) {
      userSet(null);
    } else {
      userSet(user);
    }
    // Set message
    if (message !== undefined) {
      closeOverlay ? messagesSet(message) : setRegisterMessages(message);
    }
    // Close
    if (closeOverlay) {
      setOverlay(null);
    }
  }


  // POST

  const registerPost = async (data) => {
    try {
      return await UserData.accountPost(data);
    } catch (ex) {
      return null;
    }
  }


  // Handle
  const handleToogleCompany = () => { // TODO: Not used
    setCompanyData( (companyData === null) ? companyData : null );
  }


  // RENDER >>>


  return (
    <div className="form-login">

      <MessagesBox messages={registerMessages} />

      <form onSubmit={(e) => (e.preventDefault()) } className="form-row form-3">

        <div className="form-col">
          <Input labelText="Křestní jméno" name="firstname" value={userData["firstname"]} onChange={(e) => setState(e,userData,setUserData)} />
          <Input labelText="Příjmení" name="lastname" value={userData["lastname"]} onChange={(e) => setState(e,userData,setUserData)} />
          <Input labelText="E-mail" name="email" value={userData["email"]} onChange={(e) => setState(e,userData,setUserData)} />
          <Input type="password" labelText="Heslo" name="password" value={userData["password"]} onChange={(e) => setState(e,userData,setUserData)} />
          <Input type="password" labelText="Zopakujte heslo" name="password2" value={userData["password2"]} onChange={(e) => setState(e,userData,setUserData)} />
        </div>

        <div className="form-col">
          <Check labelText="Registrovat jako firmu" name="show-company" value={(companyData === null) ? null : 1} onChange={(e) => setCompanyData((companyData === null) ? initCompanyData : null)} />
          {
            (companyData !== null) &&
            <>
              <Input labelText="Firma" name="company" value={companyData["company"]} onChange={(e) => setState(e,companyData,setCompanyData)} />
              <Input labelText="IČO" name="bin" value={companyData["bin"]} onChange={(e) => setState(e,companyData,setCompanyData)} />
              <Input labelText="DIČ" name="tin" value={companyData["tin"]} onChange={(e) => setState(e,companyData,setCompanyData)} />
            </>
          }
        </div>

        <div className="form-col">
          <button onClick={register} disabled={(registerMessages === undefined)}>
            {
              (registerMessages === undefined)
              ?
                <Loader />
              :
                "Registrovat se"
            }
          </button>
        </div>

      </form>

    </div>
  );
}

export default FormRegister;
