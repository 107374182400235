import { useState, useEffect } from 'react';

// Forms
import {setState, Input, Check} from 'components/form';
import PartialAddressInvoice from 'components/forms/_partials/address-invoice';
import PartialAddressDelivery from 'components/forms/_partials/address-delivery';

// Parts
import Loader from 'components/parts/loader';
import MessagesBox from 'components/parts/messagesbox';

// Entities
import * as AccountE from 'entities/account';
import * as AddressE from 'entities/address';
import * as UseE from 'entities/use';
// Context
import {useGlobal} from 'context/globalContext';
import {useAuth} from 'context/authContext.js';
// Data
import * as UserData from 'data/userData';


const AccountPage = (props) => {
  // Hooks
  const {messagesAdd} = useGlobal();

  const [dataUse, setDataUse] = useState( {...UseE.useAccount} );
  const [dataPassword, setDataPassword] = useState( {...AccountE.password} );
  const [dataUser, setDataUser] = useState( {...AccountE.user} );
  const [dataInvoicing, setDataInvoicing] = useState( {...AddressE.address} );
  const [dataShipping, setDataShipping] = useState( {...AddressE.address} );

  const [userMessages, setUserMessages] = useState([]);


  useEffect( () => {
    const fetchData = async () => {
      await accountLoad();
    };
    fetchData();
  }, [] );


  // GET
  const accountLoad = async () => {
    setUserMessages(undefined);
    try {
      const answer = await UserData.accountGet();

      if ( processAccountAnswer(answer) ) {
        setDataUse(answer.data.use);
        setDataUser(answer.data.account.user);
        setDataInvoicing(answer.data.account.address_invoicing);
        setDataShipping(answer.data.account.address_delivery);
      }
      return answer.data;

    } catch(e) {
      processAccountAnswer(null);
      return null;
    }
  };


  const processAccountAnswer = (answer) => {
    if (answer === undefined || answer === null) {
      messagesAdd(1, "Nepodařilo se spojit se serverem. Zkuste to později znovu nebo nás kontaktujte.");
      return false;
    }

    switch (answer.status) {
      case 0:
        break;
      case 5100:
        //setUserMessages([ {type:3, text: "Musíte se nejdříve přihlásit."} ]);
        setUserMessages(null);
        messagesAdd(3, "Musíte se nejdříve přihlásit.");
        return false;
      default:
        setUserMessages([ {type:1, text: "Systémová chyba \"" + Math.abs(data.status) + "\", prosím kontaktujte nás."} ]);
        return false;
    }

    setUserMessages([]);
    return true;
  }


  const handleAccountUpdate = async () => {
    const answer = await accountPut(dataUser, dataInvoicing, dataShipping, ((dataPassword.password_old.length === 0) ? undefined : dataPassword), dataUse);

    setDataPassword( {...AccountE.password} );

    if (answer === undefined || answer === null) {
      setUserMessages([ {type:1, text: "Nepodařilo se spojit se serverem. Zkuste to později znovu nebo nás kontaktujte."} ]);
      return false;
    }
    switch (answer.status) {
      case 0:
        break;
      case 5100:
        setUserMessages([ {type:2, text: "Musíte se nejdříve přihlásit."} ]);
        return false;
      case 6702:
        setUserMessages([ {type:1, text: "Systémová chyba: chybí zákazník, prosím kontaktujte nás."} ]);
        return false;
      case -1:
        setUserMessages([ {type:2, text: "Hodnota \"Přihlašovací e-mail\" musí být platná e-mailová adresa."} ]);
        return false;
      case -2:
        setUserMessages([ {type:2, text: "Nově zadávaný \"Přihlašovací e-mail\" je již obsazený."} ]);
        return false;
      case -3:
        setUserMessages([ {type:1, text: "Systémová chyba: chybí firma, prosím kontaktujte nás."} ]);
        return false;
      case -4:
        setUserMessages([ {type:1, text: "Systémová chyba: chybí doručovací adresa, prosím kontaktujte nás."} ]);
        return false;
      case -5:
        //setUserMessages([ {type:2, text: "Hodnota \"Nové heslo\" je prázdná nebo není shodná se \"Zopakujte heslo\"."} ]);
        setUserMessages( answer.messages );
        return false;
      case -6:
        setUserMessages([ {type:2, text: "Zadáno chybné \"Staré heslo\"."} ]);
        return false;
      case 6403:
        setUserMessages( answer.messages );
        return false;
      case 6402:
        setUserMessages([ {type:1, text: "Systémová chyba: chybí data, prosím kontaktujte nás."} ]);
        return false;
      default:
        setUserMessages([ {type:1, text: "Systémová chyba \"" + Math.abs(answer.status) + "\", prosím kontaktujte nás."} ]);
        return false;
    }

    setUserMessages([ {type:0, text: "Váš účet byl upraven."} ]);
    return true;
  }


  // PATCH
  const accountPut = async (userData, invoicingData, shippingData, passwordData, useData) => {
    setUserMessages(false);

    let data = {
      account : {
        user:userData,
        address_invoicing:invoicingData,
        address_delivery:shippingData,
      },
      use : useData,
    }
    if (passwordData !== undefined) {
      data['password'] = passwordData;
    }

    try {
      const answer = await UserData.accountPut( userData["email"], data );
      return answer;

    } catch(e) {
      return null;
    }
  };


  // RENDER >>>


  if ( userMessages === undefined ) {
    return (
      <Loader type="page" text="Načítám Váš profil ..." />
    )
  }

  if ( userMessages === null ) {
    return (
      <section id="accountpage" className="row-cols">
        <aside className="col-left">
        </aside>
        <main className="col-middle">

          <h1>Můj účet</h1>
          <MessagesBox messages={userMessages} />

        </main>
        <aside className="col-right">
        </aside>
      </section>
    );
  }


  return (
    <section id="accountpage" className="row-cols">
      <aside className="col-left">
      </aside>
      <main className="col-middle">

        <h1>Můj účet</h1>
        <form onSubmit={ (e) => (e.preventDefault()) }>
          <MessagesBox messages={userMessages} />

          <h2>Fakturační adresa</h2>
          <PartialAddressInvoice data={dataInvoicing} setData={setDataInvoicing} dataUse={dataUse} setDataUse={setDataUse} />

          <h2>Doručovací adresa</h2>
          <PartialAddressDelivery data={dataShipping} setData={setDataShipping} dataUse={dataUse} setDataUse={setDataUse} />

          <h2>Přihlašovací údaje</h2>
          <div className="form-partial form-partial-user-account form-row form-2">

            <div className="form-col">
              <Input labelText="E-mail" name="email" value={dataUser["email"]} onChange={(e) => setState(e,dataUser,setDataUser)} />
            </div>

            <div className="form-col">
              <p>Vyplňte pouze, chcete-li změnit heslo:</p>
              <Input type="password" labelText="Staré heslo" name="password_old" value={dataPassword["password_old"]} onChange={(e) => setState(e,dataPassword,setDataPassword)} />
              <Input type="password" labelText="Nové heslo" name="password_new" value={dataPassword["password_new"]} onChange={(e) => setState(e,dataPassword,setDataPassword)} />
              <Input type="password" labelText="Zopakujte heslo" name="password_repeat" value={dataPassword["password_repeat"]} onChange={(e) => setState(e,dataPassword,setDataPassword)} />
            </div>

          </div>

          <div className="form-partial form-partial-submit form-row form-1">

            <div className="form-col">
              <button onClick={handleAccountUpdate} disabled={(userMessages === false)}>
                {
                  (userMessages === false)
                  ?
                    <Loader />
                  :
                    "Změnit údaje"
                }
              </button>
            </div>

          </div>

        </form>

      </main>
      <aside className="col-right">
      </aside>
    </section>
  );

}

export default AccountPage;
