import { useState, useEffect } from 'react';
import { isset, empty, toInt } from 'helpers/core';

// Elements
import ScrollerY from 'components/elements/scrollerY';

// Forms
import { mapToOption, Input, Select } from 'components/form';

// Parts

// Helpers
import {changeTitle} from 'helpers/page';
import {strYears,strVehicle,strEngine} from 'helpers/vehicles';
import {responsive, isResponsive} from 'helpers/css';

// Context
import {useGlobal} from 'context/globalContext.js';
import {useProducts} from 'context/productsContext';
// Data
import * as DataVehicle from 'data/vehicleData';


const VehicleSelector = ({ onSet, onClear, onResponsive }) => {
  const {setOverlay} = useGlobal();
  const {vehicleClear, vehicleSetManufacturer, vehicleSetModel, vehicleSetModification, vehicleSetId} = useProducts();
  const {vehicle} = useProducts().products;

  const [manufacturers, setManufacturers] = useState(undefined);
  const [years, setYears] = useState(undefined);
  const [models, setModels] = useState(undefined);
  const [modifications, setModifications] = useState(undefined);

  const [respoShow, setRespoShow] = useState(undefined);

  const [selectedYear, setSelectedYear] = useState({ text: "Vše", value: 0, active: true });
  const currentYear = new Date().getFullYear();
  const minYear = currentYear - 30;


  // After mount - load modifications
  useEffect( () => {
    const fetchData = async () => {
      await modificationsLoad(vehicle.model_key);
    };

    if ( isResponsive(responsive.t, true) ) {
      setRespoShow(4);
    }
    if (vehicle.model_key) {
      fetchData();
    }
  }, [vehicle.model_key] );

  // After mount - load models
  useEffect( () => {
    const fetchData = async () => {
      await modelsLoad(vehicle.manufacturer_key);
    };


    if ( isResponsive(responsive.t, true) ) {
      setRespoShow(3);
    }
    if (vehicle.manufacturer_key) {
      fetchData();
    }
  }, [vehicle.manufacturer_key] );

  // After mount - load manufacturers
  useEffect( () => {
    setYears( buildYears() );

    if ( onClear ) {
      onClear( () => { handleClearVehicle(); } );
    }
    if ( onSet ) {
      onSet( () => { handleSetVehicle(); } );
    }

    if ( isResponsive(responsive.t, true) ) {
      setRespoShow(1);
    }
    const fetchData = async () => {
      await manufacturersLoad();
    };
    fetchData();
  }, [] );


  // GET

  const manufacturersLoad = async () => {
    try {
      const data = await DataVehicle.getManufacturers();
      setManufacturers(data.manufacturers);
    } catch(e) {
    }
  }

  const modelsLoad = async (manufacturerId) => {
    try {
      const data = await DataVehicle.getModels(manufacturerId);
      setModels(data.models);
    } catch(e) {
    }
  }

  const modificationsLoad = async (modelId) => {
    try {
      const data = await DataVehicle.getModifications(modelId);
      setModifications(data.modifications);
    } catch(e) {
    }
  }


  // Handle

  const handleClearVehicle = () => {
    vehicleClear();
    // Set
    setModels(undefined);
    setModifications(undefined);
  };

  const handleSetVehicle = () => {
    changeTitle("PRONDO - "+ vehicle.manufacturer_text +" "+ vehicle.model_text +" "+ vehicle.modification_kw +"kW "+ vehicle.modification_engine);

    vehicleSetId(vehicle.modification_key);
  };


  // Mapper

  const buildYears = () => {
    const out = [];
    out.push({ text: "Vše", value: 0, active: false });
    for (let i = currentYear; i >= minYear; i--) {
      out.push({ text: i, value: i, active: false });
    }
    out.push({ text: "Starší", value: -1, active: false });

    //setYears(out);
    return out;
  }

  const mapToSelector = (type, data, active) => {
    let out = [];
    if ( ! isset(data) ) {
      return undefined;
    }

    switch (type) {
      case 'manufacturers':
        data.forEach( function (item, index) {
          out.push({ text: item.text, value: item.id, active: (active === item.id) });
        });
        break;

      case 'years':
        //const lastIdx = out.length - 1;
        data.forEach( function(item,idx) {
          /*let active = false;
          if (
            ( (idx === 0) && (active === 0) ) // For "Vše"
            || ( (idx === lastIdx) && (active === -1) ) // For "Starší"
            || ( active === item.value )
          ) {
            active = true;
          }*/
          out.push({ text: item.text, value: item.value, active: (active === item.value) });
        });
        break;

      case 'models':
        data.forEach( function (item, index) {
          let text = item.text;
          const years = strYears(item.year_from, item.year_to);
          if (years.length !== 0) {
            text += " " + years;
          }

          out.push({ text, value: item.id, active: (active === item.id) });
        });
        break;

      case 'modifications':
        data.forEach( function (item, index) {
          let text = item.text;
          let str = strVehicle(item.kw, item.hp, item.ccm);
          if (str.length !== 0) {
            text += " [" + str + "]";
          }
          str = strEngine(item.engine);
          if (str.length !== 0) {
            text += " " + str;
          }

          out.push({ text, value: item.id, active: (active === item.id) });
        });
        break;

      default:
        console.log("ERROR IN CODE: not such a type '"+ type +"'");
        break;
    }

    return out;
  };


  const vehicleFromSelect = (data, id) => {
    let out = null;
    //id = parseInt(id);

    data.forEach( function (item, index) {
      //if (item.id === id) {
      if (item.id == id) {
        out = item;
        return;
      }
    });
    return out;
  };


  const filterModelsByYears = (year, allModels) => {
    let out = [];
    if ( ! isset(allModels) || (year == 0) ) {
      return allModels;
    }

    const yearOlder = minYear - 1;

    allModels.forEach( function (item, index) {
      if ( empty(item.year_from) ) {
        out.push(item);
        return;
      }

      // Parse "Year from"
      let yearFrom = null;
      let toCut = item.year_from.length - 4;
      if (toCut > 0) {
        yearFrom = toInt( item.year_from.slice(0,toCut*-1) );
      }
      // If unable to parse "Year from" -> add
      if (yearFrom === null) {
        out.push(item);
        return;
      }

      // For OLDER vehicles
      if ( year === -1 ) {
        //if ( (yearFrom <= yearOlder) && ((yearTo === null) || (yearOlder <= yearTo)) ) {
        if ( yearFrom <= yearOlder ) {
          out.push(item);
        }
        return;
      }

      // Parse "Year to"
      let yearTo = null;
      toCut = item.year_to.length - 4;
      if (toCut > 0) {
        yearTo = toInt( item.year_to.slice(0,toCut*-1) );
      }

      if ( yearFrom <= year && ((yearTo === null) || (year <= yearTo)) ) {
        out.push(item);
        return;
      }
    });

    return out;
  };


  // Handle

  const handleChangeManufacturer = (idx) => {
    const item = manufacturers[idx];
    if (item === undefined) {
      console.log("ERROR: manufacturer not selected");
      return;
    }

    // Set active manufacturer
    const newManufacturers = [...manufacturers];
    newManufacturers[idx].active = true;
    setManufacturers(newManufacturers);

    vehicleSetManufacturer(item.id, item.text);
    // Set
    setSelectedYear( years[0] );
    setModels(undefined);
    setModifications(undefined);
    // For responsive
    if ( isResponsive(responsive.t, true) ) {
      setRespoShow(2);
    }
    // Load
    modelsLoad(item.id);
  };

  const handleChangeYear = (idx) => {
    /*// Build new years
    const newYears = buildYears();
    // Set active year
    newYears[idx].active = true;
    setYears( newYears );*/

    const item = years[idx];
    setSelectedYear( item );
    // For responsive
    if ( isResponsive(responsive.t, true) ) {
      setRespoShow(3);
    }
  };

  const handleChangeModel = (idx) => {
    const filteredModels = filterModelsByYears(selectedYear.value, models);
    const item = filteredModels[idx];
    if (item === undefined) {
      console.log("ERROR: model not selected");
      return;
    }

    // Set active model
    const newModels = [...models];
    newModels[idx].active = true;
    setModels(newModels);

    vehicleSetModel(item.id, item.text, item.year_from, item.year_to);
    // Set
    setModifications(undefined);
    // For responsive
    if ( isResponsive(responsive.t, true) ) {
      setRespoShow(4);
    }
    // Load
    modificationsLoad(item.id);
  };

  const handleChangeModification = (idx) => {
    const item = modifications[idx];
    if (item === undefined) {
      console.log("ERROR: modification not selected");
      return;
    }

    changeTitle("PRONDO - "+ vehicle.manufacturer_text +" "+ vehicle.model_text +" "+ item.kw +"kW "+ item.engine);

    // Set active modification
    const newModifications = [...modifications];
    newModifications[idx].active = true;
    setModifications(newModifications);

    vehicleSetModification(item.id, item.text, item.id, item.engine, item.kw, item.hp, item.ccm);
    // For responsive
    if ( isResponsive(responsive.t, true) ) {
      //setRespoShow(0);
      setOverlay(null);
    }
  };


  // RENDER >>>


  return (
    <form className="vehicle-selector form-row">

      {
        ( (respoShow === undefined) || (respoShow === 1) )
        &&
        <div className="form-col form-15">
          <label>Výrobce</label>
          <ScrollerY
            options={ mapToSelector('manufacturers', manufacturers, vehicle.manufacturer_key) }
            onClick={handleChangeManufacturer}
            emptyText="žádný výrobce nyní není k dispozici"
            errorText="chyba: nepodařilo se načíst výrobce"
            />
        </div>
      }
      

      {
        ( (respoShow === undefined) || (respoShow === 2) )
        &&
        <div className="form-col form-10">
          <label>Rok výroby</label>
          {
            ( (models === undefined) && ((manufacturers === undefined || vehicle.manufacturer_key === null)) )
            ?
              <small>nejdříve vyberte výrobce</small>
            :
              <ScrollerY
                options={ mapToSelector('years', years, selectedYear.value) }
                onClick={handleChangeYear}
                emptyText="u tohoto výrobce nyní neevidujeme žádný model"
                errorText="chyba: nepodařilo se načíst modely"
                />
          }
        </div>
      }
      

      {
        ( (respoShow === undefined) || (respoShow === 3) )
        &&
        <div className="form-col form-30">
          <label>Model</label>
          {
            ( (models === undefined) && ((manufacturers === undefined || vehicle.manufacturer_key === null)) )
            ?
              <small>nejdříve vyberte výrobce</small>
            :
              <ScrollerY
                options={ mapToSelector('models', filterModelsByYears(selectedYear.value,models), vehicle.model_key) }
                onClick={handleChangeModel}
                emptyText="u tohoto výrobce nyní neevidujeme žádný model"
                errorText="chyba: nepodařilo se načíst modely"
                />
          }
        </div>
      }
      

      {
        ( (respoShow === undefined) || (respoShow === 4) )
        &&
        <div className="form-col">
          <label>Modifikace</label>
          {
            ( (modifications === undefined) && (models === undefined || vehicle.model_key === null) )
            ?
              <small>nejdříve vyberte model</small>
            :
              <ScrollerY
                options={ mapToSelector('modifications', modifications, vehicle.modification_key) }
                onClick={handleChangeModification}
                emptyText="u tohoto modelu nyní neevidujeme žádnou modifikaci"
                errorText="chyba: nepodařilo se načíst modifikace"
                />
          }
        </div>
      }

    </form>
  );

};

export default VehicleSelector;
