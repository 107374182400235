import { useHistory } from "react-router-dom";

// Parts
import HighlightedManufacturer from './highlightedmanufacturer';

// Context
import {useProducts} from 'context/productsContext';


const HighlightedManufacturers = ({ props, manufacturers }) => {
  // Hooks
  const history = useHistory();
  const {vehicleSetManufacturer} = useProducts();


  // Handle

  const handleChangeManufacturer = (id, text) => {
    vehicleSetManufacturer(id, text);
    // Set
    //setModels(undefined);
    //setModifications(undefined);
    history.push("/produkty");
  };


  // RENDER >>>


  if ( (! manufacturers) || (manufacturers.length === 0) ) {
    return null;
  }

  return(
    <div className="box-hero">
      {
        manufacturers.map( item =>
          <HighlightedManufacturer
            on={handleChangeManufacturer}
            key={item.key}
            url={item.url}
            text={item.text}
            img={item.img}
          />
      )}
    </div>
  );

}

export default HighlightedManufacturers;
