import config from 'config.json';
import * as Data from './data';
import * as Http from './httpData';
/*import * as Helpers from 'utils/helpers';
import * as Dt from 'utils/dt';*/


// PRODUCT + PRODUCTS >>>

const categoryUrl = config.apiUrl + '/categories';

function mapToCategory(item) {
  return item;
}
function mapFromCategory(item) {
  return item;
}
function listCategories(data) {
  let mapped = [];
  data.forEach( function (item, index) {
    mapped.push( mapToCategory(item) );
  });
  return mapped;
}


export async function getCategories(id, slug, params) {
  let response = null;
  if (id === null || slug === null) {
    response = await Http.get( categoryUrl + config.apiFormat + Data.params(params) );
  } else {
    response = await Http.get( categoryUrl + `/${id}/${slug}` + config.apiFormat + Data.params(params) );
  }
  response.data.categories = ( response.data.categories === undefined )
    ? []
    : listCategories( response.data.categories );
  return response.data;
}
