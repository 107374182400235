import { useState, useEffect } from 'react';

import { isset, toInt } from 'helpers/core';
// Parts
import Loader from 'components/parts/loader';
// Forms
import {setState, Select} from 'components/form';
// Context
import {useGlobal} from 'context/globalContext';
import {useAuth} from 'context/authContext.js';
// Data
import * as PreviewData from 'data/admin/previewData';


const optionsLevels = [
  { id: 0, value: "Z nastavení uživatele" },
  { id: 1, value: "Jako hladinu A" },
  { id: 2, value: "Jako hladinu B" },
  { id: 3, value: "Jako hladinu C" },
  { id: 4, value: "Jako hladinu D" },
  { id: 5, value: "Jako nákupní" },
];

const initData = {
  pricelevel: 0,
};


const AdminBar = () => {
  // Hooks
  const {setOverlay} = useGlobal();
  const {auth} = useAuth();
  const [data, setData] = useState(initData);


  // When changed "auth" context
  useEffect( () => {
    const info = { ...initData };
    info.pricelevel = isset(auth.admin) && isset(auth.admin.tmpPriceLevel) ? toInt(auth.admin.tmpPriceLevel) : 0;
    setData( info );
  }, [auth.admin] );


  const handleChangeLevel = async ({currentTarget : input}) => {
    await setPricelevel(input.value);
    window.location.reload();
  }


  // PATCH
  const setPricelevel = async (id) => {
    setData(undefined);
    try {
      const data = await PreviewData.previewPricelevel(id);

      /*const newData = { ...initData };
      newData.pricelevel = data;
      setData(newData);

      return data;*/

    } catch(e) {
      setData(null);
      return null;
    }
  };


  // RENDER >>>

  if ( (! isset(auth.user)) || (! auth.user.roles.includes("ROLE_ADMIN")) ) {
    return null;
  }

  if ( data === undefined ) {
    return (
      <div id="admin-bar" className="bar">
        <Loader text="Načítám administrační panel ..." />
      </div>
    )
    //return null;
  }

  return(
    <div id="admin-bar" className="bar">

      <form onSubmit={ (e) => (e.preventDefault()) }>
        <div className="row">
          <div className="col-4">
            ADMINISTRAČNÍ PANEL
          </div>
          <div className="col-8">
            <Select labelText="Zobrazit ceny" options={optionsLevels} name="pricelevel" value={data["pricelevel"]} onChange={(e) => handleChangeLevel(e)} className={data["pricelevel"] == 0 ? "" : " alt_text_err"} empty={false} />
          </div>
        </div>

        <div>
        </div>
      </form>

    </div>
  );

}

export default AdminBar;
