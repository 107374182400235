import React from 'react';

// Helpers
import { isset, isNumber } from 'helpers/core';


const DetailOrder = ({ info, incart, onstock, together, transport }) => {

  return(
    <ul className="detail-order list">
      {
        info.map(item =>
          <li key={item}><small>{item}.</small></li>
        )
      }
      {
        isset(onstock) &&
          <>
            {
              ( onstock == -1 ) &&
                <li className="state-warn"><span>Dostupnost a termín dodání tohoto zboží Vám potvrdíme</span></li>
            }
            {
              ( isset(incart) && (onstock < -1) && (incart > Math.abs(onstock)) ) &&
                <li className="state-warn">Objednáváte více kusů, než může být skladem, <span>dostupnost a termín Vám potvrdíme</span></li>
            }
          </>
      }
      {
        isset(together) && ( (isNumber(incart) && (incart % together !== 0)) || (! isNumber(incart) && (together > 1)) ) &&
          <li className="state-err">Lze nakoupit pouze <span>po {together} kusech</span></li>
      }
      {
        ( transport === false ) &&
          <li className="state-err">U tohoto produktu je možný pouze osobní odběr</li>
      }
    </ul>
  );

}


DetailOrder.defaultProps = {
  info: [],
  incart: null,
  onstock: null,
  together: null,
  transport: null,
};

export default DetailOrder;
