import React from 'react';

// Helpers
import { isset } from 'helpers/core';

// Forms
import {setState, Check, Input, Select, optionsCountries} from 'components/form';


const PartialAddressDelivery = ({ data, setData, dataUse, setDataUse }) => {

  // RENDER >>>

  if ( ! isset(dataUse) || dataUse["address_delivery"] === undefined || dataUse["address_delivery"] != 1 ) {
    return (
      <Check labelText="Stejná jako fakturační" name="address_delivery" value={1} onChange={(e) => setState(e,dataUse,setDataUse,1)} />
    );
  }


  return (
    <>
      <Check labelText="Stejná jako fakturační" name="address_delivery" value={null} onChange={(e) => setState(e,dataUse,setDataUse,1)} />

      <div className="form-partial form-partial-address-invoice form-row form-3">

        <div className="form-col">
          <Input labelText="Jméno" name="firstname" value={data["firstname"]} onChange={(e) => setState(e,data,setData)} />
          <Input labelText="Příjmení" name="lastname" value={data["lastname"]} onChange={(e) => setState(e,data,setData)} />
          <Input labelText="E-mail" name="email" value={data["email"]} onChange={(e) => setState(e,data,setData)} />
          <Input labelText="Telefon" name="phone" value={data["phone"]} onChange={(e) => setState(e,data,setData)} />
        </div>

        <div className="form-col">
          <Input labelText="Ulice, číslo" name="street" value={data["street"]} onChange={(e) => setState(e,data,setData)} />
          <Input labelText="Město" name="city" value={data["city"]} onChange={(e) => setState(e,data,setData)} />
          <Input labelText="PSČ" name="zip" value={data["zip"]} onChange={(e) => setState(e,data,setData)} />
          <Select labelText="Stát" options={optionsCountries} name="country" value={data["country"]} onChange={(e) => setState(e,data,setData)} empty={false} />
        </div>

        <div>&nbsp;</div>

      </div>
    </>
  );
}

export default PartialAddressDelivery;
