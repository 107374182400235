import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';

// Context
import { BrowserRouter } from 'react-router-dom';
import { GlobalProvider } from 'context/globalContext.js';
import { AuthProvider } from 'context/authContext.js';
import { ProductsProvider } from 'context/productsContext.js';


//TODO: Check why commented: <React.StrictMode>
/*ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);*/
ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <ProductsProvider>
        <GlobalProvider>
          <App />
        </GlobalProvider>
      </ProductsProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
