import React from 'react';


const AboutusPage = ({ props }) => {

  return (
    <>
      <h1>O firmě PRONDO</h1>
      
      <p>Dovolujeme si Vám představit firmu PRONDO. Firma vznikla v roce 1991. Od počátku se zabýváme prodejem
          náhradních
          dílů na osobní vozy značky ŠKODA, a to od nejstarších typů vozů Š100, 110 až po dnešní nejnovější typy
          vozů
          Octavia II, Fabia II, Superb II, Roomster i Yeti.</p>
      <p>Za dobu naší působnosti v tomto oboru se nám podařilo smluvně získat zastoupení rozhodujících tuzemských
          výrobců
          originálních náhradních dílů nejen pro vozy Škoda. V průběhu posledních let se rozšířila působnost firmy
          na
          prodej kompletního sortimentu náhradních dílů pro osobní automobily všech značek, ve velice zajímavých
          cenových
          relacích.</p>
      <p>Velkoobchodní prodejní sklad firmy PRONDO sídlí v průmyslové zóně mezi Mladou Boleslaví a městem
          Kosmonosy.</p>
      <p> Zabýváte-li se tedy prodejem náhradních dílů či opravami vozů nejen značky Škoda, neváhejte a navštivte
          nás.</p>

      <h2>Historie firmy PRONDO</h2>

      <p>U zrodu této rodinné firmy stáli čtyři lidé, manželé Iveta a František Cerhovi, a Eva a Jan Ištokovi.
          Protože pan Cerha byl vyučen automechanikem a auta byla jeho koníčkem, bylo zaměření firmy v celku jasné
          -
          automobilismus.
          Po vyjasnění náplně a zaměření firmy zahájila 1. dubna 1991 v Mladé Boleslavi oficiálně svou
          činnost.</p>

      <p> Firma v počátku své existence vystřídala několik názvů. Nejprve PROCIF, poté INTER - MOTO. Teprve pak
          začala
          působit pod jménem PRONDO, které vzniklo ze zkratek činnosti, kterou se firma zabývala, t. j. PROdej
          Náhradních
          Dílů Osobních. Tento název záhy doplnila logem a dodatkem "velkoobchod" a "autodíly Škoda". Obchodní
          aktivity
          firmy v samých začátcích vycházely z praktických zkušeností.
      </p>
      <p> Činnost firmy byla až do konce května roku 1992 zajišťována čtyřmi pracovníky, ale pouze mimo hlavní
          pracovní
          poměr v odpoledních a　večerních hodinách, o sobotách a nedělích. To vše bylo vhodně prokládáno kombinací
          čerpání
          dovolené, náhradního volna, apod.
      </p>
      <p> 　Protože se firma začala úspěšně rozvíjet, mohl se od června 1992 věnovat jeden a od září druhý ze
          zakladatelů
          firmy práci ve firmě na plný pracovní úvazek. V průběhu roku 1993 se firma rozrůstá na 5 stálých
          pracovníků.
          Postupně se jejich počet zvyšuje a v　současné době dosáhl počtu patnáct.
      </p>
      <p> Cílem firmy bylo výrazně rozšířit sortiment náhradních dílů a vytvářet stále lepší podmínky pro
          velkoobchod.
          Tento úkol nebyl v podmínkách konkurenčního prostředí s pětičlenným personálem nijak lehký. Firma
          získává mnoho
          obchodních kontaktů s předními českými výrobci autodílů, a tím se propracovává ke zdrojům zboží cenově a
          kvalitou konkurenčně schopným. Společnost nezaostává ani v technické vybavenosti. K　zabezpečování své
          činnosti
          měla již v roce 1993 pět vozidel, z toho 2 osobní, dodávku a 2 AVIE. Rovněž v oblasti výpočetní techniky
          byla
          firma dobře vybavena již v roce 1992, a to jak pro vedení účetnictví, tak i pro skladové hospodářství.
          Urychleně
          se vybavovala pro samotné skladové hospodářství, palet, paletových vozíků a vysokozdvižných vozíků -
          elektrických i motorových.
      </p>
      <p> Strategií firmy je stále větší zkvalitňování služeb pro stále se rozrůstající množství zákazníků. Nejen
          udržení,
          ale i sortiment a množství dílů. Co však stále chybí jsou vhodné prostory.　
      </p>
      <p> Ještě v roce 1991 se podařilo získat dva objekty k podnikání, a to garáž v Nádražní ulici v Mladé
          Boleslavi a
          suterénní prostory v hostinci na Rožátově. V průběhu následujícího roku rozšířila své prostory o dalších
          314
          m2　ve Viničné ulici. V závěru téhož roku byl pronajat další prodejní a　skladový objekt v Ptácké ulici č.
          20, u
          jezu na d areálem Mototechny. Prostor o rozloze 175 m2　byl upraven dle našich požadavků. Pouze
          plynofikaci
          objektu si firma musela provést na vlastní náklady.
      </p>
      <p> V roce 1993 jsme si v Březně pronajali další objekt o rozloze cca 100 m2.
      </p>
      <p> Prostorová rozšířenost firmy značně ztěžovala práci a zároveň značně zvyšovala i její náklady. Proto
          přivítala
          iniciativu jedné Kosmonoské firmy, která nabídla v roce 1995 vybudování moderního, zatepleného,
          vytápěného
          areálu o rozloze cca 1100 m2, rozsáhlého paletového a　manipulačního prostoru.　
      </p>
      <p> V červnu 1996 se firma do těchto prostor přestěhovala. Stávající prostory si všechny ponechala jako
          konsignační
          sklady. V roce 1997 začala tato firma pro nás budovat další objekt, který navazoval na manipulační
          prostor. Do
          doby dostavby nám pronajala další část haly, která na naši navazovala. Ke komplexnímu dobudování areálu
          však
          nedošlo, protože na ni byl vyhlášen konkurz a veškeré stavební akce byly zastaveny.　
      </p>
      <p> Počátkem roku 2001 se od správce konkurzní podstaty podařilo pronajmout celou halu, která má rozlohu
          rovněž 1100
          m2. K tomu další vytápěné prostory pro balení a expedici zboží, včetně sociálního zařízení a kanceláří.
          Tím se
          podařilo firmu soustředit do jednoho místa. Ostatní pronajaté prostory jsme postupně mohli vrátit.
      </p>
      <p> K velkoobchodu je dobré mít svoji maloobchodní prodejnu, která by v podstatě plnila mimo jiné i funkci
          průzkumu
          trhu. Stalo se tak v říjnu 1997, v　Jičíně, kde jsme pronajali objekt po zaniklé firmě, která se zabývala
          stejnou
          činností. V Mladé Boleslavi se tato snaha podařila realizovat až v　únoru 2001.
      </p>
      <p> Protože obchodní činnost se značně rozrostla, rozhodlo vedení firmy rozdělit činnost na dvě části.
          Stávající
          firma si ve své činnosti ponechala vývoz a dovoz zboží. K této činnosti si přibrala maloobchodní
          prodej. Nově vzniklá firma s názvem PRONDO s. r. o. si jako náplň své činnosti zvolila velkoobchodní prodej
          náhradních dílů v
          tuzemsku.
      </p>
      <p> V květnu 2001 svoji činnost firma rozšířila o autoservis a pneuservis na všechny typy osobních a
          užitkových
          vozidel. Ještě v témže roce si firma LUCAS zvolila náš autoservis za rychloservis firmy LUCAS.
      </p>
      <p> Protože má společnost za sebou úspěšnou desetiletou tradici, bylo rozhodnuto zaregistrovat logo firmy
          jako
          chráněné.
      </p>
      <p> Pro zkvalitnění informovanosti svých zákazníků, poskytování nabídek a zpráv o novinkách i aktivitách si
          firma
          nechala vytvořit www stránky a　aplikaci k aktuálním nabídkám. Za dobu své existence prováděla firma
          sponzorskou
          činnost, a to v oblasti školství, zdravotnictví a sportu. V posledních letech sponzoruje i oblasti
          automobilového sportu.
      </p>

    </>
  );

}

export default AboutusPage;
