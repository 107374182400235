import config from 'config.json';
import * as Data from './data';
import * as Http from './httpData';
import * as Core from 'helpers/core';
/*import * as Helpers from 'utils/helpers';
import * as Dt from 'utils/dt';*/


// USER >>>

const userUrl = config.apiUrl + '/user';

function mapToUser(item) {
  return item;
}
function mapFromUser(item) {
  return item;
}
function listUser(data) {
  let mapped = [];
  /*data.forEach( function (item, index) {
    mapped.push( mapToInfoParam(item) );
  });*/
  for ( const [key, value] of Object.entries(data) ) {
    let item = mapToUser(value);
    item.id = key;
    mapped.push( item );
  }
  return mapped;
}


export async function loginGet(params) {
  const response = await Http.get( userUrl + `/login` + config.apiFormat + Data.params(params) );
  response.data.user = ( (response.data.user === undefined) || (response.data.user === null) )
    ? null
    : mapToUser(response.data.user);
  return response.data;
}

export async function loginPost(email, password, params) {
  const response = await Http.post( userUrl + `/login` + config.apiFormat + Data.params(params), {login:{email,password}} );
  response.data.user = ( (response.data.user === undefined) || (response.data.user === null) )
    ? null
    : mapToUser(response.data.user);
  response.data.status = response.status;
  return response.data;
}

export async function loginDel(params) {
  const response = await Http.del( userUrl + `/login` + config.apiFormat + Data.params(params) );
  return response.status;
}


// ACCOUNT >>>

const accountUrl = config.apiUrl + '/account';

function mapToAccount(item) {
  return item;
}
function mapFromAccount(item) {
  return item;
}
function listAccount(data) {
  let mapped = [];
  /*data.forEach( function (item, index) {
    mapped.push( mapToInfoParam(item) );
  });*/
  for ( const [key, value] of Object.entries(data) ) {
    let item = mapToAccount(value);
    item.id = key;
    mapped.push( item );
  }
  return mapped;
}


function mapToUseAccount(item) {
  return item;
}
function mapFromUseAccount(item) {
  return item;
}
function listUseAccount(data) {
  let mapped = [];
  /*data.forEach( function (item, index) {
    mapped.push( mapToUseAccount(item) );
  });*/
  for ( const [key, value] of Object.entries(data) ) {
    let item = mapToUseAccount(value);
    item.id = key;
    mapped.push( item );
  }
  return mapped;
}



// Load all info about account
export async function accountGet(params, checkAuth = true) {
  const response = await Http.get( accountUrl + config.apiFormat + Data.params(params), {}, checkAuth);
  response.data.account = ( (response.data.account === undefined) || (response.data.account === null) )
    ? null
    : mapToAccount(response.data.account);
  return response;
}

// Register
export async function accountPost(data, params) {
  const response = await Http.post( userUrl + '/register' + config.apiFormat + Data.params(params), {account: mapFromAccount(data)} );

  /*response.data.user = ( (response.data.user === undefined) || (response.data.user === null) )
    ? null
    : mapToUser(response.data.user);
  response.data.customer = ( (response.data.customer === undefined) || (response.data.customer === null) )
    ? null
    : mapToUser(response.data.customer);
  response.data.address_same = ( (response.data.address_same === undefined) || (response.data.address_same === null) )
    ? true
    : (response.data.address_same);
  response.data.address_invoicing = ( (response.data.address_invoicing === undefined) || (response.data.address_invoicing === null) )
    ? null
    : mapToUser(response.data.address_invoicing);
  response.data.address_delivery = ( (response.data.address_delivery === undefined) || (response.data.address_delivery === null) )
    ? null
    : mapToUser(response.data.address_delivery);
  */
  response.data.account = ( (response.data.account === undefined) || (response.data.account === null) )
    ? null
    : mapToAccount(response.data.account);
  response.data.status = response.status;
  response.data.messages = response.messages;

  return response.data;
}


export async function accountPut(id, data, params) {
  const dataOut = {...data};
  data.use = mapFromUseAccount(data.use);
  data.account = mapFromAccount(data.account);

  const response = await Http.put( accountUrl + config.apiFormat + Data.params(params), dataOut, id );
  response.data.account = ( (response.data.account === undefined) || (response.data.account === null) )
    ? null
    : response.data.account;
  return response;
}


// PASSWORD >>>

const passwordUrl = config.apiUrl + '/user/password';


function mapToPasswordToken(item) {
  return item;
}
function mapFromPasswordToken(item) {
  return item;
}
function listPasswordToken(data) {
  let mapped = [];
  for ( const [key, value] of Object.entries(data) ) {
    let item = mapToPasswordToken(value);
    item.id = key;
    mapped.push( item );
  }
  return mapped;
}


function mapToPasswordNew(item) {
  return item;
}
function mapFromPasswordNew(item) {
  return item;
}
function listPasswordNew(data) {
  let mapped = [];
  for ( const [key, value] of Object.entries(data) ) {
    let item = mapToPasswordNew(value);
    item.id = key;
    mapped.push( item );
  }
  return mapped;
}


// Request for a new password token
export async function passwordToken(data) {
  const response = await Http.post( passwordUrl + '/reset' + config.apiFormat, {email: mapFromPasswordToken(data)} );

  response.data.email = ( (response.data.email === undefined) || (response.data.email === null) )
    ? null
    : mapToPasswordToken(response.data.email);
  response.data.status = response.status;
  response.data.messages = response.messages;

  return response.data;
}


// Set a new password
export async function passwordNew(id, data, params) {
  const dataOut = {...data};
  data.use = mapFromUseAccount(data.use);
  data.account = mapFromAccount(data.account);

  const response = await Http.put( passwordUrl + '/reset' + config.apiFormat, dataOut, id );
  response.data.account = ( (response.data.account === undefined) || (response.data.account === null) )
    ? null
    : response.data.account;
  return response;
}
