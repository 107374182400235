import React, { useRef } from 'react';

// Elements
import ScrollerItemText from 'components/elements/scrollerItemText';

// Parts
import Loader from 'components/parts/loader';


const ScrollerY = ({ options, activeIdx, onClick, emptyText, errorText, classes, showArrows }) => {
    const scrollerRef = useRef();


    const handleClickedItem = (idx) => {
        onClick(idx);
    }

    const handleClickedArrow = (up) => {
        /*console.log( scrollerRef.current.scrollTop );
        console.log( scrollerRef.current.scrollHeight );
        console.log( scrollerRef.current.scrollTopMax );*/
        const scrollerSize = scrollerRef.current.clientHeight - 40;
        const scrollTo = (up) ? (scrollerRef.current.scrollTop + scrollerSize) : (scrollerRef.current.scrollTop - scrollerSize);
        scrollerRef.current.scrollTo( { top: scrollTo, behavior: 'smooth' } );
    }

    const renderLoading = () => {
        return (
            <Loader text="Načítám ..." />
        );
    }

    const renderText = (text) => {
        return (
            <div>
                {text}
            </div>
        );
    }
    

    return (
        <div className={`scroller scrollerY ${classes}`}>
            {
                (options === undefined)
                ?
                    renderLoading()
                :
                (options === null)
                ?
                    renderText(errorText)
                :
                (options.length === 0)
                ?
                    renderText(emptyText)
                :
                <>
                    {
                        showArrows
                        &&
                            <div onClick={ () => handleClickedArrow(false) }>&#5123;</div>
                    }
                    
                    <ul ref={scrollerRef}>
                        {
                            options.map( (item,idx) =>
                                <ScrollerItemText key={idx} option={item} isActive={idx === activeIdx} onClick={ () => handleClickedItem(idx) } />
                            )
                        }
                    </ul>

                    {
                        showArrows
                        &&
                            <div onClick={ () => handleClickedArrow(true) }>&#5121;</div>
                    }
                </>
            }
        </div>
    );
};

ScrollerY.defaultProps = {
    activeIdx : null,
    emptyText : "Žádné výsledky nenalezeny.",
    errorText : "Nepodařilo se načíst data, zkuste obnovit stránku.",
    classes : "",
    showArrows : true
};

export default ScrollerY;