import React from 'react';
// Functions
import { isArray } from 'helpers/core';


const MessagesBox = ({ messages }) => {

  if ( (! isArray(messages)) || messages.length === 0 ) {
    return null;
  }

  return (
    <div className="messagesbox">
      {
        messages.map( (item, idx) =>
            <p key={idx} className={`message ${ (item.type==0)?" alt_ok" : (item.type==1||item.type==2)?" alt_err" : (item.type==3)?" alt_warn" : "" }`} >{item.text}</p>
          )
      }
    </div>
  );

}


MessagesBox.defaultProps = {
  messages : [],
};

export default MessagesBox;
