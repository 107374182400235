import React from 'react';

// Parts
import ProdFlags from 'components/parts/product/prodflags';

// Images
import img_empty from 'assets/images/noimg.png';


const DetailProd = ({ title, manufacturer, image, flags, discount }) => {

  return(
    <div className="detail-prod">
      <div>> {title} <span>{manufacturer}</span></div>

      <div>
        <img src={ image ? image : img_empty } alt={"Foto produktu "/* + title*/} className={ image ? "img-fit" : "img-empty" } />
      </div>

      <ProdFlags discount={discount} flags={flags} />
    </div>
  );

}

export default DetailProd;
