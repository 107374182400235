import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Parts
import Loader from 'components/parts/loader';
import ProductRow from 'components/parts/productrow';

// Helpers
import {toInt} from 'helpers/core';
import {changeTitle} from 'helpers/page';
import {strYears,strVehicle,strEngine} from 'helpers/vehicles';
import {orderByStockAndPrice} from 'helpers/products';

// Context
import {useGlobal} from 'context/globalContext';
import {useProducts} from 'context/productsContext';
// Data
import * as DataProduct from 'data/productData';
import * as DataVehicle from 'data/vehicleData';


const Table = ({ url }) => {
  /*test_params = [
    {
      name: "Hmotnost",
      unit: "kg",
      value: "1280 kg"
    },
  ];
  test_replacements = [
    {
      code: "12345",
      name: "Testovací produkt",
      manufacturer: "BMX",
      ean: "987654"
    },
  ];*/

  const {messagesAdd} = useGlobal();
  const {vehicleSetManufacturer, vehicleSetModel, vehicleSetModification, vehicleSetId} = useProducts();

  const [current, setCurrent] = useState("params");
  const [table, setTable] = useState([
    { type: "params", text: "Parametry", data: null },
    { type: "replacements", text: "Náhražky a cross reference", data: null },
    { type: "oe", text: "OE kódy", data: null },
    { type: "usage", text: "Použití ve vozidlech", data: null },
  ]);
  const [show_usage, setShow_usage] = useState({
    manufacturer: null,
    model: null,
    modification: null
  });

  const [manufacturers, setManufacturers] = useState(undefined);


  // After mount - load manufacturers
  useEffect( () => {
    const fetchData = async () => {
      await loadInfo("params");
    };
    fetchData();
  }, [] );


  const handleTableTab = (newTab) => {
    if ( current === newTab ) {
      return;
    }
    loadInfo(newTab);
    setCurrent(newTab);
  }


  const handleShowUsage = (manufacturer, model) => {
    const new_show_usage = {...show_usage};

    let changingManufacturer = (model === undefined);

    if ( changingManufacturer ) {
      // Changing manufacturer
      if (manufacturer === null) {
        new_show_usage.manufacturer = null;
        new_show_usage.model = null;
        setShow_usage(new_show_usage);
      }
      else if ( manufacturer !== new_show_usage.manufacturer ) {
        new_show_usage.manufacturer = manufacturer;
        new_show_usage.model = null;
        setShow_usage(new_show_usage);
      }

    } else {
      // Changing model
      if (model === null) {
        new_show_usage.model = null;
        setShow_usage(new_show_usage);
      }
      else if ( model !== new_show_usage.model ) {
        new_show_usage.model = model;
        setShow_usage(new_show_usage);
      }

    }

  }


  const changeVehicle = (manufacturer, model, modification) => {
    changeTitle("PRONDO - "+ manufacturer.text +" "+ model.text +" "+ modification.kw +"kW "+ modification.engine);

    vehicleSetManufacturer(toInt(manufacturer.id), manufacturer.text);
    vehicleSetModel(model.id, model.text, model.year_from, model.year_to);
    vehicleSetModification(modification.id, modification.text, modification.id, modification.engine, modification.kw, modification.hp, modification.ccm);
    vehicleSetId(modification.id);
    messagesAdd(0, "Vozidlo bylo nastaveno do hlavního filtru");
  }


  // GET

  const loadInfo = async (infoType) => {
    let idx = null;
    switch(infoType) {
      case "params":
        idx = 0;
        break;
      case "replacements":
        idx = 1;
        break;
      case "oe":
        idx = 2;
        break;
      case "usage":
        idx = 3;
        break;
      default:
        return;
    }
    // Prevent multiple downloads
    if (table[idx].data !== null) {
      return;
    }
    try {
      let infoData = await DataProduct.getInfo( url.id, url.slug, infoType );

      const new_table = [ ...table ];
      switch(infoType) {
        case "params":
          new_table[idx].data = infoData;
          break;
        case "replacements":
          new_table[idx].data = infoData;
          break;
        case "oe":
          new_table[idx].data = infoData;
          break;
        case "usage":
          new_table[idx].data = infoData;
          break;
        default:
          return;
      }
      setTable(new_table);
    } catch(e) {
    }

    // Load manufacturers
    if ( infoType === "usage" && ! manufacturers ) {
      try {
        const data = await DataVehicle.getManufacturers();
        const newManufacturers = {};
        data.manufacturers.forEach( function(item, idx) {
          newManufacturers[item.text] = item;
        });
        setManufacturers(newManufacturers);
      } catch (e) {
      }
    }
  }


  const renderEmpty = () => {
    return (
      <div className="table-header row empty">
        <div className="col-12">žádný záznam</div>
      </div>
    )
  }

  const renderParams = (data) => {
    return (
      <div className="table-all tab-parameters">
        <div className="table-header row">
          <div className="col-5">název parametru</div><div className="col-7">hodnota</div>
        </div>
        <div className="table-data">
          { data.params.map( (item, idx) =>
            <div key={idx} className="row">
              {/* <div className="col-5">{item.name}{ (item.unit) ? <span> [{item.unit}]</span> : "" }</div><div className="col-7">{item.value}</div> */}
              <div className="col-5">{item.name}</div><div className="col-7">{item.value}</div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderReplacements = (data) => {
    const products = orderByStockAndPrice( data.replacements );

    return (
      <div className="table-all tab-replacements">
        <div className="table-products">
          { products.map( (item, idx) =>
            /*<div className="row">
              <div className="col-2">{item.code}</div><div className="col-3">{item.manufacturer}</div><div className="col-5">{item.title}</div><div className="col-2">{item.ean}</div>
            </div>*/
            <ProductRow key={idx} product={item} format="buy" />
          )}
        </div>

        <div className="table-header row">
          <div className="col-12">ostatní cross kódy</div>
        </div>
        <div className="table-data">
          <div className="row">
            <div className="col-12">
              { data.cross.map( (item, idx) =>
                <><Link to={`/produkty?text=${item.code}&typ=hledat`} target="_blank" className="link inverted">{item.code}</Link> &nbsp; </>
              ) }
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderOe = (data) => {
    return (
      <div className="table-all tab-oe">
        <div className="table-header row">
          <div className="col-3">výrobce</div><div className="col-9">OE kódy</div>
        </div>
        <div className="table-data">
          { data.oe.map( (item, idx) =>
            <div className="row">
              <div className="col-3">{item.manufacturer}</div><div className="col-9">{ item.oe.map( (item2,idx2) => {
                if (item.oe.length === idx2 + 1) {
                  return <><Link to={`/produkty?text=${item2}&typ=hledat`} target="_blank" className="link inverted">{item2}</Link></>
                } else {
                  return <><Link to={`/produkty?text=${item2}&typ=hledat`} target="_blank" className="link inverted">{item2}</Link> &nbsp; </>
                }
              })}</div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderUsage = (data) => {
    return (
      <div className="table-all tab-usage">
        <div className="table-header row">
          <div className="col-3">výrobce</div><div className="col-4">model</div><div className="col-5">modifikace</div>
        </div>
        <div className="table-data">
          {
            data.usage.map( (item_manufacturer, idx_manufacturer) =>
              <div key={idx_manufacturer}>
                <div className="row">
                  <div className="col-3">{item_manufacturer.text}</div><div className="col-9"><span onClick={ () => handleShowUsage((show_usage.manufacturer !== null && show_usage.manufacturer === idx_manufacturer) ? null : idx_manufacturer) } className="link">{(show_usage.manufacturer !== null && show_usage.manufacturer === idx_manufacturer) ? "skrýt" : "zobrazit"}</span></div>
                </div>
                {
                  (show_usage.manufacturer === idx_manufacturer)
                  && item_manufacturer.models.map( (item_model, idx_model) =>
                    <div key={idx_model}>
                      <div className="row">
                        <div className="col-3">{item_manufacturer.text}</div><div className="col-4">{item_model.text} <small>{strYears(item_model.year_from, item_model.year_to)}</small></div><div className="col-5"><span onClick={ () => handleShowUsage(null, (show_usage.model !== null && show_usage.model === idx_model) ? null : idx_model) } className="link">{(show_usage.model !== null && show_usage.model === idx_model) ? "skrýt" : "zobrazit"}</span></div>
                      </div>
                      {
                        (show_usage.model === idx_model)
                        && item_model.modifications.map( (item_modification, idx_modification) =>
                          <div key={idx_modification} className="row">
                            <div className="col-3">
                              {item_manufacturer.text}</div><div className="col-4">{item_model.text} <small>{strYears(item_model.year_from, item_model.year_to)}</small></div><div className="col-5">{item_modification.text} <small>[{strVehicle(item_modification.kw, item_modification.hp, item_modification.ccm)}] {strEngine(item_modification.engine)}</small>
                              {
                                (
                                  (manufacturers !== undefined)
                                  && (manufacturers[item_manufacturer.text] !== undefined)
                                )
                                &&
                                  <> &nbsp; <span onClick={ () => changeVehicle(item_manufacturer, item_model, item_modification) } className="link nowrap">do filtru</span></>
                              }
                            </div>
                          </div>
                      )}
                    </div>
                )}
              </div>
          )}
        </div>
      </div>
    )
  }


  // RENDER >>>


  const [selected] = table.filter( item => (item.type === current) );

  return (
    <section className="table">
      <ul className="table-tabs">
      {
        table.map( (item, index) =>
          <li key={index} onClick={() => handleTableTab(item.type)} className={ (item.type === selected.type) ? "active" : "" }>{item.text}</li>
        )
      }
      </ul>

      {
        (selected.data === null)
        ?
          <Loader type="block" text="načítám informace ..." />
        :
          (selected.type === "params")
          ?
            (selected.data.params.length === 0)
            ?
              renderEmpty()
            :
              renderParams(selected.data)
          :

          (selected.type === "replacements")
          ?
            ( (selected.data.replacements.length === 0) && (selected.data.cross.length === 0) )
            ?
              renderEmpty()
            :
              renderReplacements(selected.data)
          :

          (selected.type === "oe")
          ?
            (selected.data.oe.length === 0)
            ?
              renderEmpty()
            :
              renderOe(selected.data)
          :

          (selected.type === "usage")
          ?
            (selected.data.usage.length === 0)
            ?
              renderEmpty()
            :
              renderUsage(selected.data)
          :
            null
      }
    </section>

  );

}

export default Table;
