import { useHistory } from 'react-router-dom';

// Parts
import SearchList from 'components/parts/searchlist';

// Helpers
import {changeTitle} from 'helpers/page';
import * as Url from 'helpers/url';
import {searchRemaining} from 'helpers/search';

// Context
import {useGlobal} from 'context/globalContext.js';
import {useProducts} from 'context/productsContext';

// Config
import configFile from 'config';


const SearchOverlay = () => {
  // Hooks
  const history = useHistory();
  const {global, setOverlay} = useGlobal();
  const {products, searchSetText, syncSet, productsReplaceType} = useProducts();

  // Handle

  const handleCloseOverlay = () => {
    setOverlay(null);
  }

  const handleSearchClear = () => {
    changeTitle();

    syncSet({search:1});
//    productsReplaceType({ search: [] });
    searchSetText("");
  };

  const handleSearchGo = () => {
    history.push( "/produkty" + Url.toGet({ text:products.search.text, typ:((products.search.interchangeable == true) ? "zamenitelne" : "hledat") }) );
    handleCloseOverlay();
  };


  // RENDER >>>


  if (global.overlay !== "search")
  {
    return null;
  }

  const {remaining, remainingText} = searchRemaining(products.search.text, configFile.config.search.minSearchLength);

  return (
    <section id="overlay-search" className="dialog overlay">

      <div className="close">
        <span onClick={handleCloseOverlay} className="btn-icon icon is-times"></span>
      </div>

      <div className="stretch">
        <SearchList />
      </div>

      <div className="buttons">
        <button onClick={handleSearchClear} className="alt">Vyčistit</button>
        <button onClick={handleSearchGo} disabled={ (products.sync.search !== 4) /*|| (remaining > 0)*/ }>Zobrazit vše</button>
      </div>

    </section>
  );
};

/*class SearchPopupClass extends React.Component {
  state = {
    sync_search : false,
  }


  async componentWillUpdate(nextProps) {
    if ( this.remainingToSearch() >= 0 ) {
      return;
    }

    // TODO!!!!!! EXACT search!
    if (this.props.global.sync.search === false) {
      this.props.dispatch({ type: 'SYNC_SET', payload: { search : true } });
      await this.searchLoad( this.props.global.search_word, false );
      this.props.dispatch({ type: 'SYNC_SET', payload: { search : null } });
    }
  }



  handleShowMore = e => {
    e.preventDefault();
  }


  handleClearText = e => {
    e.preventDefault();
  }


  // GET

  async searchLoad(word, exact) {

  }


  render () {
    const { search : sync } = this.props.global.sync;

    const remaining = this.remainingToSearch();

    // TODO: MAKE IT WITH FILTER ONLY!!!
    let products = [];
    if (this.props.global.products) {
      for (let idx = 0; idx < this.props.global.products.length; idx++) {
        const product = this.props.global.products[idx];
        console.log( product );
        if (products.length >= 3) {
          break;
        } else if ("search" in product.types) {
          products.push( product );
        }
      }
    }


  }
}*/

export default SearchOverlay;
