import React from 'react';


const ComplaintPage = ({ props }) => {

  return (
    <>
      <h1>Informace k reklamacím</h1>

      <p>
        Reklamace podrobně upravuje bod 7 obchodních podmínek, zde je však několik důležitých základních informací, abyste je nemuseli číst, pokud nechcete.
      </p>
      <p>
        Pokud zakoupíte produkt ve stavu nový, je záruka 2 roky.
      </p>

      <p>
        <b>! Pro uplatnění reklamace autobaterie potřebujete záruční list s kódem baterie a účtenku.</b>
      </p>
      <p>
        <b>! Pro uplatnění reklamace zboží ze své povahy elektronické potřebujete kopii výpisu z diagnostického přístroje a účtenku. A to i v případě, že se na protokolu nezobrazuje závada. Buď v papírové formě, nebo elektronicky, stačí i fotografie protokol, apod.</b>
      </p>

      <p>
        Pro uplatnění reklamace ostatního zboží nepotřebujete záruční list. Vždy Vám postačí účtenka.
      </p>
      <p>
        V případě vyřízení reklamace výměnou neběží nová záruka na zboží. Pokračuje původní záruka.
      </p>
      <p>
        Požaduje-li kupující úhradu vícenákladů způsobených vadným zbožím, musí tyto náklady prokázat příslušným dokladem a předložit již při přijímání reklamace prodávajícím. Zpětně tyto náklady nebudou řešeny.
      </p>
      <p>
        Při převzetí zboží ho vždy důkladně prohlédněte.
      </p>

      <p>
        Při převzetí balíku od přepravce zkontrolujte, zda není poškozen a pokud ano, prohlédněte obsah a okamžitě hlaste případný problém na e-mail info@prondo.cz. Pořiďte fotodokumentaci poškození a obalu.
      </p>

      <p>
        Kupující může reklamaci uplatnit (osobně či balíkem) na prodejně na adrese:<br/>
        PRONDO s.r.o., Víta Nejedlého 742, Kosmonosy 29306, tel. 326 728 002<br/>
        <br/>
        Zásilka musí obsahovat: reklamované zboží (včetně kompletního příslušenství).<br/>
        Dále přiložit kopii dokladu o zakoupení či jiným vhodným způsobem doložit záruku zboží, podrobný popis závady a dostatečné kontaktní údaje kupujícího (zejm. zpáteční adresa a tel. číslo). <br/>
        Taktéž doporučujeme zvolit si požadovaný způsob vyřízení reklamace (oprava, výměna, dobropis).
      </p>

      <h2>Ke stažení</h2>
      <p><a href="download/documents/reklamacni_formular.pdf" target="_blank">Reklamační formulář</a></p>

      <h2>Zamítnutí reklamace pro znečištění zboží</h2>
      <p>PRONDO má právo zamítnout reklamaci zboží případech, kdy reklamované zboží a/nebo jeho součásti jsou znečištěné nebo nesplňují základní předpoklady pro hygienicky bezpečné předání zboží k reklamačnímu řízení. V případě reklamace zařízení s nádrží na palivo či provozní kapalinu, musí být v okamžiku převzetí do reklamace nádrž prázdná.</p>
    </>
  );

}

export default ComplaintPage;
