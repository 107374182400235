import React from 'react';


const DocumentsPage = ({ props }) => {

  return (
    <>
      <h1>Dokumenty se stažení</h1>

      <p><a href="download/documents/obchodni_podminky.pdf" target="_blank">Obchodní podmínky</a></p>
      <p><a href="download/documents/odstoupeni_od_smlouvy.pdf" target="_blank">Odstoupení od smlouvy</a></p>
      {/*<p><a href="download/documents/gdpr.pdf" target="_blank">GDPR</a></p>*/}
      <p><a href="download/documents/reklamacni_formular.pdf" target="_blank">Reklamační formulář</a> <br/> Bezproblémové vrácení zboží. Jednoduše, bezplatně a bez udání důvodu.</p>
      {/*<p><a href="download/documents/reklamacni_rad.pdf" target="_blank">Reklamační řád</a></p>*/}
    </>
  );

}

export default DocumentsPage;
